import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class DashboardService {

    public selectedSession:any=null;
    public selectedArchiveSession:any=null;
    public editStepFalg:any =null;
    public archiveFlag:boolean = false;
    public editStep3:any = null
    constructor(private http:HttpClient){}

    public getScheduledData()
    {
        return this.http.get(environment.baseUrl+'api/digital/games/session/scheduled/list');
    }

    public deleteSession(id:any)
    {
        return this.http.delete(environment.baseUrl+'api/digital/games/session/delete/'+id);
    }
    public getArchiveVCDtls(program_id:any){
        return this.http.get(environment.baseUrl+'api/digital/games/vc/archived/details?program_id='+program_id)
    }
    public getArchiveWHDtls(program_id:any){
        return this.http.get(environment.baseUrl+'api/digital/games/wh/archived/details?program_id='+program_id)
    }
    getDashboardDtls(){
        return this.http.get(environment.baseUrl +'api/digital/games/session/dashboard/details')
    }
}   