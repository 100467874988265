<div>
    <div>
        <div class="step-main-div" *ngIf="steperFlag">
            <div>STEP 1</div>
            <div>STEP 2</div>
            <div class="step-active-css">STEP 3</div>
        </div>
        <div class="main-container">
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
                <div class="team-config-main-div">
                    <div class="element-input-div">
                        <div>
                            <img placement="bottom" [ngbTooltip]="maxplayers" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg">
                            <li>Maximum Players</li>
                        </div>
                        <input type="number" [(ngModel)]="maxPlayerData" min="1"
                            oninput="javascript: if(this.value == 0) this.value=''" [disabled]="disabledFlag"
                            [ngClass]="disabledFlag ? 'disabledInput':''">
                    </div>
                    <div class="element-input-div">
                        <div>
                            <img placement="bottom" [ngbTooltip]="noteams" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg">
                            <li>Number of Teams</li>
                        </div>
                        <input type="number" [(ngModel)]="noOfTeamsData" min="1"
                            oninput="javascript: if(this.value == 0) this.value=''" [disabled]="disabledFlag"
                            [ngClass]="disabledFlag ? 'disabledInput':''">
                    </div>
                    <div class="element-input-div">
                        <div>
                            <img placement="bottom" [ngbTooltip]="sessionduration" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg">
                            <li>Session Duration (mins)</li>
                        </div>
                        <input type="number" [(ngModel)]="sessionTimeData" min="1"
                            oninput="javascript: if(this.value == 0) this.value=''">
                    </div>
                </div>
                <div>
                    <div class="last-btn-div" style="text-align: center; margin-top: 10px;">
                        <input style="width: 65%; max-width: 200px;" type="image" src="../../../assets/img/save&conti-btn.svg"
                            (click)="savePicturePerfectConfig()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>