<div style="height: 100vh;">
    <app-header></app-header>
    <div class="main-container">
        <div class="side-nav-bar" [ngClass]="gameType == 'digital'?'side-dg':gameType == 'physical'?'side-py':''">
            <div class="side-nav-first-div">
                <img src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" alt="" (click)="goBack()">
                Game Resources
            </div>
            <div class="side-nav-dtl-div">
                <div>{{gamesDtl[gameDtlIndex]['name']}}</div>
                <div>{{gamesDtl[gameDtlIndex]['description']}}</div>
            </div>
            <div>
                <div *ngFor="let item of allFiles;let i = index" class="file-multi-div"
                    [ngClass]="item.fileName == fileName ?'activeCss':''" (click)="handleFileChange(i,item.fileName )">
                    <div>
                        <li style="font-weight: 500;">{{item.fileName}}</li>
                    </div>
                    <div *ngIf="item.type == 'video'"><img src="../../../assets/img/video-icon.svg" alt="" srcset=""
                            style="width: 25px;"></div>
                    <div *ngIf="item.type == 'pdf'"><img src="../../../assets/img/pdf-icon.svg" alt="" srcset=""
                            style="width: 25px;"></div>
                    <div *ngIf="item.type == 'ppt'"><img src="../../../assets/img/ppt_icon.png" alt="" srcset=""
                            style="width: 25px;"></div>
                    <div *ngIf="item.type == 'img'"><img src="../../../assets/img/ppt_icon.png" alt="" srcset=""
                            style="width: 25px;"></div>
                </div>
            </div>
        </div>
        <div class="main-content" [ngClass]="zoomFlag?'grid-1':''" *ngIf="type == 'pdf'">
            <div *ngIf="!zoomFlag" class="main-content-head">
                {{name}}
            </div>
            <div>
                <pdf-viewer [src]="src" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false"
                    [zoom]="0" [zoom-scale]="zoomScale" [stick-to-page]="true" [render-text]="true"
                    [external-link-target]="'blank'" [autoresize]="true" [show-borders]="true"
                    class="pdf-viewer"></pdf-viewer>
            </div>
            <div *ngIf="!zoomFlag" style="text-align: end;padding-right: 1rem;">
                <img src="../../../assets/img/zoom-out-fixed-svgrepo-com.svg" (click)="zoom()" alt="" class="zoom-img">
            </div>
            <div *ngIf="zoomFlag" style="position: absolute;right: 15px;">
                <img src="../../../assets/img/zoom-in-fixed-svgrepo-com.svg" (click)="zoom()" alt="" class="zoom-img">
            </div>
        </div>
        <div *ngIf="type == 'video'" class="video-main-div">
            <div class="file_name">
                {{name}}
            </div>
            <div class="video_div">
                <video controls [muted]="'muted'" id="videoPlayer" controlsList="nodownload" disablePictureInPicture
                    #videoPlayer style="width: 100%;" autoplay [src]="src" type="video/mp4">
                    Browser not supported
                </video>
            </div>
        </div>
        <div *ngIf="type == 'ppt'" class="video-main-div">
            <div class="file_name">
                {{name}}
            </div>
            <div class="ppt_div">
                <iframe [(src)]='src' width='100%' height='100%' frameborder='0' id="iframePPT"></iframe>
            </div>
            <!-- <img src="../../../assets/img/download-cloud-svgrepo-com.svg" alt="" (click)="downloadPPT(src)" class="pptdownload"> -->
            <iframe [(src)]="tempDownSrc" frameborder="0" style="position: absolute; visibility: hidden;"></iframe>
        </div>
        <div *ngIf="type == 'img'" class="video-main-div">
            <div class="file_name">
                {{name}}
            </div>
            <div class="ppt_div">
                <img [(src)]="src" alt="" srcset="" style="width: 98%;
                margin-left: 1%;">
            </div>
            <a id="image_download" [hidden]="true" download="image.png"></a>
            <img src="../../../assets/img/download-cloud-svgrepo-com.svg" alt="" (click)="downloadImage(src)" class="pptdownload">
        </div>
    </div>

</div>