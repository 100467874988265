import { filter } from 'rxjs';
import { query } from '@angular/animations';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { ReachOutComponent } from '../reach-out/reach-out.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-explore-all-games',
  templateUrl: './explore-all-games.component.html',
  styleUrls: ['./explore-all-games.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ExploreAllGamesComponent implements OnInit {
  selectedExplore: any = ''
  afSlider = 0;
  vcSlider = 0;
  bnSlider = 0;
  whSlider = 0;
  ppSlider = 0;
  bpSlider = 0;
  mSlider = 0;
  strSlider = 0;
  ssSlider = 0;
  alSlider = 0;
  stSlider = 0;
  mmSlider = 0;
  physicalGameContentData: any = [
    {
      name: 'Human Matrix',
      description: "Matrix as a game offers an opportunity to delve into an exploration of how and why collaboration and its various facets. The game creates an environment for interactions, trust, working with others and reaching win-win solutions, which form the crux of the session.",
      img: "../../assets/img/matrix.png",
      learningConnnect: ["Collaboration ", "Leading cross-functional teams ", "Influence over Authority", "Trust in teams"],
      grpSize: "16-40 People",
      timeGuration: "40 - 50 mins",
      spaceReq: "25ft X 25ft",
      id: 8
    },
    {
      name: 'String Theory ',
      description: "String Theory captivating game explores various fundamental principles related to Problem Solving and its various aspects, offering a multifaceted and highly engaging experience.",
      img: "../../assets/img/string_therory.png",
      learningConnnect: ["Problem Solving", "Execution Excellence", "Accountability and Ownership", "Team Work"],
      grpSize: "10-30 People",
      timeGuration: "60 - 90 mins",
      spaceReq: "20ft X 20ft",
      id: 9
    },
    {
      name: 'Storm Shelter',
      description: "The Storm Shelter is an intense and layered activity that explores the journey of moving from managing to leading teams. This multifaceted, highly engaging game covers several fundamental behaviours that govern leadership.",
      img: "../../assets/img/strom_selter.png",
      learningConnnect: ["Managing to Leading teams", "Situational leadership in action", "Leadership Communication ", "Shared Ownership"],
      grpSize: "12-30 People",
      timeGuration: "40 - 50 mins",
      spaceReq: "20ft X 20ft",
      id: 10
    },
    {
      name: 'Assembly Line',
      description: "Assembly Line captivating game explores various fundamental principles related to Growth Mindset, Innovation, and Continuous Improvement, offering a multifaceted and engaging experience.",
      img: "../../assets/img/assebembly_line.png",
      learningConnnect: ["Innovation- Mindset to Action", "Continuous Improvement /Kaizen", "Agility", "Growth Mindset", "Goal setting (Setting stretch goals)"],
      grpSize: "10-40 People",
      timeGuration: "40 - 50 mins",
      spaceReq: "20ft X 20ft",
      id: 11
    },

    {
      name: 'Magic Mats',
      description: "Magic Mats as a game lends itself very powerfully to learning conversations around the underlying behaviours that drive result orientation",
      img: "../../assets/img/magic_mats.png",
      learningConnnect: ["Result Orientation ", "Analytical thinking & Problem-Solving", "Managing Change "],
      grpSize: "10-40 People",
      timeGuration: "40 - 50 mins",
      spaceReq: "20ft X 40ft",
      id: 12
    },
    {
      name: 'Stranded',
      description: "Strand'ed multifaceted, highly engaging game covers several fundamental behaviours that govern communication and leadership. ",
      img: "../../assets/img/stranded.png",
      learningConnnect: ["Communication for Results  ", "The Art of Feedback & Feedforward ", "Leading with Empathy", "Team Motivation (For leaders)"],
      grpSize: "10-30 People",
      timeGuration: "40 - 50 mins",
      spaceReq: "25ft X 40ft",
      id: 13
    },
  ];
  physicalGameContent: any[] = this.physicalGameContentData
  digitalGamesCOntentData: any = [
    {
      name: 'Value Continuum',
      description: "Value Continuum is a activity that helps explore individual’s mindsets & preferences and perceptions of others and pushes them to reflect on their own thinking and preferences, thereby promoting open conversations on diversity, empathy and interpersonal interactions.",
      img: "../../assets/img/explore_vc.svg",
      learningConnnect: ["Appreciating Diversity & Inclusion ", "Trust & Respect", "Emotional Intelligence ", "Developing Empathy to drive interactions"],
      grpSize: "5-500 People",
      timeGuration: "30 - 45 mins",
      platform: "Laptop & Mobile",
      id: 1
    },
    {
      name: 'Warehouse',
      description: "Warehouse Game is a multifaceted and highly engaging team activity that challenges teams and individuals to think critically and work collaboratively to transport a given target order of items from a warehouse to their team store in the shortest time possible. ",
      img: "../../assets/img/explore_wh.svg",
      learningConnnect: ["Importance of planning and organizing", "Continuous Improvement ", "Managing & Leading Change", "Execution Excellence", "Ownership & Accountability", "Goal Setting"],
      grpSize: "5-100 People",
      timeGuration: "30 - 60 mins",
      platform: "Laptop & Mobile",
      id: 3
    },
    {
      name: 'Bottleneck Challenge',
      description: "The Bottleneck Challenge is an engaging team activity where participants find themselves on a mountain road, stuck in a peculiar jam. The objective is for the traffic from both sides ( represented by team members) to get to the other side and resolve the jam, while playing by certain rules that govern the movement. The activity is a powerful tool for reflecting on one's behaviour when given a task",
      img: "../../assets/img/explore_bn.svg",
      learningConnnect: ["Ownership & Initiative", "Critical thinking", "Problem Solving & Decision Making", "Team Motivation", "Process Orientation"],
      grpSize: "5-100 People",
      timeGuration: "30 - 60 mins",
      platform: "Laptop & Mobile",
      id: 2
    },
    {
      name: 'Animal Farm',
      description: "Animal Farm is an engaging activity, a team must transport a specific number of animals & birds into their barn before the other team competing with them in the same game.  These animals can be taken from the central barn or anyway else in the farm in order to meet the target before the other team.",
      img: "../../assets/img/animalFarm-logo.png",
      learningConnnect: ["Teamwork & Collaboration", "Importance of Trust in teams ", "Integrity", "Leadership"],
      grpSize: "5-100 People",
      timeGuration: "30 - 60 mins",
      platform: "Laptop & Mobile",
      id: 5
    },
    {
      name: 'Big Picture',
      description: "Big Picture is a versatile and highly customizable jigsaw themed group activity that works for virtual and in-person settings. Smaller teams have to complete challenges to earn codes. With each code certain pieces of a giant jigsaw get revealed. when all team complete all challenges and enter all codes, the entire Big picture is unveiled. The Challenges and the Image that makes up the jigsaw are customizable to the theme of your session or event. ",
      img: "../../assets/img/explore_bp.svg",
      learningConnnect: ["Collaboration, Goal alignment", "Big Picture thinking", "Vision, Mission Immersion", "Competencies Immersion"],
      grpSize: "20-500 People",
      timeGuration: "30 - 60 mins",
      platform: "Laptop & Desktop",
      id: 6
    },
    {
      name: 'Picture Perfect ',
      description: "A challenging game where teams have to recreate an image on a virtual canvas based on the instructions they receive from a guide/leader who alone has access to the image that has to be replicated. The game mimics the real life challenges of communication to get things done in an interdependent environment, where the ability to humanize ones communication has a direct impact on outcomes.",
      img: "../../assets/img/Picture_Perfect_Logo.png",
      learningConnnect: ["Communicating for Results", "Leadership Communication", "Visualization & Story telling in communication", "Empathy in interactions"],
      grpSize: "5-100 People",
      timeGuration: "60 - 90 mins",
      platform: "Laptop & Desktop",
      id: 4
    },
  ]
  digitalGamesCOntent: any[] = this.digitalGamesCOntentData
  afImages: any = ["../../../assets/resource_slider/Animal_Farm_1.jpg", "../../../assets/resource_slider/Animal_Farm_2.jpg", "../../../assets/resource_slider/Animal_Farm_3.jpg", "../../../assets/resource_slider/Animal_Farm_4.jpg"];
  vcImages: any = ["../../../assets/resource_slider/VC1.jpg", ".../../../assets/resource_slider/VC2.jpg", ".../../../assets/resource_slider/VC3.jpg", ".../../../assets/resource_slider/VC4.jpg"];
  bnImages: any = ["../../../assets/resource_slider/Bottleneck_1.jpg", "../../../assets/resource_slider/Bottleneck_2.jpg", "../../../assets/resource_slider/Bottleneck_3.jpg", "../../../assets/resource_slider/Bottleneck_4.jpg"];
  whImages: any = ["../../../assets/resource_slider/warehouse_1.jpg", "../../../assets/resource_slider/warehouse_2.jpg", "../../../assets/resource_slider/warehouse_3.jpg", "../../../assets/resource_slider/warehouse_4.jpg"];
  ppImages: any = ["../../../assets/resource_slider/pp_1.jpg", "../../../assets/resource_slider/pp_2.jpg", "../../../assets/resource_slider/pp_3.jpg", "../../../assets/resource_slider/pp_4.jpg", "../../../assets/resource_slider/pp_5.jpg", "../../../assets/resource_slider/pp_6.jpg", "../../../assets/resource_slider/pp_7.jpg", "../../../assets/resource_slider/pp_8.jpg"];
  bpImages: any = ["../../../assets/resource_slider/bp_1.jpg", "../../../assets/resource_slider/bp_2.jpg", "../../../assets/resource_slider/bp_3.jpg", "../../../assets/resource_slider/bp_4.jpg", "../../../assets/resource_slider/bp_5.jpg", "../../../assets/resource_slider/bp_6.jpg",];


  mImages: any = ["../../../assets/resource_slider/matrix_1.jpg", "../../../assets/resource_slider/matrix_2.jpg", "../../../assets/resource_slider/matrix_3.jpg"];
  stImages: any = ["../../../assets/resource_slider/string_1.jpg", "../../../assets/resource_slider/string_2.jpg", "../../../assets/resource_slider/string_3.jpg"];
  ssImages: any = ["../../../assets/resource_slider/strom_1.jpg", "../../../assets/resource_slider/strom_2.jpg"];
  alImages: any = ["../../../assets/resource_slider/asbly_line_1.jpg", "../../../assets/resource_slider/asbly_line_2.jpg"];
  strImages: any = ["../../../assets/resource_slider/stander_1.jpg", "../../../assets/resource_slider/stander_2.jpg", "../../../assets/resource_slider/stander_3.jpg"];
  mmImages: any = ["../../../assets/resource_slider/magic_mats_1.png", "../../../assets/resource_slider/magic_mats_2.png", "../../../assets/resource_slider/magic_mats_3.png"];


  slideIndex = 1;



  constructor(
    private router: Router,
    private dataShare: DataSharingServiceService,
    private dialog: MatDialog,
    private httpSv: HttpService
  ) { }
  headerTxt:string = ''
  ngOnInit(): void {
    this.selectedExplore = this.dataShare.exploreGameSelection
    if (this.selectedExplore == 'digital') this.getGameAccessList(1)
    else if (this.selectedExplore == 'physical') this.getGameAccessList(2)
    if (!this.selectedExplore) this.headerTxt = 'Explore All Games'
  }
  selectedExploreGame(data: any) {
    if (data == this.selectedExplore) return
    else if (data == 'digital') {this.getGameAccessList(1);this.headerTxt = '';}
    else if (data == 'physical') {this.getGameAccessList(2);this.headerTxt = '';}
    this.selectedExplore = data;
  }
  plusSlides(data: any) {
    switch (data) {
      case 1:
        this.vcSlider = (this.vcSlider + 1) % (this.vcImages.length);
        break;
      case 2:
        this.bnSlider = (this.bnSlider + 1) % (this.bnImages.length);
        break;
      case 3:
        this.whSlider = (this.whSlider + 1) % (this.whImages.length);
        break;
      case 4:
        this.ppSlider = (this.ppSlider + 1) % (this.ppImages.length);
        break;
      case 5:
        this.afSlider = (this.afSlider + 1) % (this.afImages.length);
        break;
      case 6:
        this.bpSlider = (this.bpSlider + 1) % (this.bpImages.length);
        break;
      case '8':
        this.mSlider = (this.mSlider + 1) % (this.mImages.length);
        break;
      case '13':
        this.strSlider = (this.strSlider + 1) % (this.strImages.length);
        break;
      case '10':
        this.ssSlider = (this.ssSlider + 1) % (this.ssImages.length);
        break;
      case '11':
        this.alSlider = (this.alSlider + 1) % (this.alImages.length);
        break;
      case '9':
        this.stSlider = (this.stSlider + 1) % (this.stImages.length);
        break;
      case '12':
        this.mmSlider = (this.mmSlider + 1) % (this.mmImages.length);
        break;
    }

  }
  minusSlides(data: any) {
    switch (data) {
      case 1:
        this.vcSlider = this.vcSlider - 1;
        if (this.vcSlider < 0)
          this.vcSlider = this.vcImages.length - 1
        break;
      case 2:
        this.bnSlider = this.bnSlider - 1;
        if (this.bnSlider < 0)
          this.bnSlider = this.bnImages.length - 1
        break;
      case 3:
        this.whSlider = this.whSlider - 1;
        if (this.whSlider < 0)
          this.whSlider = this.whImages.length - 1
        break;
      case 4:
        this.ppSlider = this.ppSlider - 1;
        if (this.ppSlider < 0)
          this.ppSlider = this.ppImages.length - 1
        break;
      case 5:
        this.afSlider = this.afSlider - 1;
        if (this.afSlider < 0)
          this.afSlider = this.afImages.length - 1
        break;
      case 6:
        this.bpSlider = this.bpSlider - 1;
        if (this.bpSlider < 0)
          this.bpSlider = this.bpImages.length - 1
        break;
      case '8':
        this.mSlider = this.mSlider - 1;
        if (this.mSlider < 0)
          this.mSlider = this.mImages.length - 1
        break;
      case '13':
        this.strSlider = this.strSlider - 1;
        if (this.strSlider < 0)
          this.strSlider = this.strImages.length - 1
        break;
      case '10':
        this.ssSlider = this.ssSlider - 1;
        if (this.ssSlider < 0)
          this.ssSlider = this.ssImages.length - 1
        break;
      case '11':
        this.alSlider = this.alSlider - 1;
        if (this.alSlider < 0)
          this.alSlider = this.alImages.length - 1
        break;
      case '9':
        this.stSlider = this.stSlider - 1;
        if (this.stSlider < 0)
          this.stSlider = this.stImages.length - 1
        break;
      case '12':
        this.mmSlider = this.mmSlider - 1;
        if (this.mmSlider < 0)
          this.mmSlider = this.mmImages.length - 1
        break;
    }
  }
  back() {
    if (this.dataShare.myGames && this.dataShare.myGames != 'dashboard') {
      console.log(this.dataShare.myGames)
      this.router.navigate(['myGames'])
    }
    else if (this.dataShare.myGames && this.dataShare.myGames == 'dashboard') {
      this.router.navigate(['/dashboard'])
      this.dataShare.myGames = null
    }
    this.dataShare.exploreGameSelection = null
    this.selectedExplore = ''
  }
  goToGameResource(id: any) {
    this.dataShare.exploreGameSelection = this.selectedExplore
    this.router.navigate(['game/resources'], { queryParams: { id: id } })
  }
  goToQuestico() {
    this.router.navigate(['/game/questico'])
  }



  getBgClass(id: any) {
    let cssClass = ''
    switch (id) {
      case 1:
        cssClass = 'diYellow'
        break;
      case 2:
        cssClass = 'diPurple'
        break;
      case 3:
        cssClass = 'diBlue'
        break;
      case 4:
        cssClass = 'digital'
        break;
      case 5:
        cssClass = 'digital'
        break;
      case 6:
        cssClass = 'diPink'
        break;
      case '8':
        cssClass = 'physical'
        break;
      case '9':
        cssClass = 'pyBlue'
        break;
      case '10':
        cssClass = 'pyGreen'
        break;
      case '11':
        cssClass = 'pyYellow'
        break;
      case '12':
        cssClass = 'pyPurple'
        break;
      case '13':
        cssClass = 'pyPink'
        break;
    }


    return cssClass
  }
  filterDigitalContent(val: any) {
    let temp = this.digitalGamesCOntentData.filter((ele: any) => ele.description.toLowerCase().includes(val.value.toLowerCase()) | ele.learningConnnect.some((ele: any) => ele.toLowerCase().includes(val.value.toLowerCase())) | ele.name.toLowerCase().includes(val.value.toLowerCase()))
    // if(temp.length == 0) this.digitalGamesCOntent = this.digitalGamesCOntentData
    // else 
    this.digitalGamesCOntent = temp
  }
  filterPhysicalContent(val: any) {
    let temp = this.physicalGameContentData.filter((ele: any) => ele.description.toLowerCase().includes(val.value.toLowerCase()) | ele.learningConnnect.some((ele: any) => ele.toLowerCase().includes(val.value.toLowerCase())) | ele.name.toLowerCase().includes(val.value.toLowerCase()))
    // if(temp.length == 0) this.physicalGameContent = this.physicalGameContentData
    // else
    this.physicalGameContent = temp
  }
  openReachOut() {
    console.log(this.gameAccessIds)
    const dialogRef = this.dialog.open(ReachOutComponent, {
      panelClass: 'reachout',
      hasBackdrop: true,
    })
  }
  gameAccessIds: any[] = []
  getGameAccessList(game_type: number) {
    this.gameAccessIds = []
    this.httpSv.getGameAccessList(game_type).subscribe((res: any) => {
      if (res['status']) {
        res['results'].forEach((ele: any) => {
          this.gameAccessIds.push(ele.game_id)
        });
      }

    })
  }
}
