<div class="entire" id="headerMainDiv">
    <div class="logo" (click)="routeLandingPage()" style="cursor: pointer;"><img src="../../assets/kairosName.svg">
    </div>
    <div class="title">
        <div class="three-step-div" *ngIf="first != null">
            <span>{{first}}</span>
            <span class="divider">|</span>
            <span
                [ngClass]="secound=='Digital'?'color-blue':secound == 'Questico'?'yellow-color':secound=='Physical'?'color-green':''">{{secound}}</span>
            <span class="divider">|</span>
            <span style="font-weight: 500;">{{third}}</span>
        </div>
        <div class="single-header" *ngIf="first == null && oneHeader != null">
            <span style="font-size: 18px;font-weight: 500;">{{oneHeader}}</span>
        </div>
    </div>
    <div class="header_left_main_div">
        <!-- <div class="myGames" style="cursor: pointer;" (click)="goToMyGame()">
            <p>My</p>
            <p> Games</p>
        </div>
        <div class="exploreGames" style="cursor: pointer;" (click)="goToExplore()">
            <p>Explore </p>
            <p>All Games</p>
        </div> -->
        <div>{{username}}</div>
        <div class="userProfile" style="cursor: pointer;" (click)="toggleFunc()" style="margin-right: 10px;">
            <img src="../../assets/img/user_profile.svg" style="cursor: pointer;">
        </div>
    </div>

</div>
<div class="header-drowdwon" *ngIf="toggleFlag" (appOutsideClick)="outSideClickHandle($event)">
    <div class="header-drowdwon-content" (click)="goToAccountSetting()">
        My Profile
    </div>
    <div class="header-drowdwon-content" (click)="goToMyGame()">
        My Games
    </div>
    <div class="header-drowdwon-content" (click)="goGameReport()">
        Games Reports
    </div>
    <div class="header-drowdwon-content" (click)="logout()">
        Logout
    </div>
</div>