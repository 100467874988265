import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-game-content',
  templateUrl: './game-content.component.html',
  styleUrls: ['./game-content.component.css']
})
export class GameContentComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toster: ToastrService,
    public sanitizer: DomSanitizer,
    private dialog: MatDialog,

  ) { }
  gameType:any='';
  ngOnInit(): void {
    this.gameDtlIndex = 0;
    this.src = this.gamesDtl[this.gameDtlIndex]['files'][0]['src'];
    this.name = "Facilitator Guide"
  }

  DigitalGames:any = 'Digital Games';

  src:any;
  fileName: any
  name:any;
  type: any ='pdf'
  allFiles: any
  downloadSrc: any

  openLegendDialog:boolean=true;

  zoomScale: any = 'page-height'
  zoomFlag: boolean = false

  addLegend(){
    // this.openLegendDialog = true;
    const dialogRef = this.dialog.open(addLegendDialogComponent, {
      disableClose: false,
      panelClass: 'no-padding-dialog',
    })
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        
      }
    }, (err: any) => {
      this.toster.error(err.error.message)
    })
  }

  zoom() {
    if (!this.zoomFlag) {
      this.zoomFlag = true
      this.zoomScale = 'page-width'
    }
    else if (this.zoomFlag) {
      this.zoomFlag = false
      this.zoomScale = 'page-height'
    }
  }
  gameDtlIndex: any

  gamesDtl: any = [
    {
      id: 1,
      name: 'Value Continuum',
      description: 'Value Continuum is a activity that helps explore individual’s mindsets & preferences and perceptions of others and pushes them to reflect on their own thinking and preferences, thereby promoting open conversations on diversity, empathy and interpersonal interactions.',
      files: [
        { fileName: 'Facilitator Guide', des_name:'Value Continuum', type: 'pdf', src: '../../../../assets/pdf/Kairos_Value_Continuum_ Guide.pdf' },
        { fileName: 'Learning to Action Tool', des_name:'Value Continuum-Empathy Affirmations & Stakeholder Map', type: 'ppt', src: '../../../../assets/ppt/Value-Continuum-Empathy-Affirmations-_-Stakeholder-Map.html', downloadSrc: '../../../assets/ppt/Value Continuum - Empathy Affirmations & Stakeholder Map.pptx' },
      ]
    }   
  ]

}

@Component({
  selector: 'add-legend-dialog',
  templateUrl: './dialog-legend.component.html',
  styleUrls: ['./game-content.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class addLegendDialogComponent implements OnInit {

  constructor(
    private toster: ToastrService,
    public dialogRef: MatDialogRef<addLegendDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    
  ) { }

  ngOnInit(): void {

    this.legendDtl.push({
      id:'',
      type: '',
      name: '',
      files:[],
    },
    {
      id:'',
      type: '',
      name: '',
      files:[],
    }
    )
  }

  fileTypes = {
    1: 'pdf',
    2: 'video',
    3: 'ppt',
    4: 'images'
  }
  onOptionClick(option: string, legend: any) {
    // Handle the option click here
    if (option == 'PDF') {
    } else if (option == 'Video') {
    }else if (option == 'PPT') {
    }else if (option == 'Images') {
    }
  }


  onSelectChange(event: any, i:number) {
    console.log(event.target.key, i)
    console.log(this.legendDtl)
    if (this.legendDtl.length > 0) {
      // this.legendDtl[0].type = 'ppt';
      // this.legendDtl[i].type = event.target.key;
    }

  }

  toggleDropdown(legend: any) {
    // Toggle the dropdown state for the user
    legend.isDropdownOpen = !legend.isDropdownOpen;
  }

  legendDtl: any[] = [];

  close(){
    this.dialogRef.close(false)
  }
  confirm(){
    this.dialogRef.close(true)
  }

}


