import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-iglu-labs-user',
  templateUrl: './iglu-labs-user.component.html',
  styleUrls: ['./iglu-labs-user.component.css'],
})
export class IgluLabsUserComponent implements OnInit {

  constructor(
    private detectChange: ChangeDetectorRef,
    private httpSv: HttpService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem("caltoken")) {
      if (localStorage.getItem("caltoken") == "384|azgzGIVJBdJCalendarTrj0z8gkr649MIrAGeFbjeIlEI0XO") {

      }
      else this.router.navigate(['/login'])
    }
    else this.router.navigate(['/login'])
    this.httpSv.getCalenderData().subscribe((res: any) => {
      if (res['status']) {
        this.data = res['result']
        const d = new Date();
        const t = d.getMonth()
        this.monthNumber = t
        const y = d.getFullYear()
        this.currentYear = y


        this.allDatesInMonth = this.getAllDatesOfMonth(y, t);
        this.allDatesInLastMonth = this.getAllDatesOfMonth(y, t - 1);
        this.allDatesInNextMonth = this.getAllDatesOfMonth(y, t + 1);
        this.firstDayOfMonth = this.getFirstDayOfWeek(y, t)
        this.lastDayOfMonth = this.getLastDayOfWeek(y, t)
        this.getAllDates()
      }
    })

  }
  allMonths: any[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  daysOfWeek: any = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  month: any
  monthNumber: number = 0
  todayDate: any
  allDatesInMonth: any
  allDatesInLastMonth: any
  allDatesInNextMonth: any
  todayDay: any
  firstDayOfMonth: any
  lastDayOfMonth: any
  currentYear: any
  getAllDatesOfMonth(year: any, month: any) {
    let dates = [];

    // Create a new Date object with the given year and month (months are zero-based)
    let currentDate = new Date(year, month, 1);

    // Loop until the month changes
    while (currentDate.getMonth() === month) {

      // Add the current date to the array
      dates.push(currentDate.getDate());

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);

    }

    return dates;
  }
  getFirstDayOfWeek(year: any, month: any) {

    let firstDays = [];

    // Create a new Date object with the given year and month (months are zero-based)
    let currentDate = new Date(year, month, 1);

    // Loop until the month changes
    while (currentDate.getMonth() === month) {
      // Add the day of the week for the 1st date to the array
      let dayOfWeek = this.daysOfWeek[currentDate.getDay()];
      firstDays.push(dayOfWeek);

      // Move to the next month
      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(1);
    }

    return firstDays;
  }
  getLastDayOfWeek(year: any, month: any) {
    let lastDays = [];

    // Create a new Date object with the given year and month (months are zero-based)
    let currentDate = new Date(year, month, 1);

    // Loop until the month changes
    while (currentDate.getMonth() === month) {
      // Move to the next month
      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(0); // Set the date to 0 to get the last day of the previous month

      // Add the day of the week for the last date to the array
      let dayOfWeek = this.daysOfWeek[currentDate.getDay()];
      lastDays.push(dayOfWeek);

      // Move back to the first day of the current month
      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(1);
    }

    return lastDays;
  }
  allFinalDisplayDates: any = []
  getAllDates() {
    let temp = this.allDatesInLastMonth
    let firstDayIndex = this.daysOfWeek.findIndex((ele: any) => ele == this.firstDayOfMonth[0])
    let lastDayIndex = this.daysOfWeek.findIndex((ele: any) => ele == this.lastDayOfMonth[0])
    let lastDates = firstDayIndex > 0 ? temp.splice(-firstDayIndex) : []
    temp = this.allDatesInLastMonth
    let futureDates = temp.splice(0, (this.daysOfWeek.length - 1) - lastDayIndex,)
    const finalLastDates = lastDates.map((ele: any) => {

      return {
        date: ele,
        mm: this.monthNumber - 1,
        data: this.getGameDtls(ele, this.allMonths[this.monthNumber - 1], this.currentYear)
      }
    })
    const finalFuture = futureDates.map((ele: any) => {
      return {
        date: ele,
        mm: this.monthNumber + 1,
        data: this.getGameDtls(ele, this.allMonths[this.monthNumber + 1], this.currentYear)
      }
    })
    const finalCurrentDates = this.allDatesInMonth.map((ele: any) => {

      return {
        date: ele,
        mm: this.monthNumber,
        data: this.getGameDtls(ele, this.allMonths[this.monthNumber], this.currentYear)
      }
    })

    this.allFinalDisplayDates = [...finalLastDates, ...finalCurrentDates, ...finalFuture]
  }
  nextMonth() {
    const d = new Date();
    let nextMontYear
    let nextMonthNum = 0
    let lastMontYear
    let lastMonthNum = 0

    if (this.monthNumber < 11) {
      this.monthNumber += 1
      nextMontYear = this.currentYear
      lastMontYear = this.currentYear
      lastMonthNum = this.monthNumber - 1
    }
    else {
      this.currentYear += 1
      this.monthNumber = 0
      nextMontYear = this.currentYear
      nextMonthNum = this.monthNumber + 1
      lastMontYear = this.currentYear
      lastMonthNum = this.monthNumber - 1
    }
    if (this.monthNumber == 11) {
      nextMontYear = this.currentYear + 1
      nextMonthNum = 0
    }
    if (this.monthNumber == 0) {
      lastMontYear = this.currentYear - 1
      lastMonthNum = 11
    }
    this.allDatesInMonth = this.getAllDatesOfMonth(this.currentYear, this.monthNumber);
    this.allDatesInLastMonth = this.getAllDatesOfMonth(lastMontYear, lastMonthNum);
    this.allDatesInNextMonth = this.getAllDatesOfMonth(nextMontYear, nextMonthNum);
    this.firstDayOfMonth = this.getFirstDayOfWeek(this.currentYear, this.monthNumber)
    this.lastDayOfMonth = this.getLastDayOfWeek(this.currentYear, this.monthNumber)
    this.getAllDates()
  }
  privousMonth() {
    const d = new Date();
    // this.monthNumber -= 1
    // const y = d.getFullYear()
    let nextMontYear
    let nextMonthNum = 0
    let lastMontYear
    let lastMonthNum = 0
    if (this.monthNumber > 0 && this.monthNumber <= 11) {
      this.monthNumber -= 1
      nextMontYear = this.currentYear
      lastMontYear = this.currentYear
      lastMonthNum = this.monthNumber - 1
    }
    else {
      this.currentYear -= 1
      this.monthNumber = 11
      nextMontYear = this.currentYear
      nextMonthNum = this.monthNumber - 1
      lastMontYear = this.currentYear
      lastMonthNum = this.monthNumber - 1
    }
    if (this.monthNumber == 0) {
      nextMontYear = this.currentYear + 1
      nextMonthNum = 0
      lastMontYear = this.currentYear - 1
      lastMonthNum = 11
    }

    this.allDatesInMonth = this.getAllDatesOfMonth(this.currentYear, this.monthNumber);
    this.allDatesInLastMonth = this.getAllDatesOfMonth(lastMontYear, lastMonthNum);
    this.allDatesInNextMonth = this.getAllDatesOfMonth(nextMontYear, nextMonthNum);
    this.firstDayOfMonth = this.getFirstDayOfWeek(this.currentYear, this.monthNumber)
    this.lastDayOfMonth = this.getLastDayOfWeek(this.currentYear, this.monthNumber)
    this.getAllDates()
  }
  currentMounth() {
    this.ngOnInit()
  }
  data = []
  thisMonthData: any
  getGameDtls(date: any, month: any, year: any) {
    let thisDateData: any = []
    this.data.forEach((ele: any, index: any) => {
      let temp = ele.program_date.split(" ")
      if (temp[0] == date && temp[1] == month) {
        thisDateData.push(this.data[index])
      }
    })

    return thisDateData

  }
  goBack() {
    this.router.navigate(['/login'])
  }
  gameDataHtmlHandel(data: any, platform: any) {
    let count = 0
    data.forEach((ele: any) => {
      if (ele.platform == platform) count++
    })
    return count
  }
  platformVarifyFunc(platform: string, data: any) {
    let flag: boolean = false
    data.forEach((ele: any) => {
      if (ele.platform == platform) flag = true
    })
    return flag
  }
  outSideClickHandle(event:any){
    if(event && this.passwordDiv){
      this.passwordDiv = false
    }
  }
  passwordDiv:boolean = false
  cheatPassword:string =''
  openChectPassoword(){
    this.passwordDiv = true
  }
  verifyCheatPassword(){
    if(this.cheatPassword === 'rajib'){
      console.log(this.allFinalDisplayDates)
    }
    else{
      console.error('Password incorrect')
    }
    this.passwordDiv = false
  }
}
