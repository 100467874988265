<div>
    <div class="header-info-main-div">
        <div><img src="../../../assets/img/back-svgrepo-com.svg" alt="" srcset=""
                style="max-width: 25px; cursor: pointer; margin: 0 24px;" (click)="goToDashBoard()"></div>
        <div class="header-text-div">DATE</div>
        <div class="header-text-div">SESSION</div>
        <div class="header-text-div">CLIENT</div>
        <div class="header-text-div">STATUS</div>
        <div class="search_box_div">
          <img src="../../../assets/img/search-svgrepo-com.svg" alt="">
          <input type="text" name="search" id="" placeholder="Search archived sessions" (input)="filterGameReport($event.target)">
          
      </div>
    </div>
    <div>
        <div class="single-game-main-div" *ngFor="let item of allGameList;let i=index">
            <div [id]="'oneLine'+i" class="display-block">
                <div class="single-game-sub-div" (click)="handleToggleClick(i,'dropDown')">
                    <div><b>{{item.program_date}}</b></div>
                    <div>{{item.program_name}}</div>
                    <div>{{item.client_name}}</div>
                    <div class="completed-status">Completed</div>
                </div>
                <div class="border-div" [id]="'borderLine'+i">&nbsp;</div>
            </div>
            <div [id]="'dropDown'+i" class="display-none" style="font-size: 14px;">
                <div  class="dropdwon" (click)="handleToggleClick(i,'oneLine')">
                    <div class="m-w-13_5"><b>{{item.program_date | date:' d MMMM yyyy'}}</b></div>
                    <div class="m-w-13_5">{{item.program_name}}</div>
                    <div class="m-w-13_5">{{item.client_name}}</div>
                    <div class="completed-status" style="min-width: 7rem;">Completed</div>
                    <div
                        style="width: -webkit-fill-available;  width: -moz-available; width: fill-available;margin-left: 17px;">
                        <div style="display: grid; grid-template-columns: 1fr 1fr;">
                            <div>
                                <div class="dropdwon-list expand-game">
                                    <div class="expand-game-details"
                                      *ngFor="let subject of item.program_games let j = index">
                                      <div class="drop-game-list game-detail">
                                        <div class="game-detail-each-names">
                                          <img class="gameImage" *ngIf="subject.game_name=='Value Continuum'"
                                            src="../../../assets/img/value_continum.svg">
                                          <img class="gameImage" *ngIf="subject.game_name=='Bottle Neck Challenge'"
                                            src="../../../assets/img/bottleneck.svg">
                                          <img class="gameImage" *ngIf="subject.game_name=='Warehouse'"
                                            src="../../../assets/img/warehouse.svg">
                                          <img class="gameImage" *ngIf="subject.game_name=='Animal Farm'"
                                            src="../../../assets/img/animal_faram.svg">
                                          <img class="gameImage" *ngIf="subject.game_name=='Big Picture'"
                                            src="../../../assets/img/bigPicture_withtout_bg.svg">
                                          <img class="gameImage" *ngIf="subject.game_name=='Human Matrix'"
                                            src="../../../assets/img/human_matrix.jpg" class="pyGameImg">
                                          <img class="gameImage" *ngIf="subject.game_name=='String Theory'"
                                            src="../../../assets/img/string_theory.jpg" class="pyGameImg">
                                          <img class="gameImage" *ngIf="subject.game_name=='Stromshelter'"
                                            src="../../../assets/img/strom.jpg" class="pyGameImg">
                                          <img class="gameImage" *ngIf="subject.game_name=='Assemblyline'"
                                            src="../../../assets/img/assembley_line.jpg" class="pyGameImg">
                                          <img class="gameImage" *ngIf="subject.game_name=='Magic Mats'"
                                            src="../../../assets/img/magic_mats.jpg" class="pyGameImg">
                                          <img class="gameImage" *ngIf="subject.game_name=='Stranded'"
                                            src="../../../assets/img/stander.jpg" class="pyGameImg">
                                          <div>{{subject.game_name}}</div>
                                        </div>
                                      </div>
                                      <!-- <div class="drop-game-list">
                                                          <img class="gameImage" src="../../../assets/img/bottleneck.svg"
                                                              class="game-icon-img">
                                                          <div>Bottleneck Challenge</div>
                                                      </div> -->
                                    </div>
                                  </div>
                            </div>
                            <div style="margin: auto;">
                                <div class="sessionbtn" (click)="openSession(item)"><img class="sessionImg"
                                        src="../../../assets/img/Edit Button.svg"><b>Session Dashboard</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>