    <div class="legend_dialog_header">
        <label>Legend Name</label>
        <input type="text" [(ngModel)]="legendDtl[0].name">
    </div>
    <div class="legend_dialog_body">
        <div *ngFor="let legend of legendDtl; let i = index">
            <div class="legend_resource">
                <label>test</label>
                <div class="custom-dropdown" [class.open]="legend.isDropdownOpen">
                    <label for="resource_type" class="addResourceLabel">Resource Type<b>*</b></label><br>
                    <div class="custom-select-wrapper custom-select-container addResourceInput">
                        <select class="form-control addResourceInput" (change)="onSelectChange($event, i)" [(ngModel)]="legendDtl[i].type">
                        <option value="" disabled>Resource Type</option>
                        <option *ngFor="let fileType of fileTypes | keyvalue" [value]="fileType.value">{{ fileType.value }}</option>
                        </select>
                        <i class="dropdown-icon">
                            <img src="../../../../assets/downArrow.svg" alt="Dropdown Icon">
                        </i>
                    </div>
                </div>
            </div>
        </div>
        <div>
            add files legend
        </div>
    </div>
    <div class="legend_dialog_footer">
        <img src="../../../../assets/btn_done.svg">
    </div>