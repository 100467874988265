<div style="margin-right: 3vw;" class="main_py_assign_container">
    <div class="physical_game_main_div">
        <div class="phsical_heading" style="display: flex;">
            <img src="../../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg " alt="" style="width: 22px;cursor: pointer;" (click)="goBack()">
            Physical Games
        </div>
        <div class="physical_body_main_div assign_physical_body_main_div">
            <div class="physical_body_sub_div">
                <div class="assign_heading">Select Games</div>
                <div class="py_game_list_main_div">
                    <div class="grid_2fr">
                        <div *ngFor="let item of gameList" class="py_game_list_div">
                            <input type="checkbox" name="" id="" (click)="handleGameSelection(item.id)" [checked]="gameAcessArray.includes(item.id)" [disabled]="gameAcessArray.includes(item.id)">
                            <span>{{item.game_name}}</span>
                        </div>
                    </div>
                </div>
                <div class="white_card" style="min-width: 300px;margin-bottom: 2rem;"
                    *ngIf="selectedGamesList.length > 0">
                    <div class="">
                        <div class="grid_2fr_center selected_game_heading">
                            <div>Selected Games</div>
                            <div>No. of Game kits</div>
                        </div>
                        <div *ngFor="let item of selectedGamesList" class="grid_2fr_center selected_game_list">
                            <div>{{item.game_name}}</div>
                            <div><input type="number" [(ngModel)]="item.game_kit"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="py_expiry_main_div">
            <div class="py_expiry_start_end_div">
                <div class="py_expiry_element_div">
                    <div>Start Date</div>
                    <input type="date" name="" id="" class="date_inp" [(ngModel)]="start_date" (change)="dateValidityChangeHandler()">
                </div>
                <div class="py_expiry_element_div">
                    <div>End Date</div>
                    <div>{{end_date | date:'dd/MM/yyyy'}}</div>
                </div>
            </div>
            <div class="py_expiry_validity_div">
                <div class="py_expiry_validity_sub_div">
                    <div>Validity</div>
                    <div class="py_expiry_validity_inp_div">
                        <input type="number" name="" id="" [(ngModel)]="validity" (keydown)="onKeyDown($event)"  (input)="dateValidityChangeHandler()">
                        <span>Months</span>
                    </div>
                </div>  
                <div>&nbsp;</div>
            </div>
        </div>
        <div class="assign_new_btn_div">
            <button class="btn_custom" (click)="assignGame()">Assign Games</button>
        </div>
    </div>
</div>