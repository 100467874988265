import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { DashboardService } from 'src/app/Shared/Services/Dashboard.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-archive-schedule',
  templateUrl: './archive-schedule.component.html',
  styleUrls: ['./archive-schedule.component.css'],
  providers: [DatePipe],
})
export class ArchiveScheduleComponent implements OnInit {

  constructor(
    private dataShare: DataSharingServiceService,
    private dashboardService: DashboardService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getAllGameData()
  }
  allGameListApiData: any
  allGameList: any
  handleToggleClick(id: any, type: any) {
    if (type == 'dropDown') {
      if (document.getElementsByClassName('acrive-drop').length > 0) {
        let tempId: string = document.getElementsByClassName('acrive-drop')[0].id.slice(8)
        document.getElementById('oneLine' + tempId)?.classList.add('display-block')
        if (tempId != '0') {
          document.getElementById('borderLine' + (parseInt(tempId) - 1))?.classList.add('display_block_border')
        }
        document.getElementById('oneLine' + tempId)?.classList.remove('display-none')
        document.getElementsByClassName('acrive-drop')[0].classList.add('display-none')
        document.getElementsByClassName('acrive-drop')[0].classList.remove('display-block')
        document.getElementsByClassName('acrive-drop')[0].classList.remove('acrive-drop')
      }
      document.getElementById(type + id)?.classList.remove('display-none')
      document.getElementById(type + id)?.classList.add('display-block')
      document.getElementById(type + id)?.classList.add('acrive-drop')
      document.getElementById('oneLine' + id)?.classList.remove('display-block')
      document.getElementById('oneLine' + id)?.classList.add('display-none')
      if (id != 0) {
        if (document.getElementsByClassName('display_block_border').length > 0)
          document.getElementById('borderLine' + (id - 1))?.classList.remove('display_block_border')
        document.getElementById('borderLine' + (id - 1))?.classList.add('display_none_border')
      }
    }
    else if (type == 'oneLine') {
      document.getElementById(type + id)?.classList.remove('display-none')
      document.getElementById(type + id)?.classList.add('display-block')
      document.getElementById('dropDown' + id)?.classList.remove('display-block')
      document.getElementById('dropDown' + id)?.classList.add('display-none')
      if (id != 0) {
        document.getElementById('borderLine' + (id - 1))?.classList.remove('display_none_border')
        document.getElementById('borderLine' + (id - 1))?.classList.add('display_block_border')
      }
    }
  }

  openSession(data: any) {
    this.dashboardService.selectedArchiveSession = data;
    // if(data.program_games[0].game_name!='Big Picture')
    this.router.navigate(['/sessionArchive'])
    // else{
    //   this.router.navigate(['/bigPicture'])
    // }
  }

  goToDashBoard() {
    this.router.navigate(['/dashboard'])
  }

  filterGameReport(val:any){
    let temp = this.allGameListApiData.filter((ele: any) => ele.program_name.toLowerCase().includes(val.value.toLowerCase()) | ele.client_name?.toLowerCase().includes(val.value.toLowerCase()))
    // if(temp.length == 0) this.allGameListApiData = this.allGameList
    // else
    this.allGameList = temp
  }











  getAllGameData() {
    this.httpSv.getAllCompletedGame().subscribe((res: any) => {
      if (res['status']) {
        this.allGameListApiData = res['result'].slice(0,500)
        this.allGameList = res['result'].slice(0,500)
      }
      else {
        this.toster.info('No Game Data Found', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
    })
  }

}
