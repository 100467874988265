<div style="height: 100vh;">
  <app-header [oneHeader]="'My Games'"></app-header>
  <div class="detailedGamesMainDiv" *ngIf="selectedExplore" style="padding-top: 0;">
    <div class="leftContainer">
      <div class="individualSelections" [ngClass]="{highlightdigital:selectedExplore=='digital'} "
        (click)="selectedExploreGame('digital')" *ngIf="allowedGameId && allowedGameId.includes(1)">
        <img src="../../../assets/img/digitalGame.svg"
          [ngClass]="{rounded:selectedExplore=='digital',circle:selectedExplore!='digital'} ">
        <b>Digital Games</b>
        <i class="arrow right arrow-digital" *ngIf="selectedExplore=='digital'"></i>
      </div>
      <div class="individualSelections" [ngClass]="{highlightphysical:selectedExplore=='physical'} "
        (click)="selectedExploreGame('physical')" *ngIf="allowedGameId && allowedGameId.includes(2)">
        <img src="../../../assets/img/physicalGame.svg"
          [ngClass]="{rounded:selectedExplore=='physical',circle:selectedExplore!='physical'} ">
        <b>Physical Games</b>
        <i class="arrow right arrow-physical" *ngIf="selectedExplore=='physical'"></i>
      </div>
      <div class="individualSelections" style="cursor: default;">
        &nbsp;
      </div>
    </div>
    <div class="rightContainer_myGame"
      [ngClass]="selectedExplore=='physical'?'bg-green':selectedExplore=='questico'?'bg-yellow':''">
      <div style="height: 100%;">
        <div class="myGameContainer" *ngIf="selectedExplore=='digital' && allowedGameId && allowedGameId.includes(1)" >
          <div class="digital_header_main_div">
            <!-- <div class="expiry_msg_div" role="alert" *ngIf="flag">
              <div>Your validity is going to expire soon! Reach out to us for renewal.</div>
            </div> -->
            <div style="margin-right: 3rem;">
              <img class="back_btn_img" src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"
                (click)="backClicked()">
            </div>
            <div style="display: flex; gap: 20px;align-items: center;height: 100%;">
              <div class="digital_header_sub_div">
                <div class="q_info_heading">Licenses</div>
                <div>&nbsp;</div>
                <div class="q_info_sub_heading">Left :</div>
                <div class="q_info_sub_num"><b>{{licensesDtls.left}}</b></div>
                <div class="q_info_sub_heading">Purchased :</div>
                <div class="q_info_sub_num"><b>{{licensesDtls.purchased}}</b></div>
              </div>

              <div class="digital_header_sub_div">
                <div class="q_info_heading">Validity</div>
                <div>&nbsp;</div>
                <div class="q_info_sub_heading">Access available till :</div>
                <div class="q_info_sub_num"><b>{{validity.access_till | date:' dd MMMM yyyy'}}</b></div>
                <div class="q_info_sub_heading">Purchased on :</div>
                <div class="q_info_sub_num"><b>{{validity.purchased_on | date:' dd MMMM yyyy'}}</b></div>
              </div>
            </div>
            <div style="flex: 1;">&nbsp;</div>
            <div class="digital_header_q_btn_div">
              <div>
                <button class="digital_header_q_btn" (click)="openReachOut()">
                  <span>Reachout</span>
                  <img src="../../../assets/reach_out_icon.svg" alt="">
                </button>
              </div>
              <div>
                <button class="digital_header_q_btn" (click)="goToGameReport()">
                  <span>Game Reports</span>
                  <img src="../../../assets/reach_out_icon.svg" alt="">
                </button>
              </div>
            </div>
          </div>
          <div class="myGames myGamesDis">
            <div class="myGame_img_div" *ngFor="let game of digitalGamesAllowed" (click)="goToGameResource(game.id)">
              <img src="../../../assets/img/myGames/vc.png" *ngIf="game.name=='Value Continuum'" (click)="goToGameResource(game.id)">
              <img src="../../../assets/bn_one_line.svg" *ngIf="game.name=='Bottle Neck Challenge'" (click)="goToGameResource(game.id)">
              <img src="../../../assets/img/explore_wh.svg" *ngIf="game.name=='Ware House'" (click)="goToGameResource(game.id)">
              <img src="../../../assets/img/myGames/af.png" *ngIf="game.name=='Animal Farm'" (click)="goToGameResource(game.id)">
              <img src="../../../assets/img/myGames/bp.png" *ngIf="game.name=='Big Picture'" (click)="goToGameResource(game.id)">
              <img src="../../../assets/img/Picture_Perfect_Logo.png" *ngIf="game.name=='Picture Perfect'" (click)="goToGameResource(game.id)">
            </div>

          </div>

        </div>
        <div class="myGameContainer" *ngIf="selectedExplore=='physical' && allowedGameId && allowedGameId.includes(2)">
          <div style="align-self: flex-start;position: sticky;top: 0;width: 100%;">
            <!-- <div class="expiry_msg_div" role="alert" *ngIf="flag">
              <div>Your validity is going to expire soon! Reach out to us for renewal.</div>
            </div> -->
            <div style="margin-right: 3rem;margin-top: 1rem;margin-left: 1rem;max-width: 2rem;">
              <img class="back_btn_img" style="background: #fff;"
                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" (click)="backClicked()">
            </div>
          </div>
          <div class="myGames">
            <div class="games_count_main_div"><span>Games</span>&nbsp;<span>{{pyhsicalGamesAllowed.length}}</span></div>
            <div class="myGames_sub_div" *ngFor="let item of pyhsicalGamesAllowed">
              <div class="myGameImg" style="height: 250px;width: 25%;">
                <div
                  style="height: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <img src="../../../assets/img/myGames/human_matrix.svg" alt="" *ngIf="item.game_id == 8">
                  <img src="../../../assets/img/string_therory.png" alt="" *ngIf="item.game_id == 9">
                  <img src="../../../assets/img/strom_selter.png" alt="" *ngIf="item.game_id == 10">
                  <img src="../../../assets/img/assebembly_line.png" alt="" *ngIf="item.game_id == 11">
                  <img src="../../../assets/img/magic_mats.png" alt="" *ngIf="item.game_id == 12">
                  <img src="../../../assets/img/stranded.png" alt="" *ngIf="item.game_id == 13">
                </div>
              </div>

              <div style="max-width: 20%;text-align: justify;width: 20%;">
                <div class="game_kit_main_div">
                  <div class="game_kit_sub_div">
                    <img src="../../../assets/img/myGames/game_kit_icon.svg" alt="">
                    <div class="game_kit_info">
                      <div>{{item.game_kits}}</div>
                      <div>Game Kits</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="myGameDate">
                <div class="myGameExpiry" style="width: 100%;text-align: center;">
                  <div class="expiry_sub_div">
                    <div>
                      <span>Purchased on:</span>
                      <span>{{epochToHumanDate(item.start_date) | date: 'dd MMMM yyyy'}}</span>
                    </div>
                    <div>
                      <span>Access available till:</span>
                      <span>{{epochToHumanDate(item.end_date) | date: 'dd MMMM yyyy'}}</span>
                    </div>
                  </div>
                </div>
                <div class="gameResurce" style="margin-top:1rem;padding: 16px 33px;max-width: 15rem;font-size: 19px;"
                  (click)="goToGameResource(item.game_id)">
                  Game Resources
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="myGameContainer" *ngIf="selectedExplore=='questico'">
          <div class="myGames">
            <div class="myGameImg" style="height: 250px;width: 25%;">
              <div
                style="height: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <div style="font-size: 34px;font-weight: 500;">QUESTICO</div>
                <!-- <div style="font-size: 34px;font-weight: 200;">Games</div> -->
              </div>
            </div>
            <div style="max-width: 27%;text-align: justify;">
              Use this race themed customizable platform to create a gamified learning experience for your cohort,
              either as a single player or team based game.This link will take you directly to the Questico dashboard
              where you can create or choose challenges and other parameters that will define your game.
            </div>
            <div class="myGameDate">
              <div class="myGameExpiry" style="width: 55%;text-align: center;" *ngIf="!adminFlag">
                <b>THIS IS TRIAL ACCOUNT ACTIVE FOR 21 DAYS</b>
              </div>
              <div class="gameResurce" style="margin-top:1rem;padding: 16px 33px;max-width: 15rem;font-size: 19px;"
                (click)="goToGameResource('questico')">
                Games
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>