import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingServiceService } from '../services/data-sharing-service.service';
import { HttpService } from '../services/http.service';
import { DashboardService } from '../Shared/Services/Dashboard.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() first: any = null
  @Input() secound: any
  @Input() third: any
  @Input() oneHeader: any
  @Input() adminFlag: boolean = false
  constructor(private router: Router, private dataShare: DataSharingServiceService, private httpSv: HttpService,private dashboardService: DashboardService) {
  }
  toggleFlag: boolean = false
  username: string | null = ''
  ngOnInit(): void {
    // if(localStorage.getItem("role") == "1"){
    //   this.adminFlag = true
    // }
    this.username = localStorage.getItem('userName')
  }
  routeLandingPage() {
    if (this.adminFlag == true) {
      this.router.navigate(['/admin-dashboard'])
    } else {
      this.router.navigate(['/dashboard'])
      this.dataShare.exploreGameSelection = null
    }
  }
  goToExplore() {
    this.dataShare.exploreGameSelection = ''
    this.router.navigate(['/exploreGames'])
  }
  logout() {
    // this.router.navigate(['/login'])
    this.httpSv.logOut().subscribe((res: any) => {
      if (this.adminFlag == true) {
        this.router.navigate(['/admin'])
      } else
        this.router.navigate(['/login'])
    })
  }
  goToMyGame() {
    this.dataShare.myGames = null
    this.router.navigate(['myGames'])
  }
  goGameReport(){
    this.dashboardService.archiveFlag = true
    this.router.navigate(['/scheduled'])
  }
  goToAccountSetting() {
    this.toggleFlag = false
    this.router.navigate(['/accountSetting'])
  }
  tempCount: any = 0
  toggleFunc() {
    this.toggleFlag = !this.toggleFlag
    if (!this.toggleFlag)
      this.tempCount = 0
  }
  outSideClickHandle(event: any) {
    if (event) {
      if (this.toggleFlag) {
        if (this.tempCount == 1) {
          this.toggleFlag = false
          this.tempCount = 0
        }
        else
          this.tempCount = 1
      }
    }
  }
  goToCalendar() {
    this.router.navigate(['/config/status'])
  }
}
