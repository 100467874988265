import { Component, EventEmitter, Inject, NgModule, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { Location } from '@angular/common';

interface UserTableRow {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  role_id: string;
  created_at: string;
  last_login_at: string;
  reachout_flag: any;
  options: any;
  isHovered?: boolean; // Optional property to track hover state
  isDropdownOpen?: boolean;

}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  @Output() backEmitter = new EventEmitter<any>();

  compRoute: string = 'manageUser';

  constructor(
    private dataService: DataSharingServiceService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private router: Router,
    public dialog: MatDialog
  ) { }


  ngOnInit(): void {

    this.header = 'Users';
    this.dataService.userData = {};
    this.getAllUser()
    this.role_name = this.dataService.role_id_name
  }
  header: string = '';
  raisedHandIcon: string = '../../../../assets/raisedHand.svg'
  allUsers: UserTableRow[] = [];

  role_name: any
  goBack() {
    this.backEmitter.emit('home')
  }

  changeCompRoute(route: any) {
    if (route == 'addUser') {
      this.dataService.addEditUser = route;
      this.router.navigate(['/add-user']);
    }
  }

  getRole(role_id: any) {
    return this.role_name[role_id];
  }

  getDateFormat(timestamp: any) {
    let formattedDate;
    if (timestamp !== null) {
      const dateObject = new Date(timestamp);
      const year = dateObject.getFullYear().toString().slice(-2);
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      const day = dateObject.getDate().toString().padStart(2, '0');

      formattedDate = `${day}/${month}/${year}`;
    } else {
      formattedDate = 'N/A';
    }

    return formattedDate;
  }

  onRowHover(isHovered: boolean, user: UserTableRow): void {
    user.isHovered = isHovered;
    if (!isHovered) {
      user.isDropdownOpen = false;
    }
  }

  addNewUser() {
    this.dataService.addEditUser = 'addUser';
    this.router.navigate(['/add-user']);
  }
  getAllUser() {
    this.httpSv.getAllUserList().subscribe((res: any) => {
      if (res['status']) {
        this.allUsers = res['results']
      } else {
        this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
    })
  }

  onOptionClick(option: string, user: any) {
    // Handle the option click here
    if (option == 'assignGame') {
      this.router.navigate(['/assign-game'], { queryParams: { id: user.id } })
    } else if (option == 'editUser') {
      // this.dataService.userData = user;
      // this.dataService.addEditUser = 'editUser';
      this.router.navigate(['/edit-user'], { queryParams: { id: user.id } });
    }
  }

  toggleDropdown(user: any) {
    // Toggle the dropdown state for the user
    user.isDropdownOpen = !user.isDropdownOpen;
  }

}


// Add New User 
// Add New User 
// Add New User 
// Add New User 

@Component({
  selector: 'app-user-add-user',
  templateUrl: './user-new-add-dialog.html',
  styleUrls: ['./user-management.component.css']
})
export class UserAddComponent implements OnInit {
  constructor(
    private dataService: DataSharingServiceService,
    private httpSv: HttpService,
    private router: Router,
    private toster: ToastrService,
    private route: ActivatedRoute,
    private location: Location,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((result: any) => {
      if (result.id) {
        this.httpSv.userDetails(result.id).subscribe((res: any) => {
          const data = res['result'][0]
          console.log(res['result'][0])
          for (const key in data) {
            if (this.inputFields.hasOwnProperty(key)) {
              if (key == 'role_id') {
                this.userData['user_type'] = data[key];
              } else {
                this.userData[key] = data[key];
              }
            }
          }
          this.userId = result.id;
          this.header = 'Edit User';
          this.addEditUser = 'editUser'
          this.selectedRole = this.userData.user_type;
        })
      }
      else {
        this.header = 'Add User';
        this.addEditUser = 'addUser'
      }

      console.log(this.userData, this.selectedRole)
    })
    console.log(this.dataService.userData)
    // if(Object.keys(this.dataService.userData).length > 0){
    //   const data = this.dataService.userData;
    //   for (const key in data) {
    //     if (this.inputFields.hasOwnProperty(key)) {
    //       if(key == 'role_id'){
    //         this.userData['user_type'] = data[key];
    //       } else{
    //         this.userData[key] = data[key];
    //       }
    //     }
    //   }
    //   this.userId = this.dataService.userData.id;
    // }

    // if(this.dataService.addEditUser == ''){
    //   this.router.navigate(['/user-list']);
    // } else{
    //   this.addEditUser = this.dataService.addEditUser;
    //   if(this.dataService.addEditUser == 'addUser'){
    //     this.header = 'Add User';
    //   } else if(this.dataService.addEditUser == 'editUser'){
    //     this.header = 'Edit User';
    //   }
    // }


  }

  emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])[\w\d\W_]{6,}$/;
  // passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z])[\w\d]{6,}$/;

  header: string = '';
  tempPassword: string = 'XXXXXXXX';

  options: any = {
    1: 'Super Admin',
    3: 'Facilitator'
  }

  addEditUser = '';
  userId = '';
  isDropdownOpen: boolean = true;
  selectedRole: any;

  userData: any = {
    first_name: '',
    last_name: '',
    email: '',
    contact: '',
    password: '',
    user_type: 0,
    organisation: '',
    bio: ''
  }

  inputFields: any = {
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email Address',
    contact: 'Phone Number',
    password: 'Password',
    user_type: 'User Type',
    role_id: 'User Type',
    organisation: 'Organisation',
    bio: 'Bio/brief'
  }

  roles = {
    1: 'Super Admin',
    3: 'Facilitator'
  }

  changeCompRoute(route: any) {
    if (route == 'addUser') {
      this.router.navigate(['/user-list']);
    }
  }

  clearInputValues() {
    this.userData = {
      first_name: '',
      last_name: '',
      email: '',
      contact: '',
      password: '',
      user_type: 0,
      organisation: '',
      bio: ''
    }
  }

  onOptionClick(option: string,) {
    // Handle the option click here
    if (option == 'assignGame') {
    } else if (option == 'editUser') {
      this.dataService.addEditUser = 'editUser';
      this.router.navigate(['/edit-user']);
    }
  }

  onSelectChange(event: any) {
    this.userData.user_type = 1;
    this.selectedRole = event.target.value;
    this.userData.user_type = Number(this.selectedRole);
  }

  toggleDropdown() {
    // Toggle the dropdown state for the user
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  addNewUser() {
    let emptyField = false;
    let fieldName = '';

    const userInputData = { ...this.userData };

    if (this.addEditUser == 'editUser') {
      if (userInputData.password == '') {
        delete userInputData.password;
      }
      delete userInputData.email;
    }

    for (const key in userInputData) {
      if (userInputData.hasOwnProperty(key) && !(key == 'contact' || key == 'bio')) {
        const value = userInputData[key];
        if (value == '' || value == 0) {
          emptyField = true;
          fieldName = key;
          break;
        }
      }
    }
    if (emptyField) {
      this.toster.warning(`Please fill the ${this.inputFields[fieldName]}`, '', { timeOut: 2000 })
    } else {
      if ((userInputData.hasOwnProperty('password') && this.passwordRegex.test(userInputData.password)) || !userInputData.hasOwnProperty('password')) {
        if (this.addEditUser == 'addUser') {
          if (this.emailRegex.test(userInputData.email)) {
            if (!userInputData.bio) delete userInputData.bio
            if (!userInputData.contact) delete userInputData.contact
            else userInputData.contact = parseInt(userInputData.contact)
            userInputData.role_id = userInputData.user_type
            delete userInputData.user_type
            this.httpSv.addNewUser(userInputData).subscribe((res: any) => {
              if (res['status']) {
                this.toster.success('User added successfully', '', { timeOut: 2000 })
                this.router.navigate(['/user-list']);
              } else {
                this.toster.error('Opps! Something went wrong', '', { timeOut: 2000 })
              }
            }, (err: any) => {
              let errMsg 
              console.log(err.error)
              for(const key in err.error ){
                errMsg =  err.error[key]
                console.log(err.error[key])
              }
              this.toster.error(errMsg, '', { timeOut: 2000 })
            })
          } else {
            this.toster.warning('Please enter a valid email id', '', { timeOut: 2000 })
          }
        } else if (this.addEditUser == 'editUser') {
          if (!userInputData.bio) delete userInputData.bio
          userInputData.role_id = userInputData.user_type
          delete userInputData.user_type
          if (!userInputData.contact) delete userInputData.contact
          else userInputData.contact = parseInt(userInputData.contact)
          this.httpSv.updateUser(this.userId, userInputData).subscribe((res: any) => {
            if (res['status']) {
              this.toster.success('User updated successfully', '', { timeOut: 2000 })
              this.router.navigate(['/user-list']);
            } else {
              this.toster.error('Opps! Something went wrong', '', { timeOut: 2000 })
            }
          }, (err: any) => {
            this.toster.error('Opps! Something went wrong', '', { timeOut: 2000 })
          })
        }
      } else {
        this.toster.warning('Password should have minimum 6 digits containing atleast one number & character.', '', { timeOut: 4000 })
      }
    }
  }
}