<app-header [oneHeader]="header" [adminFlag]="true"></app-header>
<div class="addUser">
    <div style="display:flex; flex-direction:row;  gap:20px; width: 80vw; height:75vh; align-self:center; justify-self:center ">
        <div class="grid-container">
            <div class="grid-item">
                <label for="name" class="addUserLabel">First Name<b>*</b></label> <br>
                <input type="text" class="form-control addUserInput" [(ngModel)]="userData.first_name"> <br>
                <label for="organisation" class="addUserLabel">Organisation<b>*</b></label> <br>
                <input type="text" class="form-control addUserInput" [(ngModel)]="userData.organisation"> <br>
                <label for="email" class="addUserLabel">Email Address<b>*</b></label><br>
                <input type="email" class="form-control addUserInput" [(ngModel)]="userData.email" [disabled]="addEditUser == 'editUser'"> <br>
                <label for="contact" class="addUserLabel">Phone Number</label><br>
                <input type="number" class="form-control addUserInput" [(ngModel)]="userData.contact"> <br>
            </div>
            <div class="grid-item">
                <label for="last_name" class="addUserLabel">Last Name<b>*</b></label> <br>
                <input type="text" class="form-control addUserInput" [(ngModel)]="userData.last_name"> <br>
                <label for="user_type" class="addUserLabel">User Type<b>*</b></label><br>
                <div class="custom-select-wrapper custom-select-container addUserInput">
                    <select class="form-control addUserInput" (change)="onSelectChange($event)" [(ngModel)]="selectedRole">
                      <option value="" disabled>Select user type</option>
                      <option *ngFor="let role of roles | keyvalue" [value]="role.key">{{ role.value }}</option>
                    </select>
                    <i class="dropdown-icon">
                        <img src="../../../../assets/downArrow.svg" alt="Dropdown Icon">
                    </i>
                </div>
                <br><br>
                <label for="password" class="addUserLabel">Password<b *ngIf="addEditUser == 'addUser'">*</b></label><br>
                <input type="text" class="form-control addUserInput"  [(ngModel)]="userData.password" [value]="addEditUser === 'addUser' ? '' : null" placeholder="XXXXXX">
            </div>
            <div class="grid-item-bio">
                <label for="bio" class="addUserLabel">Bio/Brief</label><br>
                <textarea type="text"  class="form-control addUserInput square-input" [(ngModel)]="userData.bio"></textarea> <br>
            </div>
        </div>
    </div>
    <div style="display: flex; gap: 20px;">
        <button style="min-width: 10%; max-width: 35%; border: none; background: none; padding: 0;" (click)="addNewUser()">
            <img *ngIf="addEditUser == 'addUser'" src="../../../../assets/addUser.svg" alt="add user">
            <img *ngIf="addEditUser == 'editUser'" src="../../../../assets/editUser.svg" alt="edit user">
        </button>
        <button style="min-width: 10%; max-width: 35%; border: none; background: none; padding: 0;" (click)="addNewUser()">
        </button>
    </div>
</div>