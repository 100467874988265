import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../Shared/Services/Login.service';
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from '../../../services/data-sharing-service.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  constructor(private router: Router, private loginService: LoginService, private toster: ToastrService, private dataShare: DataSharingServiceService) {
  }

  ngOnInit(): void {
    localStorage.clear()
    // if(localStorage.getItem('token'))
    // {
    //   this.router.navigate(['/dashboard'])
    // }
  }
  submit(form: any) {
    if (form.email == "calendar@playkairos.com" && form.password == "calendar@123") {
      localStorage.setItem('caltoken', "384|azgzGIVJBdJCalendarTrj0z8gkr649MIrAGeFbjeIlEI0XO")
      this.router.navigate(['/config/status'])
    }
    else {
      form.type = 1
      this.loginService.login(form).subscribe((res: any) => {
        if (res.status == true) {
          localStorage.setItem('token', res.token);
          // localStorage.setItem('is_questico_access', res.user.is_questico_access);
          localStorage.setItem('token', res.token);

          localStorage.setItem('userName', res.user.name)
          localStorage.setItem('contact', res.user.contact)
          localStorage.setItem('userEmail', res.user.email)
          localStorage.setItem('ids', res.user.id)
          if (res.user.role_id == 1) {
            this.router.navigate(['/admin-dashboard'])
            localStorage.setItem('role', '1')
          }
          else
            this.varifyDate(res.user.expiry_epoch_time)
        }
      }, (error: any) => {
        this.toster.error("Invalid Email or Password", '', { timeOut: 2000 })
      })
    }

  }
  falg: boolean = false
  varifyDate(date: any) {
    let date1: any = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date1.setUTCSeconds(date)
    date1 = date1.toDateString()
    let date2 = new Date().toDateString()
    if (Date.parse(date1) > Date.parse(date2)) {
      this.dataShare.expireDate = date
      this.router.navigate(['/dashboard']);
    } else if (Date.parse(date1) < Date.parse(date2)) {
      this.falg = true
    } else {
      localStorage.setItem('expireToday', 'true')
      this.router.navigate(['/dashboard']);
    }

  }
}

