import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  userType: any;
  constructor(private router: Router) {
    
  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (localStorage.getItem('token')) {
        // logged in so return true
        return true;
      }
      else{
      localStorage.clear()
      this.router.navigate(['/login']);
      return false;
      }
  }
  calenderActive(){
    if(localStorage.getItem("token")){
      if(localStorage.getItem("token") == "384|azgzGIVJBdJCalendarTrj0z8gkr649MIrAGeFbjeIlEI0XO"){
        return true
      }
      else return false
    }
    else return false
  }
}
