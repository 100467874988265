import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/Shared/Services/Dashboard.service';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { Columns, PdfMakeWrapper, Table, Txt } from 'pdfmake-wrapper';
import { Subscription, interval } from 'rxjs';
@Component({
  selector: 'app-session-dashboard',
  templateUrl: './session-dashboard.component.html',
  styleUrls: ['./session-dashboard.component.css'],
  providers: [DatePipe],
})
export class SessionDashboardComponent implements OnInit {
  value = "above"
  @Input('matTooltipHideDelay')
  hideDelay: number = 1000;
  programDetails: any;

  constructor(private dialog: MatDialog, private dashboardService: DashboardService, private router: Router, private dataSharingService: DataSharingServiceService,
    private httpSv: HttpService,
    private location: Location,
    private toster: ToastrService,
    private dataShare: DataSharingServiceService,
    private clipboard: Clipboard,

  ) { }
  headerMsg: string = "Session Dashboard"
  bigPictureExist: boolean = false
  date: any
  ngOnInit(): void {
    localStorage.removeItem('uuid')
    if (this.dashboardService.selectedSession != null) {
      this.programDetails = this.dashboardService.selectedSession;
      this.tinyUrl = this.programDetails.participants_url
      this.tinyFunction(this.programDetails.participants_url, this.programDetails.id)
      this.programDetails.program_games.forEach((ele: any) => {
        if (ele.game_id == 6) {
          this.bigPictureExist = true
          return
        }
      })
    }
    else
      this.router.navigate(['/dashboard'])

    this.pysicalTest()

    this.programDetails.program_games.forEach((element: any) => {
      if (element.status == 'Running') {
        this.statusLiveFlag = true
      }
    });

    this.todayDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
    console.log(this.programDetails)
    if (this.programDetails.slots.length != 0) {
      const d = new Date(this.programDetails.slots.start_time * 1000).toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
      this.date = d
      this.gameConfigureDate = d
    } else {
      // this.gameConfigureDate = this.programDetails.formatted_date
    }
    if (Date.parse(this.todayDate) == Date.parse(this.gameConfigureDate)) {
      this.sessionStartFlag = true
    }
    const temp = this.programDetails.slots.start_time
    const temp1 = this.programDetails.slots.end_time * 1000
    this.sessionConfigDate = `${new Date(temp * 1000).getFullYear()}-${new Date(temp * 1000).getMonth() + 1}-${new Date(temp * 1000).getDate()}`
    this.sessionConfigTime = `${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(temp * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(temp1))}`
  }
  sessionConfigDate: any
  sessionConfigTime: any
  sessionStartFlag: boolean = false
  todayDate: any;
  gameConfigureDate: any;
  statusLiveFlag: boolean = false
  pyFlag: boolean = false
  pysicalTest() {
    const data = this.programDetails.program_games[0]
    if (data.game_id > 7 && data.game_id < 14)
      this.pyFlag = true
  }
  openFacLink(link: any) {
    window.open(link)
  }
  show() {
    document.getElementById('tooltip')
  }
  openpopup(val: any, program_id: any, slots: any) {
    const data = {
      val,
      program_id,
      slots,
    }
    const startTime = new Date(data.slots.start_time * 1000)
    const hoursStart = startTime.getHours();
    const minutesStart = startTime.getMinutes();
    const endTime = new Date(data.slots.end_time * 1000)
    const hoursEnd = endTime.getHours();
    const minutesEnd = endTime.getMinutes();
    const currentEpochTime = Math.floor(new Date().getTime() / 1000);
    console.log(startTime)
    if (data.slots.start_time > currentEpochTime) {
      this.toster.info(`Session can't be start before ${hoursStart.toString().padStart(2, '0')}:${minutesStart.toString().padStart(2, '0')}`)
    }
    else if (data.slots.end_time < currentEpochTime) {
      this.toster.info(`Session can't be start after ${hoursEnd.toString().padStart(2, '0')}:${minutesEnd.toString().padStart(2, '0')}`)
    }
    // else if (data.slots.start_time <= currentEpochTime && data.slots.end_time >= currentEpochTime) {
    else {
      this.httpSv.updateProgramStatus(data['program_id']).subscribe((res: any) => {
        if (res['status']) {
          this.programDetails.status = 'Running'
          this.openGCR(val)
        } else {
          this.toster.error(res['message'], '', { timeOut: 5000 })
        }
      }, (err: any) => {
        this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
      })


    }


    // const dialogRef = this.dialog.open(VcDeleteDialog, {
    //   disableClose: false,
    //   minWidth: '40rem',
    //   maxWidth: '60rem',
    //   data: {
    //     val,
    //     program_id,
    //     slots,
    //   }
    // })
    // dialogRef.afterClosed().subscribe((result: any) => {
    //   if (result == true) {
    //     this.programDetails.status = 'Running'
    //   }
    // })
  }
  openGCR(link: any) {
    window.open(link)
  }
  openScheduledPage() {
    if (this.dataShare.dashBoradRedir) {
      this.router.navigate(['/dashboard'])
      this.dataShare.dashBoradRedir = null
    }
    else
      this.router.navigate(['/scheduled'])
  }
  editGame(game: any, oneGame: any) {
    if (this.statusLiveFlag) {
      this.toster.info("Live game can't be edit", '', { timeOut: 2000 })
    }
    else {
      if (this.timeValidatorForEdit(game.slots.start_time, game.slots.end_time)) {
        this.httpSv.getProgramStatus(game.id).subscribe((res: any) => {
          if (res['status']) {
            let statusFlag = true
            const temp = res.result[0].status
            if (temp != 'Configured') statusFlag = false
            if (statusFlag) {


              var data: any;
              switch (oneGame.game_id) {
                case 1:
                  data = [`{"name":"Value Continuum","gameId":"1","imgUrl":"../../assets/img/value_continum.svg"}`];
                  break;
                case 2:
                  data = [`{"name":"Bottle Neck","gameId":"2","imgUrl":"../../assets/img/bottleneck.svg"}`];
                  break;
                case 3:
                  data = [`{"name":"Warehouse","gameId":"3","imgUrl":"../../assets/img/warehouse.svg"}`];
                  break;
                case 4:
                  data = [`{"name":"Picture Perfect","gameId":"4","imgUrl":"../../assets/img/picturePerfect.svg"}`];
                  break;
                case 5:
                  data = [`{"name":"Animal Farm","gameId":"5","imgUrl":"../../assets/img/animal_faram.svg"}`];
                  break;
                case 6:
                  data = [`{"name":"Big Picture","gameId":"6","imgUrl":"../../assets/img/bottleneck_logo.svg"}`];
                  break;


              }

              this.dataSharingService.selectedGameForConfig = data;


              let noOfSlot = []
              for (let i = 0; i < +game.slots.no_of_slots; i++) { noOfSlot.push('') }
              this.dataSharingService.newSetup = false;
              this.dataSharingService.sessionData = {
                sessionName: game.program_name,
                sessionDate: game.program_date,
                clientName: game.client_name,
                programId: game.id,
                maxPlayer: game.maxPlayerNum,
                singleEdit: true,
                timeSlotDtls: {
                  date: this.sessionConfigDate,
                  time: this.sessionConfigTime
                },
                noOfSlot,
                sessionSlot: this.sessionConfigTime,
                sessionPlayer: game.maxPlayerNum
              }
              this.dataSharingService.timeSlotData = {
                language: 'en',
                players: game.maxPlayerNum,
                program_id: game.id,
                stringDateTime: this.sessionConfigTime,
                timeslots: noOfSlot
              }
              this.dataSharingService.currentSelect = JSON.parse(data[0])['gameId']
              data.forEach((ele: any) => {
                this.dataSharingService.sessionTempEditData[JSON.parse(ele)['gameId']] = null
              })
              // this.dataSharingService.sessionTempEditData={
              //   2:{

              //   }
              // }
              this.router.navigate(['/configure/games']);

            }
            else {
              this.toster.info('Live game can not be edit', '', { timeOut: 2000 })
            }
          }
        })
      } else {
        this.toster.info('This session is expired.', '', { timeOut: 2000 })
      }
    }

  }
  deleteGame(game: any, oneGame: any, type: string) {
    if (this.statusLiveFlag) {
      this.toster.info("Live game can't be deleted", '', { timeOut: 2000 })
    }
    else {
      this.httpSv.getProgramStatus(game.id).subscribe((res: any) => {
        if (res['status']) {
          let statusFlag = true
          const temp = res.result[0].status
          if (temp != 'Configured') statusFlag = false
          if (statusFlag) {
            const dialogRef = this.dialog.open(OneGameDeleteDialog, {
              disableClose: true,
              minWidth: '30rem',
              maxWidth: '60rem',
              data: oneGame.game_id
            })
            dialogRef.afterClosed().subscribe((res: any) => {
              if (res) {
                if (type == 'py') {
                  this.httpSv.deletePysicalOneGame(game.id, oneGame.game_id).subscribe((res: any) => {
                    if (res['status']) {
                      const index = this.programDetails.program_games.findIndex((ele: any) => ele.game_id == oneGame.game_id)
                      this.programDetails.program_games.splice(index, 1)
                      if (this.programDetails.program_games.length == 0) {
                        this.location.back()
                      }
                    }
                  })
                } else if (type == 'di') {
                  this.httpSv.deleteDigitalOneGame(game.id, oneGame.game_id).subscribe((res: any) => {
                    if (res['status']) {
                      const index = this.programDetails.program_games.findIndex((ele: any) => ele.game_id == oneGame.game_id)
                      this.programDetails.program_games.splice(index, 1)
                      if (this.programDetails.program_games.length == 0) {
                        this.location.back()
                      }
                    }
                  })
                }
              }
            })
          }
          else {
            this.toster.info('Live game can not be deleted', '', { timeOut: 2000 })
          }
        }
      })
    }

  }
  editSession(data: any) {
    if (this.statusLiveFlag) {
      this.toster.info("Live session can't be edit", '', { timeOut: 2000 })
    }
    else {
      if (this.timeValidatorForEdit(data.slots.start_time, data.slots.end_time)) {
        this.httpSv.getProgramStatus(data.id).subscribe((res: any) => {
          if (res['status']) {
            let statusFlag = true
            const temp = res.result[0].status
            if (temp != 'Configured') statusFlag = false
            if (statusFlag) {
              let noOfSlot = []
              for (let i = 0; i < +data.slots.no_of_slots; i++) { noOfSlot.push('') }
              this.dataSharingService.sessionData = {
                sessionName: data.program_name,
                sessionDate: data.program_date,
                clientName: data.client_name,
                programId: data.id,
                maxPlayer: data.maxPlayerNum,
                timeSlotDtls: {
                  date: this.sessionConfigDate,
                  time: this.sessionConfigTime
                },
                noOfSlot
              }
              this.dataSharingService.newSetup = false;
              let tempData: any = []
              data.program_games.forEach((ele: any) => {
                switch (ele.game_id) {
                  case 1:
                    tempData.push(`{"name":"Value Continuum","gameId":"1","imgUrl":"../../assets/img/value_continum.svg"}`)
                    break;
                  case 2:
                    tempData.push(`{"name":"Bottle Neck","gameId":"2","imgUrl":"../../assets/img/bottleneck.svg"}`);
                    break;
                  case 3:
                    tempData.push(`{"name":"Warehouse","gameId":"3","imgUrl":"../../assets/img/warehouse.svg"}`);
                    break;
                  case 4:
                    tempData.push(`{"name":"Picture Perfect","gameId":"4","imgUrl":"../../assets/img/picturePerfect.svg"}`);
                    break;
                  case 5:
                    tempData.push(`{"name":"Animal Farm","gameId":"5","imgUrl":"../../assets/img/animal_faram.svg"}`);
                    break;
                  case 6:
                    tempData.push(`{"name":"Big Picture","gameId":"6","imgUrl":"../../assets/img/bottleneck_logo.svg"}`);
                    break;
                  case 8:
                    tempData.push(`{"name":"Human Matrix","gameId":"8","imgUrl":"../../assets/img/human_matrix.jpg"}`);
                    break;
                  case 9:
                    tempData.push(`{"name":"String Theory","gameId":"9","imgUrl":"../../assets/img/string_theory.jpg"}`);
                    break;
                  case 10:
                    tempData.push(`{"name":"Stromshelter","gameId":"10","imgUrl":"../../assets/img/strom.jpg"}`);
                    break;
                  case 11:
                    tempData.push(`{"name":"Assemblyline","gameId":"11","imgUrl":"../../assets/img/assembley_line.jpg"}`);
                    break;
                  case 12:
                    tempData.push(`{"name":"Magic Mats","gameId":"12","imgUrl":"../../assets/img/magic_mats.jpg"}`);
                    break;
                  case 13:
                    tempData.push(`{"name":"Stranded","gameId":"13","imgUrl":"../../assets/img/stander.jpg"}`);
                    break;
                }
              })
              this.dataSharingService.selectedGameForConfig = tempData;
              this.dataSharingService.currentSelect = JSON.parse(tempData[0])['gameId']
              this.dashboardService.editStep3 = null
              tempData.forEach((ele: any) => {
                this.dataSharingService.sessionTempEditData[JSON.parse(ele)['gameId']] = null
              })
              if (!this.pyFlag)
                this.router.navigate(['/configure']);
              else {
                this.router.navigate(['/configure/physical']);
              }
            }
            else {
              this.toster.info('Live game can not be edit', '', { timeOut: 2000 })
            }
          }

        })
      } else {
        this.toster.info('This session is expired.', '', { timeOut: 2000 })
      }
    }
  }
  showQrCode(link: any, programId: any) {
    const dialogRef = this.dialog.open(QrCodeDialog, {
      disableClose: false,
      minWidth: '300px',
      maxWidth: '400px',
      data: link
    })
    this.clipboard.copy(link)
  }
  redirectToResource(id: any) {
    if (id != 7) {
      this.dataSharingService.lastUrlForGameResource = '/sessionDashboard'
      this.router.navigate(['/game/resources'], { queryParams: { id: id } })
    }
    else {
      this.dataSharingService.lastUrlForGameResource = '/sessionDashboard'
      this.router.navigate(['/game/questico'])
    }
  }
  playLinkFuture() {
    this.toster.info(` Session is configured for date ${this.gameConfigureDate}. Can't start today`, '', { timeOut: 3000 })
  }
  tinyUrl: any
  tinyFunction(link: any, programId: any) {
    this.httpSv.shortUrl(link, programId).subscribe((res: any) => {
      if (res['status']) {
        const url = res.url_result
        this.tinyUrl = url
      }

    }, (err: any) => {
      this.toster.error('Opps!! URL shortning went wrong', '', { timeOut: 2000 })
    })
  }
  bpCode: any
  showCode(id: any) {
    this.httpSv.getBpGameDtls(id).subscribe((res: any) => {
      if (res['status'] == true) {
        this.bpCode = res['result'][0]['codes']
        const dialogRef = this.dialog.open(BigPictureCodeDialog, {
          maxWidth: '50vw',
          data: this.bpCode
        })
      }
    })
  }
  timeValidatorForEdit(start_time: any, end_time: any) {
    const startTime = new Date(start_time * 1000)
    const hoursStart = startTime.getHours();
    const minutesStart = startTime.getMinutes();
    const endTime = new Date(end_time * 1000)
    const hoursEnd = endTime.getHours();
    const minutesEnd = endTime.getMinutes();
    const currentEpochTime = Math.floor(new Date().getTime() / 1000);
    if (end_time < currentEpochTime) return false
    else return true
  }


}

@Component({
  selector: 'confrmation-popup',
  templateUrl: './confirmation-popUp.html',
})
export class VcDeleteDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<VcDeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dialog: MatDialog
  ) { }
  ngOnInit(): void {
  }
  close(data: boolean) {
    this.dialogRef.close(data)
  }
  open() {
    const startTime = new Date(this.data.slots.start_time * 1000)
    const hoursStart = startTime.getHours();
    const minutesStart = startTime.getMinutes();
    const endTime = new Date(this.data.slots.end_time * 1000)
    const hoursEnd = endTime.getHours();
    const minutesEnd = endTime.getMinutes();
    const currentEpochTime = Math.floor(new Date().getTime() / 1000);
    console.log(startTime)
    if (this.data.slots.start_time > currentEpochTime) {
      this.toster.info(`Session can't be start before ${hoursStart.toString().padStart(2, '0')}:${minutesStart.toString().padStart(2, '0')}`)
    }
    else if (this.data.slots.end_time < currentEpochTime) {
      this.toster.info(`Session can't be start after ${hoursEnd.toString().padStart(2, '0')}:${minutesEnd.toString().padStart(2, '0')}`)
    }
    // else if (this.data.slots.start_time <= currentEpochTime && this.data.slots.end_time >= currentEpochTime) {
    else {
      const timeDifferenceInSeconds = Math.abs(this.data.slots.end_time - currentEpochTime);
      const timeDifferenceInMinutes = timeDifferenceInSeconds / 60
      console.log(timeDifferenceInMinutes)
      const hoursDiff = Math.floor(timeDifferenceInMinutes / 60)
      const minutesDiff = Math.floor(timeDifferenceInMinutes % 60)

      const dialogRef = this.dialog.open(TimeSlotDialog, {
        hasBackdrop: true,
        disableClose: true,
        data: {
          program_id: this.data.program_id,
          url: this.data.val,
          hhLeft: hoursDiff,
          mmLeft: minutesDiff
        }
      })
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.dialogRef.close(true)
        }
      })

    }
    // else if (this.data.slots.end_time < currentEpochTime) {
    //   this.toster.info(`Session can't be start after ${hoursEnd.toString().padStart(2, '0')}:${minutesEnd.toString().padStart(2, '0')}`)
    // }
  }


}
@Component({
  selector: 'qr-code-popup',
  templateUrl: './qr-code-popUp.html',
})
export class QrCodeDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<QrCodeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {

  }
  ngOnInit(): void {
    this.playerLink = this.data
  }
  playerLink: any
  close() {
    this.dialogRef.close()
  }
}
@Component({
  selector: 'game-delete-popup',
  templateUrl: './delete-confirm-dialog.html',
})
export class OneGameDeleteDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<OneGameDeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  ngOnInit(): void {
    switch (this.data) {
      case 1:
        this.src = "../../assets/img/value_continum.svg"
        break;
      case 2:
        this.src = "../../assets/img/bottleneck.svg"
        break;
      case 3:
        this.src = "../../assets/img/warehouse.svg"
        break;
      case 4:
        this.src = "../../assets/img/picturePerfect.svg"
        break;
      case 5:
        this.src = "../../assets/img/animal_faram.svg"
        break;
      case 6:
        this.src = "../../assets/img/bigPicture_withtout_bg.svg"
        break;
      case 8:
        this.src = "../../assets/img/human_matrix.jpg"
        this.flag = true
        break;
      case 9:
        this.src = "../../assets/img/string_theory.jpg"
        this.flag = true
        break;
      case 10:
        this.src = "../../assets/img/strom.jpg"
        this.flag = true
        break;
      case 11:
        this.src = "../../assets/img/assembley_line.jpg"
        this.flag = true
        break;
      case 12:
        this.src = "../../assets/img/magic_mats.jpg"
        this.flag = true
        break;
      case 13:
        this.src = "../../assets/img/stander.jpg"
        this.flag = true
        break;

    }
  }
  src: string = ''
  flag: boolean = false
  close() {
    this.dialogRef.close(false)
  }
  dlt() {
    this.dialogRef.close(true)
  }
}
@Component({
  selector: 'bp-code-popup',
  templateUrl: './bigPictureCode-dialog.html',
})
export class BigPictureCodeDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BigPictureCodeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  codes: any
  ngOnInit(): void {
    this.codes = this.data
    console.log(this.data)
  }
  bpCode: any = this.data
  pdfData: any = [];
  genPDF() {
    this.pdfData = []
    this.bpCode.forEach((ele: any) => {
      if (this.pdfData.length == 0) {
        this.pdfData.push([ele.code])
      }
      else {
        if (this.pdfData[this.pdfData.length - 1].length < 5) {
          this.pdfData[this.pdfData.length - 1].push(ele.code)
        }
        else {
          this.pdfData.push([ele.code])
        }
      }
    });
    if (this.pdfData[this.pdfData.length - 1].length < 5) {
      const condition = 5 - this.pdfData[this.pdfData.length - 1].length
      for (let i = 0; i < condition; i++) {
        this.pdfData[this.pdfData.length - 1].push('')
      }
    }
    const pdf = new PdfMakeWrapper();
    pdf.header(
      new Txt('Big Picture Game Code').bold().alignment('center').margin(20).end
    );
    pdf.add(new Table(this.pdfData).widths(['*', '*', '*', '*', '*']).end);

    pdf.create().download();
  }
}

@Component({
  selector: 'time-slot-popup',
  templateUrl: './time_slot_dialog.html',
})
export class TimeSlotDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<TimeSlotDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService
  ) { }
  hours: string = '00'
  mins: string = '00'
  ngOnInit(): void {
    this.hours = this.data.hhLeft
    this.mins = this.data.mmLeft
    this.intervalFunction()
    setTimeout(() => {
      this.httpSv.updateProgramStatus(this.data['program_id']).subscribe((res: any) => {
        if (res['status']) {
          window.open(this.data.url)
          this.dialogRef.close(true)
        } else {
          this.toster.error(res['message'], '', { timeOut: 5000 })
          this.dialogRef.close(false)
        }
      }, (err: any) => {
        this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
        this.dialogRef.close(false)
      })
    }, 4980)
  }
  count: any = 5
  intervalFunction() {
    const myInterval = interval(1000)
    const counter: Subscription = myInterval.subscribe((value: any) => {
      this.count = 5 - value
      if (value >= 5) {
        counter.unsubscribe();
      }
    })
  }
}