import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }
  sessionPost(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/session/create', data)
  }
  getVcPairs() {
    return this.http.get(environment.baseUrl + 'api/digital/games/vc/bslpairs/view')
  }
  getVcLibraryPairs() {
    return this.http.get(environment.baseUrl + 'api/digital/games/vc/mypairs/view')
  }
  addPairInMyLib(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/vc/mypairs/create', data)
  }
  configVcGame(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/vc/create', data)
  }
  deleteMyLibPair(data: any) {
    return this.http.delete(environment.baseUrl + 'api/digital/games/vc/mypairs/delete/' + data)
  }
  configWhGame(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/wh/create', data)
  }
  configAfGame(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/af/create', data)
  }
  configBnGame(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/bn/create', data)
  }
  configBpGame(data: any) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(environment.baseUrl + 'api/digital/games/bp/create', data)
  }

  getVcGameDtls(data: any) {
    return this.http.get(environment.baseUrl + `api/digital/games/vc/view?program_id=${data}&game_id=1`)
  }
  getBnGameDtls(data: any) {
    return this.http.get(environment.baseUrl + `api/digital/games/bn/view?program_id=${data}&game_id=2`)
  }
  getWhGameDtls(data: any) {
    return this.http.get(environment.baseUrl + `api/digital/games/wh/view?program_id=${data}&game_id=3`)
  }
  getAfGameDtls(data: any) {
    return this.http.get(environment.baseUrl + `api/digital/games/af/view?program_id=${data}&game_id=5`)
  }
  redirectQuestico(data: any) {
    return this.http.post(environment.baseUrl + 'api/questico/games/session/create', data)
  }
  getBpGameDtls(data: any) {
    return this.http.get(environment.baseUrl + 'api/digital/games/bp/view?program_id=' + data + '&game_id=6')
  }
  getGameAccessList(game_type:number){
    return this.http.get(environment.baseUrl+ 'api/games/list/details?game_type='+game_type)
  }

  userAllowedGameType(){
    return this.http.get(environment.baseUrl+'api/facilitator/allowed/gameType')
  }
  raiseReachoutRequest(data:any){
    return this.http.post(environment.baseUrl+'api/reachout',data)
  }
  getLicenseCount(){
    return this.http.get(environment.baseUrl+'api/facilitator/license/count')
  }








  logOut() {
    return this.http.get(environment.baseUrl + 'api/logout')
  }
  userDetails(data: any) {
    return this.http.get(environment.baseUrl + 'api/user/profile/' + data)
  }
  updateUserDetails(id: any, data: any) {
    return this.http.put(environment.baseUrl + 'api/fac/user/edit/' + id, data)
  }
  changePassword(data: any, id: any) {
    return this.http.put(environment.baseUrl + 'api/user/password/reset', data)
  }
  configPhysicalGame(data: any) {
    return this.http.post(environment.baseUrl + 'api/physical/games/session/create', data)
  }
  deletePysicalOneGame(program_id: any, game_id: any) {
    return this.http.delete(environment.baseUrl + 'api/physical/games/delete/' + program_id + '/' + game_id)
  }
  deleteDigitalOneGame(progaram_id: any, game_id: any) {
    return this.http.delete(environment.baseUrl + 'api/digital/games/session/delete/' + progaram_id + '/' + game_id);
  }



  getProgramStatus(program_id: any) {
    return this.http.get(environment.baseUrl + 'api/program/games/status/' + program_id)
  }

  updateProgramStatus(program_id: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/program/status/update', { program_id })
  }

  getProgramDtls(program_id:any){
    return this.http.get(environment.baseUrl+'api/digital/games/session/details',program_id)
  }



  shortUrl(link: any, programId: any) {
    const data = {
      main_url: link,
      program_id: programId
    }
    return this.http.post(environment.nodeUrl, data)
  }



  getCalenderData() {
    return this.http.get(environment.baseUrl + 'api/bsl/calender/scheduled/sessions')
  }





  //Archive Page
  getAllCompletedGame() {
    return this.http.get(environment.baseUrl + 'api/digital/games/session/archived/list')
  }









  //Slots 
  getAllSlots(player: any, time: any) {
    return this.http.get(environment.baseUrl + 'api/kairos/scheduler/sessions?players=' + player + '&epoch_datetime=' + time)
  }
  bookSlot(data:any){
    return this.http.post(environment.baseUrl+'api/kairos/scheduler/sessions/create',data)
  }
  deleteBookedSlot(data:any){
    return this.http.put(environment.baseUrl+'api/kairos/scheduler/sessions/delete',data)
  }
  deleteGameOnEdit(id:any){
    return this.http.delete(environment.baseUrl+`api/digital/games/session/game/delete/${id}`)
  }




  // Super Admin Apis 
  superAdminLogin(data:any){
    return this.http.post(environment.baseUrl+'api/login', data)
  }
  getAllUserList(){
    return this.http.get(environment.baseUrl+'api/user/list')
  }
  // getAllGameList(){
  //   return this.http.get(environment.baseUrl+'api/admin/gameList')
  // }
  addNewUser(data:any){
    return this.http.post(environment.baseUrl+'api/user/create', data)
  }
  updateUser(id:any, data:any){
    return this.http.put(environment.baseUrl+'api/user/edit/'+ id, data)
  }
  getUserInfoWithSubcription(user_id:any){
    return this.http.get(environment.baseUrl+'api/user/info/game/dtls?user_id='+user_id)
  }
  getAllGameList(){
    return this.http.get(environment.baseUrl+'api/all/game/list')
  }
  assignPhysicalGames(data:any){
    return this.http.post(environment.baseUrl+'api/subscriptions/create',data)
  }
}
