import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../Shared/Services/Dashboard.service';
import { Title } from "@angular/platform-browser";
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from '../services/data-sharing-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  Games: any = [];
  todayCount = 0
  is_questico_access: any = 0;
  // Games:any=[];
  digitalGames = ['Animal Farm', 'Ware House', 'Value Continuum'];
  physicalGames = ['Human Matrix', 'Stranded', 'Home Shelter'];
  questico = ['Game 1', 'Game 2', 'Game 3'];
  newSession = false;
  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private toster: ToastrService,
    private titleService: Title,
    private dataShare: DataSharingServiceService
  ) {
    this.titleService.setTitle('Kairos | Dashboard')
  }
  userName: any = ''
  userDlts: any[] = []
  digitalGameCount:number = 0
  physicalGameCount:number = 0
  ngOnInit(): void {
    localStorage.removeItem('uuid')
    this.dataShare.selectedGameForConfig = []
    this.dataShare.sessionData = {}
    this.dataShare.currentSelect = null
    this.dataShare.totalConfigDone = []
    this.dataShare.sessionTempEditData = {}
    this.dataShare.newSetup = true
    this.dataShare.lastGameConfigId = null
    this.dataShare.exploreGameSelection = null
    this.dataShare.lastUrlForGameResource = null
    this.dataShare.myGames = null
    this.dataShare.dashBoradRedir = null
    this.dashboardService.selectedSession = null;
    this.dashboardService.selectedArchiveSession = null;
    this.dashboardService.editStepFalg = null;
    this.dashboardService.archiveFlag = false;
    this.dashboardService.editStep3 = null

    if (localStorage.getItem('expireToday') == 'true') {
      this.flag = true
    }
    if (localStorage.getItem('userName')) {
      this.userName = localStorage.getItem('userName')
    }
    this.dashboardService.getDashboardDtls().subscribe((res: any) => {
      if (res.status) {
        res.results.session.forEach((val: any, index: any) => {
          val.type = 'Digital';
          let programDate = new Date(val.formatted_date).getDate();
          let today = new Date().getDate();
          if (programDate == today && new Date(val.formatted_date).getMonth() == new Date().getMonth()) {
            val.schedulesStatus = true;
            this.todayCount += 1;
          }
          val.program_date = val.formatted_date;
        })
        this.Games = res.results.session;
        this.userDlts = res.results.subcriptionDtls
        this.digitalGameCount = this.userDlts.filter((ele:any)=>ele.game_type == 1).length
        this.physicalGameCount = this.userDlts.filter((ele:any)=>ele.game_type == 2).length
      }
      else {
        this.Games = [];
      }
    })
  }
  flag: boolean = false
  questicoNavigate() {
    if (this.is_questico_access == 0) {
      this.toster.error("You don't have the Access, Please contact the Support", '', { timeOut: 2000 })
    }
    else {
      this.router.navigate(['/configure/questico']);
    }
  }
  getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  }

  openNewSessionSetup() {
    // if(this.digitalGameCount != 0){
    //   // this.toster.warning('','',{timeOut:2000})
    //   return
    // }
    if(+this.LicienceLeft < 2){
      this.toster.warning('Minimum 2 licience is required to setup gmaes','',{timeOut:2000})
      return
    }
    this.router.navigate(['/configure'])
  }
  closeNewSession(event: any) {

    if (event.target.className == 'newSessionEntire')
      this.newSession = false;
  }
  closePopup() {
    this.newSession = false;
  }
  scheduledPage() {
    this.router.navigate(['/scheduled']);
  }
  openSessionDashboard(game: any) {
    this.dashboardService.selectedSession = game;
    // if (game.program_games[0].game_id == 6) {
    //   this.router.navigate(['/bigPicture'])
    // }
    // else {
    this.router.navigate(["/sessionDashboard"]);
    this.dataShare.dashBoradRedir = true
    // }
  }
  goForShedule() {
    window.open('https://calendly.com/playkairos/connect')
  }
  goToResoure(id: any) {
    this.dataShare.lastUrlForGameResource = '/dashboard'
    this.router.navigate(['/game/resources'], { queryParams: { id: id } })
  }
  goToExplorePage() {
    this.router.navigate(['/exploreGames'])
  }
  goToGameResource(gameName: any) {
    if (gameName == 'digital') {
      this.dataShare.exploreGameSelection = 'digital'
      this.router.navigate(['/exploreGames'])
      this.dataShare.myGames = 'dashboard'
    }
    else if (gameName == 'physical') {
      this.dataShare.exploreGameSelection = 'physical'
      this.router.navigate(['/exploreGames'])
      this.dataShare.myGames = 'dashboard'
    }
    else if (gameName == 'questico') {
      this.router.navigate(['/game/questico'])
      this.dataShare.myGames = 'questico'
      this.dataShare.lastUrlForGameResource = '/dashboard'
    }
  }
  digitalMyGames(){
    this.dataShare.myGames = 'digital'
    this.router.navigate(['/myGames'])
  }
  physicalMyGames(){
    this.dataShare.myGames = 'physical'
    this.router.navigate(['/myGames'])
  }
  goToExplore() {
    this.dataShare.exploreGameSelection = ''
    this.router.navigate(['/exploreGames'])
  }

  get LicienceLeft(){
    return this.userDlts.filter((ele:any)=>ele.game_type == 1)[0].no_of_licenses_left
  }
  get DigitalValidTill(){
    let date = new Date(this.userDlts.filter((ele:any)=>ele.game_type == 1)[0].end_date * 1000)
    let dateTemp = date.toLocaleString("en-GB", { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-')
    return dateTemp.split('-').reverse().join('-')
  }

}
