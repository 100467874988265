<div style="height: 100vh;">
    <app-header style="position:fixed;width: 100%;" [oneHeader]="headerTxt"></app-header>
    <div class="entireContainer" *ngIf="!selectedExplore">
        <div class="gameConatainer">
            <div class="gamebox">
                <b>Digital Games</b>
                <div class="box">
                    <div class="digitalHeader">
                        <img src="../../../assets/img/digitalHeader.svg">
                    </div>
                    <div class="boxBody">
                        Transforming the virtual and hybrid learning programs into dynamic and interactive experiences.
                        Our behavioral games go beyond the typical ‘gamification’ of content, promoting better recall &
                        retention. With multiplayer real-time games, each player’s actions impact others and the game’s
                        outcome, guaranteeing 100% engagement. These desktop and mobile-ready games easily integrate
                        into your existing learning sessions.
                    </div>
                    <div class="boxGames">
                        <b>Value Continuum</b>
                        <b>Ware House</b>
                        <b>Bottleneck Challenge</b>
                        <img src="../../../assets/viewAll.svg" (click)="selectedExploreGame('digital')">
                    </div>
                </div>
            </div>
            <div class="gamebox">
                <b>Physical Games</b>
                <div class="box">
                    <div class="physicalHeader">
                        <img src="../../../assets/img/physicalHeader.svg">
                    </div>
                    <div class="boxBody">
                        In-classroom learning programs allow for interactions, engagement and social learning. Kairos’
                        physical games are built for these in-person learning experiences and where the magic of deeper
                        interactions and real time group dynamics enable A-ha moments and immersive learning.
                    </div>
                    <div class="boxGames">
                        <b>Human Matrix</b>
                        <b>Stranded</b>
                        <b>Strom Shelter</b>
                        <img src="../../../assets/viewAll.svg" (click)="selectedExploreGame('physical')">
                    </div>
                </div>
            </div>
            <!-- <div class="gamebox">
            <b>Questico</b>
            <div class="box">
                <div class="questicoHeader">
                    <img src="../../../assets/img/questicoHeader.svg">
                </div>
                <div class="boxBody">
                    Questico is a fully customizable race themed platform for gamified learning. It allows to integrate multiple challenges built on any of your existing authoring tools or content platforms, and works as both single-player game for self paced learning or team based games. Ideal for passive, content heavy learning such as mandatory trainings, induction & onboarding, values, vision, strategy dissemination or just pure fun.
                </div>
                <div class="boxGames">
                    <b>Great Ocean Race</b>
                    <b>Keeper of Kingdoms</b>
                    <b>Customised</b>
                    <img src="../../../assets/img/knowmore.svg" (click)="goToQuestico()">
                </div>
            </div>
        </div> -->
        </div>
    </div>
    <div class="detailedGames" *ngIf="selectedExplore=='digital'"
        [ngClass]="{digitalBackground:selectedExplore=='digital'}">

        <div class="HeaderTop">
            <div class="backBtn" (click)="back()"> <img style="padding-left: 12px;
            width: 80%;
            height: auto;" src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>

            <div class="header_sub_div">
                <p>
                    Explore all Games
                    <b *ngIf="selectedExplore=='digital'">| Digital Games</b>
                    <b *ngIf="selectedExplore=='physical'">| Physical Games</b>
                </p>
                <div class="search_box_div">
                    <img src="../../../assets/img/search-svgrepo-com.svg" alt="">
                    <input type="text" name="search" id="" (input)="filterDigitalContent($event.target)">
                </div>
            </div>
        </div>
        <div class="exploreBody" style="margin: 0 3rem;">
            <div class="gameDesc" *ngFor="let game of digitalGamesCOntent">
                <div class="gameLeft">
                    <div class="gameName">
                        <img [src]="game.img" [ngClass]="game.id == 1 || game.id == 6 ? 'img-width-11':''">
                    </div>
                    <div class="gameDesciption">
                        {{game.description}}
                    </div>
                </div>
                <div class="gameMiddle">
                    <div class="learningConnect" [ngClass]="getBgClass(game.id)">
                        <b style="display: block; margin-left: 15%; text-align: start;">Learning Connect</b>
                        <div style="margin-top: 1rem;"></div>
                        <div class="learningElements" *ngFor="let data of game.learningConnnect">
                            {{data}}
                        </div>
                    </div>
                    <div class="gameSize">
                        <div><img src="../../../assets/img/group.svg" alt="" class="group-icon"> Group Size : <b> {{
                                game.grpSize}}</b></div>
                        <div><img src="../../../assets/img/time.svg" alt="" class="group-icon">Time Duration : <b> {{
                                game.timeGuration}}</b></div>
                        <div><img src="../../../assets/img/platform.svg" alt="" class="group-icon">Platform : <b>{{
                                game.platform}}</b></div>
                    </div>
                </div>
                <div class="gameRight">
                    <div class="slideshow-container" *ngIf="game.id==1">
                        <div (click)="minusSlides(game.id)"><img class="leftArrow"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img [src]="vcImages[vcSlider]" class="main-image" style="max-width: 23rem;">
                        <div (click)="plusSlides(game.id)"><img class="rightArrow" src="../../../assets/img/arrow.jpg">
                        </div>
                    </div>
                    <div class="slideshow-container" *ngIf="game.id==2">
                        <div (click)="minusSlides(game.id)"><img class="leftArrow"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img [src]="bnImages[bnSlider]" class="main-image" style="max-width: 23rem;">
                        <div (click)="plusSlides(game.id)"><img class="rightArrow" src="../../../assets/img/arrow.jpg">
                        </div>
                    </div>
                    <div class="slideshow-container" *ngIf="game.id==3">
                        <div (click)="minusSlides(game.id)"><img class="leftArrow"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img [src]="whImages[whSlider]" class="main-image" style="max-width: 23rem;">
                        <div (click)="plusSlides(game.id)"><img class="rightArrow" src="../../../assets/img/arrow.jpg">
                        </div>
                    </div>
                    <div class="slideshow-container" *ngIf="game.id==4">
                        <div (click)="minusSlides(game.id)"><img class="leftArrow"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img [src]="ppImages[ppSlider]" class="main-image" style="max-width: 23rem;">
                        <div (click)="plusSlides(game.id)"><img class="rightArrow" src="../../../assets/img/arrow.jpg">
                        </div>
                    </div>
                    <div class="slideshow-container" *ngIf="game.id==5">
                        <div (click)="minusSlides(game.id)"><img class="leftArrow"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img [src]="afImages[afSlider]" class="main-image" style="max-width: 23rem;">
                        <div (click)="plusSlides(game.id)"><img class="rightArrow" src="../../../assets/img/arrow.jpg">
                        </div>
                    </div>
                    <div class="slideshow-container" *ngIf="game.id==6">
                        <div (click)="minusSlides(game.id)"><img class="leftArrow"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img [src]="bpImages[bpSlider]" class="main-image" style="max-width: 23rem;">
                        <div (click)="plusSlides(game.id)"><img class="rightArrow" src="../../../assets/img/arrow.jpg">
                        </div>
                    </div>
                    <!-- <div class="scheduleDemo">
                    Schedule a Demo
                </div> -->
                    <div class="gameResource" (click)="goToGameResource(game.id)"
                        *ngIf="gameAccessIds.includes(game.id) && game.id != 4">
                        Game Resource
                    </div>
                    <div class="gameResource reachout_div" (click)="openReachOut()"
                        *ngIf="!gameAccessIds.includes(game.id) && game.id != 4">
                        <span>Reachout</span>
                        <img src="../../../assets/reach_out_icon.svg" alt="">
                    </div>
                    <div class="gameResource" style="cursor: not-allowed;" *ngIf="game.id == 4">
                        Game In Progress
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="detailedGames" *ngIf="selectedExplore=='physical'"
        [ngClass]="{physicalBackground:selectedExplore=='physical'}">

        <div class="HeaderTop">
            <div class="backBtn" (click)="back()"> <img style="padding-left: 12px;
            width: 80%;
            height: auto;" src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>

            <div class="header_sub_div">
                <p>
                    Explore all Games
                    <b *ngIf="selectedExplore=='digital'">| Physical Games</b>
                    <b *ngIf="selectedExplore=='physical'">| Physical Games</b>
                </p>
                <div class="search_box_div">
                    <img src="../../../assets/img/search-svgrepo-com.svg" alt="">
                    <input type="text" name="search" id="" (input)="filterPhysicalContent($event.target)">
                </div>
            </div>
        </div>
        <div class="exploreBody" style="width: 90%;">
            <div class="gameDesc" *ngFor="let game of physicalGameContent">
                <div class="gameLeft">
                    <div class="gameName">
                        <img [src]="game.img">
                    </div>
                    <div class="gameDesciption">
                        {{game.description}}
                    </div>
                </div>
                <div class="gameMiddle">
                    <div class="learningConnect" [ngClass]="getBgClass(game.id)">
                        <b style="display: block; margin-left: 15%; text-align: start;">Learning Connect</b>
                        <div style="margin-top: 1rem;"></div>
                        <div class="learningElements" *ngFor="let data of game.learningConnnect">
                            {{data}}
                        </div>
                    </div>
                    <div class="gameSize">
                        <div><img src="../../../assets/img/group.svg" alt="" class="group-icon"> Group Size : <b> {{
                                game.grpSize}}</b></div>
                        <div><img src="../../../assets/img/time.svg" alt="" class="group-icon">Time Duration : <b>{{
                                game.timeGuration}}</b></div>
                        <div><img src="../../../assets/img/platform.svg" alt="" class="group-icon">Space Required :
                            <b>{{ game.spaceReq}}</b>
                        </div>
                    </div>
                </div>
                <div class="gameRight">
                    <div class="slideshow-container" *ngIf="game.id=='8'">
                        <div (click)="minusSlides(game.id)"><img class="leftArrow"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img [src]="mImages[mSlider]" class="main-image">
                        <div (click)="plusSlides(game.id)"><img class="rightArrow" src="../../../assets/img/arrow.jpg">
                        </div>
                    </div>
                    <div class="slideshow-container" *ngIf="game.id=='13'">
                        <div (click)="minusSlides(game.id)"><img class="leftArrow"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img [src]="strImages[strSlider]" class="main-image">
                        <div (click)="plusSlides(game.id)"><img class="rightArrow" src="../../../assets/img/arrow.jpg">
                        </div>
                    </div>
                    <div class="slideshow-container" *ngIf="game.id=='10'">
                        <div (click)="minusSlides(game.id)"><img class="leftArrow"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img [src]="ssImages[ssSlider]" class="main-image">
                        <div (click)="plusSlides(game.id)"><img class="rightArrow" src="../../../assets/img/arrow.jpg">
                        </div>
                    </div>
                    <div class="slideshow-container" *ngIf="game.id=='11'">
                        <div (click)="minusSlides(game.id)"><img class="leftArrow"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img [src]="alImages[alSlider]" class="main-image">
                        <div (click)="plusSlides(game.id)"><img class="rightArrow" src="../../../assets/img/arrow.jpg">
                        </div>
                    </div>
                    <div class="slideshow-container" *ngIf="game.id=='9'">
                        <div (click)="minusSlides(game.id)"><img class="leftArrow"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img [src]="stImages[stSlider]" class="main-image">
                        <div (click)="plusSlides(game.id)"><img class="rightArrow" src="../../../assets/img/arrow.jpg">
                        </div>
                    </div>
                    <div class="slideshow-container" *ngIf="game.id=='12'">
                        <div (click)="minusSlides(game.id)"><img class="leftArrow"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img [src]="mmImages[mmSlider]" class="main-image">
                        <div (click)="plusSlides(game.id)"><img class="rightArrow" src="../../../assets/img/arrow.jpg">
                        </div>
                    </div>
                    <!-- <div class="scheduleDemo">
                    Schedule a Demo
                </div> -->
                    <div class="gameResource" (click)="goToGameResource(game.id)"
                        *ngIf="gameAccessIds.includes(game.id)">
                        Game Resource
                    </div>
                    <div class="gameResource reachout_div" (click)="openReachOut()"
                        *ngIf="!gameAccessIds.includes(game.id)">
                        <span>Reachout</span>
                        <img src="../../../assets/reach_out_icon.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>