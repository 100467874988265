<app-header [oneHeader]=header [adminFlag]="true"></app-header>
<div style="height: 90vh; background-color:#F8F8F8">
    <div style="display: flex; gap: 7vw; justify-content: center;align-items: center;height: 100%;">
        <div style="padding: 20px;">
            <p><b style="font-size: 24px;">Users</b></p>
            <div class="verticalItemsBox">
                <span class="manageUserButton" style="cursor:pointer;" (click)="changeCompRoute('user-list')"><p style="margin: 0; padding: 6px 4px">Manage Users</p></span>
                <!-- <span style="cursor:pointer;" (click)="changeCompRoute('add-user')">Add New User</span> -->
                <button style="border: none; background: none; padding: 0; height: 50px; width:40%;" (click)="changeCompRoute('addUser')">
                    <img style="width: 100%;" src="../../../../assets/addNewUser.svg" alt="add new user">
                </button>
            </div>
        </div>
        <div style="padding: 20px;">
            <p><b style="font-size: 24px;">Games</b></p>
            <div class="verticalItemsBox">
                <img style="height: 65px; width:50%; cursor:pointer;" (click)="changeCompRoute('digital')" src="../../../../assets/digital_game_btn.svg"/>
                <img style="height: 65px; width:50%; cursor:pointer;" (click)="changeCompRoute('physical')" src="../../../../assets/physical_game_btn.svg"/>
                <!-- <span (click)="compRoute = 'game'" >Digital Games</span> -->
                <!-- <span>Physical Games</span> -->
            </div>
        </div>
    </div>
</div>
<app-game-management *ngIf="compRoute == 'game'" (backEmitter)="changeCompRoute($event)"></app-game-management>