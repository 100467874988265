<div>
    <!-- <app-header [oneHeader]='headerMsg'></app-header> -->
    <div class="main_wrapper">
        <div class="left_main_wrapper">
            <div><img src="../../../assets/kairos_logo-transparent_bg.svg" alt="" style="max-width: 65%;"></div>
            <div style="margin-left: 12px;">
                <div style="color: #0B477B;font-size: 18px;font-weight: 500;margin-top: 1rem;">Session Plan</div>
                <div class="session_plan_main_div">
                    <li>Session</li><span>: {{sessionName}}</span>
                </div>
                <div class="session_plan_main_div">
                    <li>Client</li><span>: {{clientName}}</span>
                </div>
                <div class="session_plan_main_div">
                    <li>Date</li><span>: {{sessionDate}}</span>
                </div>
                <div class="session_plan_main_div">
                    <li>Time Slot</li><span>: {{timeSlotData}}</span>
                </div>
                <div class="session_plan_main_div">
                    <li>Players</li><span>: {{maxPlyr}}</span>
                </div>
                <div class="session_plan_main_div">
                    <li>Game Planned</li><span>: {{gamesConfig.length}}</span>
                </div>
            </div>
            <div style="margin-left: 17px;margin-top: 1rem;">
                <div style="color: #0B477B;font-size: 18px;font-weight: 500;margin-top: 1rem;">Session time</div>
                <div style="display: flex;align-items: center;gap: 20px;">
                    <div>Start</div>
                    <div style="padding: 2px 10px;background: #FFFFFF 0% 0% no-repeat padding-box;border-radius: 8px;">
                        {{programStartTime}}</div>
                    <div>End</div>
                    <div style="padding: 2px 10px;background: #FFFFFF 0% 0% no-repeat padding-box;border-radius: 8px;">
                        {{programEndTime}}</div>
                </div>
            </div>

            <div style="flex: 1;">&nbsp;</div>
            <div style="padding: 10px;background: #dfe9ff;">
                <div *ngFor="let item of gamesConfig" style="padding: 10px;">
                    <div class="game_config_list_sub_div" [ngClass]="item.game_id == selectedGame? 'selectedGameCss':''" (click)="switchGame(item.game_id )">
                        <img src="../../../assets/img/value_continum.svg" alt="" *ngIf="item.game_id == 1">
                        <img src="../../../assets/img/bottleneck.svg" alt="" *ngIf="item.game_id == 2">
                        <img src="../../../assets/img/warehouse.svg" alt="" *ngIf="item.game_id == 3">
                        <img src="../../../assets/img/picturePerfect.svg" alt="" *ngIf="item.game_id == 4">
                        <img src="../../../assets/img/animal_faram.svg" alt="" *ngIf="item.game_id == 5">
                        <img src="../../../assets/img/bottleneck_logo.svg" alt="" *ngIf="item.game_id == 6">
                        <span style="margin-left: 10px;">{{item.game_name}}</span>
                    </div>
                </div>
            </div>

        </div>
        <div style="flex: 1;" *ngIf="selectedGame == 1">
            <div *ngIf="vcPlayedFlag">
                <div
                    style="display: flex;justify-content: space-between;align-items: center;padding-right: 10px; height: 15vh;">
                    <div><img src="../../../assets/img/valuecontinuum.svg" alt=""></div>
                    <div>
                        <div>
                            <div style="display: flex;gap: 20px;align-items: center;">
                                <div style="color: #0B477B;font-size: 18px;font-weight: 500;">Game time</div>
                                <div>Start</div>
                                <div
                                    style="background: #A6DBD9 0% 0% no-repeat padding-box;border-radius: 8px;padding: 4px 8px;">
                                    {{formateEpoch(vcArchiveData.vc_info.start_time)}}</div>
                                <div>End</div>
                                <div
                                    style="background: #A6DBD9 0% 0% no-repeat padding-box;border-radius: 8px;padding: 4px 8px;">
                                    {{formateEpoch(vcArchiveData.vc_info.expiry_time)}}</div>
                            </div>
                        </div>
                        <div style="display: flex;gap: 20px;">
                            <div style="color: #0B477B;font-size: 18px;font-weight: 500;">Active Players</div>
                            <div>{{vcArchiveData.vc_info.no_of_players_played}}</div>
                        </div>
                    </div>
                </div>
                <div
                    style="background-color: #F2F6FA; height: 85vh;display: flex;flex-direction: column;align-items: center;justify-content: center;">
                    <div style="width: 60vw;border: 1px solid #A3A3A3;border-radius: 10px">
                        <div style=" display: flex; background: #CBE4FE;align-items: stretch;border-radius: 10px 10px 0
                        0;font-weight: 500;">
                            <div style="flex: 1;display: flex;justify-content: center;align-items: center;">
                                <div>Sl No.</div>
                            </div>
                            <div
                                style="flex: 3;border-right: 1px solid #A3A3A3;display: flex;justify-content: center;align-items: center;">
                                <div>Statement Pairs</div>
                            </div>
                            <div
                                style="flex: 1.5; text-align: center;border-right: 1px solid #A3A3A3;display: flex;flex-direction: column;align-items: stretch;justify-content: space-between;">
                                <div style="padding-top: 6px;padding-bottom: 6px;">Left Pair</div>
                                <div style="display: flex;">
                                    <div
                                        style="flex: 1;border-right: 1px solid #A3A3A3;background-color: #e6f2ff;padding: 6px;">
                                        Avg
                                    </div>
                                    <div style="flex: 1;background-color: #e6f2ff;padding: 6px;">No. Count</div>
                                </div>
                            </div>
                            <div
                                style="flex: 1.5;text-align: center;display: flex;flex-direction: column;align-items: stretch;justify-content: space-between;">
                                <div style="padding-top: 6px;padding-bottom: 6px;">Right Pair</div>
                                <div style="display: flex;">
                                    <div
                                        style="flex: 1;border-right: 1px solid #A3A3A3;background-color: #e6f2ff;padding: 6px;">
                                        Avg
                                    </div>
                                    <div style="flex: 1;background-color: #e6f2ff;padding: 6px;">No. Count</div>
                                </div>
                            </div>
                        </div>

                        <div style="display: flex;align-items: stretch;"
                            *ngFor="let item of vcArchiveData.vc_pair;let i = index" class="wh_ele_div">
                            <div style="flex: 1;display: flex;justify-content: center;align-items: center;">
                                <div>{{i+1}}</div>
                            </div>
                            <div
                                style="flex: 3;border-right: 1px solid #A3A3A3;display: flex;justify-content: center;align-items: center;">
                                <div style="display: grid;grid-template-columns: 5fr 1fr 5fr; align-items: center;justify-content: center;">
                                    <div style="text-align: right;">{{item.pair_left}}</div>
                                    <img src="../../../assets/img/U-dia.svg" alt="" style="margin: 0 8px; width: 25px;">
                                    <div>{{item.pair_right}}</div>
                                </div>
                            </div>
                            <div
                                style="flex: 1.5; text-align: center;border-right: 1px solid #A3A3A3;display: flex;flex-direction: column;align-items: stretch;justify-content: space-between;">
                                <!-- <div style="padding-top: 4px;">Left Pair</div> -->
                                <div style="display: flex;height: 100%;">
                                    <div style="flex: 1;border-right: 1px solid #A3A3A3;padding: 6px;">
                                        {{item.pair_left_info.avg}}
                                    </div>
                                    <div style="flex: 1;padding: 6px;">{{item.pair_left_info.total_count}}</div>
                                </div>
                            </div>
                            <div
                                style="flex: 1.5;text-align: center;display: flex;flex-direction: column;align-items: stretch;justify-content: space-between;">
                                <!-- <div style="padding-top: 4px;">Right Pair</div> -->
                                <div style="display: flex;height: 100%;">
                                    <div style="flex: 1;border-right: 1px solid #A3A3A3;padding: 6px;">
                                        {{item.pair_right_info.avg}}
                                    </div>
                                    <div style="flex: 1;padding: 6px;">{{item.pair_right_info.total_count}}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div *ngIf="!vcPlayedFlag" class="data_not_found_div">
                <div style="text-align: center;
            font-size: 25px;
            font-weight: 500;">Data Not found</div>
            </div>
        </div>
        <div style="flex: 1;" *ngIf="selectedGame == 3">
            <div *ngIf="whPlayedFlag">
                <div
                    style="display: flex;justify-content: space-between;align-items: center;padding-right: 10px; height: 15vh;">
                    <div><img src="../../../assets/img/explore_wh.svg" alt="" style="width: 160px;
                        margin-left: 13px;"></div>
                    <div style="display: flex;gap: 25px;">
                        <div>
                            <div>
                                <div style="display: flex;gap: 20px;align-items: center;">
                                    <div style="color: #0B477B;font-size: 18px;font-weight: 500;width: 120px;">Game time
                                    </div>
                                    <div>: Start</div>
                                    <div
                                        style="background: #A6DBD9 0% 0% no-repeat padding-box;border-radius: 8px;padding: 4px 8px;">
                                        {{formateEpoch(whArchiveData.wh_info.start_time)}}</div>
                                    <div>End</div>
                                    <div
                                        style="background: #A6DBD9 0% 0% no-repeat padding-box;border-radius: 8px;padding: 4px 8px;">
                                        {{formateEpoch(whArchiveData.wh_info.expiry_time)}}</div>
                                </div>
                            </div>
                            <div style="display: flex;gap: 20px;">
                                <div style="color: #0B477B;font-size: 18px;font-weight: 500;width: 120px;">Active
                                    Players</div>
                                <div>: {{whArchiveData.wh_info.no_of_players_played}}</div>
                            </div>
                            <div style="display: flex;gap: 20px;">
                                <div style="color: #0B477B;font-size: 18px;font-weight: 500;width: 120px;">No. of Teams
                                </div>
                                <div>: {{whArchiveData.leaderboard.length}}</div>
                            </div>
                        </div>
                        <div>
                            <div style="display: flex;gap: 20px;">
                                <div style="color: #0B477B;font-size: 18px;font-weight: 500;width: 165px;">Warehouse
                                    Theme</div>
                                <div>: {{whArchiveData.wh_info.theme_type}}</div>
                            </div>
                            <div style="display: flex;gap: 20px;">
                                <div style="color: #0B477B;font-size: 18px;font-weight: 500;width: 165px;">No. of Rounds
                                </div>
                                <div>: {{whArchiveData.wh_rounds.length}}</div>
                            </div>
                            <div style="display: flex;gap: 20px;">
                                <div style="color: #0B477B;font-size: 18px;font-weight: 500;width: 165px;">Game Duration
                                </div>
                                <div>: {{getGameDuration(whArchiveData.wh_info.expiry_time - whArchiveData.wh_info.start_time)}}
                                    mins</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style="background-color: #F2F6FA; height: 85vh;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div style="width: 60vw;border: 1px solid #A3A3A3;border-radius: 10px">
                        <div
                            style="display: flex; background: #CBE4FE;align-items: stretch;    border-radius: 10px 10px 0 0;font-weight: 500;">
                            <div
                                style="flex: 1;padding: 18px;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div>Sl No.</div>
                            </div>
                            <div
                                style="flex: 3;border-right: 1px solid #A3A3A3;    padding: 18px;display: flex;align-items: center; justify-content: center;">
                                <div>Team Name</div>

                            </div>
                            <div
                                style="flex: 2;padding: 18px; text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                No. of Players
                            </div>
                            <div
                                style="flex: 1.5;padding: 18px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div>Best Time</div>
                            </div>
                            <div
                                style="flex: 3;padding: 18px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div>No. of Disqualifications</div>
                            </div>
                            <div
                                style="flex: 1.5;padding: 18px;text-align: center;display: flex;align-items: center; justify-content: center;">
                                <div>Game time</div>
                            </div>
                        </div>

                        <div style="display: flex;align-items: stretch;"
                            *ngFor="let item of whArchiveData.leaderboard;let i = index" class="wh_ele_div">
                            <div
                                style="flex: 1;padding: 8px 18px;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div>{{i+1}}</div>
                            </div>
                            <div
                                style="flex: 3;border-right: 1px solid #A3A3A3;    padding: 18px;display: flex;align-items: center; justify-content: center;">
                                <div>{{item.team}}</div>

                            </div>
                            <div
                                style="flex: 2;padding: 8px 18px; text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                {{item.no_of_players}}
                            </div>
                            <div
                                style="flex: 1.5;padding: 8px 18px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div>{{getSecToMin(item.best_time)}}</div>
                            </div>
                            <div
                                style="flex: 3;padding: 8px 18px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div>{{item.disqualification}}</div>
                            </div>
                            <div
                                style="flex: 1.5;padding: 8px 18px;text-align: center;display: flex;align-items: center; justify-content: center;">
                                <div>{{getSecToMin(item.game_play_time)}}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div *ngIf="!whPlayedFlag">
                <div style="text-align: center;
            font-size: 25px;
            font-weight: 500;">Data Not found</div>
            </div>
        </div>
    </div>
</div>