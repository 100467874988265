import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router, Event as NavigationEvent } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter, flatMap } from 'rxjs';
import { DashboardService } from 'src/app/Shared/Services/Dashboard.service';

@Component({
  selector: 'app-scheduled',
  templateUrl: './scheduled.component.html',
  styleUrls: ['./scheduled.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('525ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ScheduledComponent implements OnInit {

  selectedSession = 'scheduled'
  isDelete = false;
  columnsToDisplay = ["back", 'date', 'program', 'game', 'actions'];
  expandedElement: any = {
    id: -1
  };
  dataSource: any;
  openSessionData: any = {
    id: -2
  };
  allGameList: any
  constructor(private dashboardService: DashboardService, private router: Router, private toster: ToastrService, private activatedRoute: ActivatedRoute) {
  }
  ngOnInit(): void {
    this.tableBuilder();
    if(this.dashboardService.archiveFlag){
      this.dashboardService.archiveFlag = false
      this.selectedSession = 'archive'
    }
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    
  }
  private getLastRoute(url: string): string {
    const segments = url.split('/');
    // Filter out empty segments and return the last one
    console.log(segments.filter(segment => segment !== '').pop() || '')
    return segments.filter(segment => segment !== '').pop() || '';
  }

  tableBuilder() {
    this.dashboardService.getScheduledData().subscribe((res: any) => {
      if (res.result) {
        res.result.forEach((element: any) => {
          element.status = this.getOneProgramStatus(element);
          // let newdate = element.program_date.split("-").reverse();  
          // let dd = newdate[0];
          // let mm = this.getMonthName(newdate[1]);
          // let yy = newdate[2];
          // var finalDateFormated = `${dd} ${mm} ${yy}`;
          element.program_date = element.formatted_date;
          var status;
          element.program_games.forEach((game: any) => {
            if (game.status == 'Draft')
              game.status = 'Draft'
            if (game.status == 'Completed')
              game.status = 'Completed'
            if (game.status == 'Running')
              game.status = 'Live'
          })
        });
        this.dataSource = new MatTableDataSource(res.result);
        this.allGameList = res.result
        console.log(this.allGameList)
      }
      else {
        this.dataSource.data = [];
        this.allGameList = []
        // this.ngOnInit()
      }
    })
  }
  setid() {
    this.openSessionData = {
      id: -1
    }
  }
  getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  }
  session(element: any) {
    this.openSessionData = element;
    this.isDelete = false;
  }
  changeSession(data: any) {
    this.selectedSession = data;
  }
  deleteSession(val: boolean, event: Event, id = null) {
    event.stopPropagation()
    this.isDelete = val;
    if (val) {
      if (id != null) {
        this.dashboardService.deleteSession(id).subscribe((res: any) => {
          if (res.status) {
            this.tableBuilder();
            this.isDelete = false
          }
        })
      }
    }
  }
  openSession(data: any) {
    this.dashboardService.selectedSession = data;
    // if(data.program_games[0].game_name!='Big Picture')
    this.router.navigate(['/sessionDashboard'])
    // else{
    //   this.router.navigate(['/bigPicture'])
    // }
  }
  sessionDeleteHandler(event: Event, data: any) {
    event.stopPropagation();
    if (data.status == 'Live') {
      this.toster.info("Live session can't be deleted", '', { timeOut: 2000 })
    }
    else {
      this.isDelete = true
    }
    this.expandedElement = null

  }
  goToDashBoard() {
    this.router.navigate(['/dashboard'])
  }
  getOneProgramStatus(data: any) {
    if (data.status != null) {
      if (data.status == 'Running') {
        return 'Live'
      }
      else {
        return data.status
      }
    } else {
      if (data.program_games.every((ele: any) => ele.status == "Completed")) {
        return "Completed"
      }
      else if (data.program_games.every((ele: any) => ele.status == "Configured")) {
        return "Configured"
      }
      else {
        return "Live"
      }
    }
  }
  handleToggleClick(id: any, type: any) {
    this.isDelete = false
    if (type == 'dropDown') {
      if (document.getElementsByClassName('acrive-drop').length > 0) {
        let tempId: string = document.getElementsByClassName('acrive-drop')[0].id.slice(8)
        document.getElementById('oneLine' + tempId)?.classList.add('display-block')
        if (tempId != '0') {
          document.getElementById('borderLine' + (parseInt(tempId) - 1))?.classList.add('display_block_border')
        }
        document.getElementById('oneLine' + tempId)?.classList.remove('display-none')
        document.getElementsByClassName('acrive-drop')[0].classList.add('display-none')
        document.getElementsByClassName('acrive-drop')[0].classList.remove('display-block')
        document.getElementsByClassName('acrive-drop')[0].classList.remove('acrive-drop')
      }
      document.getElementById(type + id)?.classList.remove('display-none')
      document.getElementById(type + id)?.classList.add('display-block')
      document.getElementById(type + id)?.classList.add('acrive-drop')
      document.getElementById('oneLine' + id)?.classList.remove('display-block')
      document.getElementById('oneLine' + id)?.classList.add('display-none')
      if (id != 0) {
        if (document.getElementsByClassName('display_block_border').length > 0)
          document.getElementById('borderLine' + (id - 1))?.classList.remove('display_block_border')
        document.getElementById('borderLine' + (id - 1))?.classList.add('display_none_border')
      }
    }
    else if (type == 'oneLine') {
      document.getElementById(type + id)?.classList.remove('display-none')
      document.getElementById(type + id)?.classList.add('display-block')
      document.getElementById('dropDown' + id)?.classList.remove('display-block')
      document.getElementById('dropDown' + id)?.classList.add('display-none')
      if (id != 0) {
        document.getElementById('borderLine' + (id - 1))?.classList.remove('display_none_border')
        document.getElementById('borderLine' + (id - 1))?.classList.add('display_block_border')
      }
    }
  }
  stopParent(event: Event) {
    event.stopPropagation()
  }
  timeSlot(start_time:any , end_time:any){
    if(!start_time) return ''
    const temp = start_time
    const temp1 = end_time * 1000
    // const temp1 = this.programDetails.slots.end_time * 1000
    return `${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(temp * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(temp1))}`
  }
}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}