import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponentComponent } from './login-component/login-component.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ConfigureComponent } from './components/configure/configure.component';
import { NewGamesComponent } from './components/new-games/new-games.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ScheduledComponent } from './components/scheduled/scheduled.component';
import { SessionDashboardComponent } from './components/session-dashboard/session-dashboard.component';
import { ExploreAllGamesComponent } from './components/explore-all-games/explore-all-games.component';
import { ConfigureQuesticoComponent } from './components/configure-questico/configure-questico.component';
import { BigPicDashboardComponent } from './components/big-pic-dashboard/big-pic-dashboard.component';
import { MyGamesComponent } from './components/my-games/my-games.component';
import { AuthGuard } from './auth-gard/auth.guard';
import * as path from 'path';
import { GameResourcesComponent } from './components/game-resources/game-resources.component';
import { QuesticoResourceComponent } from './components/questico-resource/questico-resource.component';
import { AccountSettingComponent } from './components/account-setting/account-setting.component';
import { ConfigurePhysicalComponent } from './components/configure-physical/configure-physical.component';
import { IgluLabsUserComponent } from './components/iglu-labs-user/iglu-labs-user.component';
import { ArchiveDashboardComponent } from './components/archive-dashboard/archive-dashboard.component';
import { PendingChangesGuard } from './guard/pending-changes.guard.guard';
import { AdminDashboardComponent } from './components/superadmin/admin-dashboard/admin-dashboard.component';
import { AdminLoginComponent } from './components/superadmin/admin-login/admin-login.component';
import { UserAddComponent, UserManagementComponent } from './components/superadmin/user-management/user-management.component';
import { GameAssignmentComponent } from './components/superadmin/game-assignment/game-assignment.component';
import { GameDtlsComponent } from './components/superadmin/game-dtls/game-dtls.component';
import { GameContentComponent } from './components/superadmin/game-content/game-content.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component:SessionDashboardComponent, pathMatch: 'full',
  // },
  {
    path: '',
    redirectTo: 'login', pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponentComponent,
  },
  {
    path: 'configure',
    component: ConfigureComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'configure/games',
    component: NewGamesComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: 'scheduled',
    component: ScheduledComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sessionDashboard',
    component: SessionDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sessionArchive',
    component: ArchiveDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'exploreGames',
    component: ExploreAllGamesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'configure/questico',
    component: ConfigureQuesticoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'configure/physical',
    component: ConfigurePhysicalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bigPicture',
    component: BigPicDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'game/resources',
    component: GameResourcesComponent,
  },
  {
    path: 'game/questico',
    component: QuesticoResourceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'myGames',
    component: MyGamesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'accountSetting',
    component: AccountSettingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "config/status",
    component: IgluLabsUserComponent,
  },
  {
    path: "admin",
    component: AdminLoginComponent,
  },
  {
    path: "admin-dashboard",
    component: AdminDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "user-list",
    component: UserManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "add-user",
    component: UserAddComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "edit-user",
    component: UserAddComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "game-content",
    component: GameContentComponent
  },
  {
    path: "assign-game",
    component: GameAssignmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "admin-games",
    component: GameDtlsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: DashboardComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy },]
})
export class AppRoutingModule { }
