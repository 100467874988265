import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-game-dtls',
  templateUrl: './game-dtls.component.html',
  styleUrls: ['./game-dtls.component.css']
})
export class GameDtlsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private httpSv: HttpService,
    private router: Router,
    public toster: ToastrService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((result: any) => {
      if (result.game == 1)
        this.selectedExplore = 'digital'
      else if (result.game == 2)
        this.selectedExplore = 'physical'
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        this.location.back();
      }
    })
  }
  selectedExplore: any = 'digital'
  digitalGamesAllowed: any[] = [
    { name: 'Value Continuum' },
    { name: 'Bottle Neck Challenge' },
    { name: 'Ware House' },
    { name: 'Animal Farm' },
    { name: 'Big Picture' },
    { name: 'Picture Perfect' },
  ]
  physicalGamesAllowed: any[] = [
    { game_id: 8 }, { game_id: 9 }, { game_id: 10 }, { game_id: 11 }, { game_id: 12 }, { game_id: 13 }
  ]
  selectedExploreGame(data: any) {
    // if(data == this.selectedExplore) return
    // else if(data == 'digital') this.getGameAccessList(1)
    // else if(data == 'physical') this.getGameAccessList(2)
    this.selectedExplore = data;
  }
  goToGameResource(id: any) {

  }
}
