<div style="height: 100vh;">
    <!-- <app-header [first]=NewSession [secound]=Digital [third]=Name></app-header> -->
    <div class="header_main_div">
        <div style="    display: flex;
        flex-direction: column;">
            <img src="../../../assets/kairos_logo-transparent_bg.svg" alt="" width="100%" (click)="moveToHomePage()"
                style="cursor: pointer;">
            <img src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" alt=""
                *ngIf="NewSession == 'Edit Session' && step == 1" style="width: 25px;margin-left: 20px;cursor: pointer;"
                (click)="goBack()">
        </div>

        <div class="wh_header_right_sub">
            <div *ngIf="(step == 2 && NewSession != 'Edit Session') || backEditFlag">
                <span>Time left to complete the set-up </span>
                <span>{{('0'+remainingMinutes).slice(-2)}}:{{('0'+remainingSeconds).slice(-2)}}</span>
            </div>
            <div style="background: none;box-shadow: none;">
                <img src="../../../assets/profile.png" alt="" (click)="toggleFunc()">
            </div>
        </div>
        <div class="header-drowdwon" *ngIf="toggleFlag" (appOutsideClick)="outSideClickHandle($event)">
            <div class="header-drowdwon-content" (click)="goToAccountSetting()">
                My Profile
            </div>
            <div class="header-drowdwon-content" (click)="goToMyGame()">
                My Games
            </div>
            <div class="header-drowdwon-content" (click)="goGameReport()">
                Games Reports
            </div>
            <div class="header-drowdwon-content" (click)="logout()">
                Logout
            </div>
        </div>
    </div>
    <div class="main-div-container" id="mainDivContainer">

        <div class="" style="height: 100%;">
            <!-- <div class="step-main-div">
                <div [ngClass]="step==1? 'step-active-css':''">STEP 1</div>
                <div [ngClass]="step==2? 'step-active-css':''">STEP 2</div>
                <div [ngClass]="step==3? 'step-active-css':''">STEP 3</div>
            </div> -->
            <div class="bg-side-div">
                <img src="../../../assets/img/Path 566.svg" alt="" srcset="">
            </div>
            <div class="container-div" *ngIf="step == 1">
                <div class="left-container">
                    <div>
                        <img src="../../../assets/img/Group 3468.svg" alt="">
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;justify-content: center;">
                    <div style="margin: 0 10px;justify-content: center;">
                        <div class="session_config_type">{{NewSession}}</div>
                        <div class="right-container" *ngIf="!schedularTimeSlot">
                            <div>
                                <label for="session-name">Session Name
                                    <img placement="bottom" [ngbTooltip]="newSessionTooltip" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="sessionName">
                            </div>

                            <div>
                                <label for="client-name">Client Name
                                    <img placement="bottom" [ngbTooltip]="clientNameTooltip" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="clientName">
                            </div>
                            <div>
                                <label for="client-name">No. of players
                                    <img placement="bottom" [ngbTooltip]="maxplayers" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                                </label>
                                <input type="number" class="form-control" [(ngModel)]="noOfPlayer" min="2" max="100"
                                    *ngIf="NewSession != 'Edit Session'" (keydown)="onKeyDown($event)"
                                    (change)="noOfPlayerHandle()">
                                <div *ngIf="NewSession == 'Edit Session'" style="font-weight: 600;">{{noOfPlayer}}</div>
                                <div *ngIf="noOfPlayerErrFlag">
                                    <span class="err_ele_span" style="margin-top: 10px;" *ngIf="noOfPlayer > 100">
                                        <img src="../../../assets/error_icon.svg" alt="">
                                        <span>No. of players cannot exceed 100</span>
                                    </span>
                                    <span class="err_ele_span" style="margin-top: 10px;" *ngIf="noOfPlayer < 2">
                                        <img src="../../../assets/error_icon.svg" alt="">
                                        <span>Minimum 2 players required for configuration</span>
                                    </span>
                                    <span class="err_ele_span" style="margin-top: 10px;" *ngIf="noOfPlayer > avaliableLicience && noOfPlayer > 2 && noOfPlayer <= 100">
                                        <img src="../../../assets/error_icon.svg" alt="">
                                        <span>You have only {{avaliableLicience}} licence left</span>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label for="client-name">Language
                                    <img placement="bottom" [ngbTooltip]="languageTooltip" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                                </label>
                                <select name="lang" id="lang" class="form-select custom-select">
                                    <option value="en-us" selected>English</option>
                                </select>
                            </div>
                            <div style="grid-column: 1/3;">
                                <app-scheduler *ngIf="NewSession != 'Edit Session' && toggleClander"
                                    (messageEmitter)="changeSteper($event)" [dateSelect]="sessionDate"
                                    [noOfPlayer]="noOfPlayer" [progarmId]="schdularProgamId" [clientName]="clientName"
                                    [sessionName]="sessionName" [avaliableLicience]="avaliableLicience"
                                    (dataSubmit)="makeProgramOnDraft($event)"></app-scheduler>
                                <div *ngIf="NewSession == 'Edit Session'">
                                    <label for="">Session Date & Time</label>
                                    <img placement="bottom" [ngbTooltip]="sessionduration" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                                    <div style="font-weight: 600;">{{editDisableDate}}</div>
                                </div>
                                <div *ngIf="NewSession != 'Edit Session'">
                                    <div class="timeLimit_box" *ngIf="!btnFlag && NewSession != 'Edit Session'">
                                        <img src="../../../assets/img/timer15min.svg" alt="">
                                        <div>
                                            <span>After selecting these time slots, <span style="font-weight: 500;">
                                                    you must complete the game setup within 15 mins,
                                                </span> else the incomplete game setup will be removed and these time
                                                slots will be released.</span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="NewSession == 'Edit Session'">
                                    <div class="timeLimit_box">
                                        <img src="../../../assets/error_icon.svg" alt="" style="width: 100px;">
                                        <div style="margin-bottom: 0;">
                                            <span>If you want to change the No. of players or Session Date & Time,
                                                please delete this session and create a new one.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="last-btn-div" *ngIf="NewSession != 'Edit Session'">
                                    <input style="width: 25%; cursor: not-allowed;" type="image"
                                        src="../../../assets/save&countinue_disabled.svg" *ngIf="btnFlag">
                                    <input style="width: 25%;" type="image" src="../../../assets/img/save&conti-btn.svg"
                                        (click)="moveNext()" *ngIf="!btnFlag">
                                </div>
                            </div>


                        </div>

                    </div>
                    <div style="text-align: center;display: flex;
                    justify-content: center;
                    gap: 30px;margin-top: 3rem;" *ngIf="NewSession == 'Edit Session'">

                        <input style="width: 158px;" type="image" src="../../../assets/delete_session.svg"
                            (click)="deleteSession()">
                        <input style="width: 158px;" type="image" src="../../../assets/img/save&conti-btn.svg"
                            (click)="goToStep2()">
                    </div>
                </div>



            </div>
            <div class="container-div remove-grid" *ngIf="step == 2">
                <div style="display: flex; flex-direction: row; gap:3.5vw;">
                    <!-- <div class="st2-quik-info">
                        <div>
                            <img src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" alt="back"
                                srcset="" (click)="goToStep1()">
                            <span>{{sessionName}}</span>
                        </div>
                        <div>
                            {{clientName}}
                        </div>
                    </div> -->
                    <div style="display: flex; flex-direction:column; margin-left: 18px;">
                        <div class="session_dtls_div">Session Details </div>
                        <div class="side-nav-info-div">
                            <div style="display: flex;justify-content: space-between;align-items: flex-start;">
                                <div style="max-width:60%; overflow-wrap: anywhere;">{{sessionName}}</div>
                                <div>
                                    <div class="editBtnCss" (click)="goBackToStep1()">Edit</div>
                                </div>
                            </div>
                            <div>
                                <ul>
                                    <li>{{clientName}}</li>
                                    <li>
                                        {{sessionDate | date:' d MMMM yyyy' }}
                                    </li>
                                    <li>{{timeSlotData.stringDateTime}}</li>
                                    <li>{{noOfPlayer}} Players</li>
                                </ul>
                            </div>
                        </div>


                    </div>
                    <!-- <div cdkDropListGroup class="st2-drag-drop-main-div">
                            <div class="example-container example-container-left">
                                <div class="st2-left-text"> Select games (Drag & drop) and rearrange as per the playing
                                    order </div>

                                <div cdkDropList [cdkDropListData]="todo" class="drag-drop-left"
                                    (cdkDropListDropped)="reverse($event)">
                                    <div class="example-box" *ngFor="let item of todo" cdkDrag>
                                        <div>
                                            <img [src]="getJSONparse(item,'imgUrl')" alt="" srcset="">
                                        </div>
                                        <div>
                                            {{getJSONparse(item,'name')}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="right-final-div">
                                <div class="example-container example-container-right">

                                    <div cdkDropList [cdkDropListData]="done" class="example-list example-list-right"
                                        (cdkDropListDropped)="drop($event)">
                                        <div class="example-box-right" *ngFor="let item of done; let i = index" cdkDrag>
                                            <div>
                                                <span>{{i+1}}</span>
                                                <span><img src="../../../assets/img/3Lines.svg" alt=""></span>

                                            </div>
                                            <div>
                                                <span>{{getJSONparse(item,'name')}}</span>
                                                <img src="../../../assets/img/Delete Button.svg" alt=""
                                                    style="cursor: pointer;" (click)="deleteFromRight(item,i)">
                                            </div>
                                        </div>
                                        <div class="bottom-text-main-div">
                                            <div>+ Drag & Drop here</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="last-btn-div">
                                    <input style="width: 35%;" type="image" src="../../../assets/img/save&conti-btn.svg"
                                        (click)="saveAndMoveToNew()">
                                </div>
                            </div>
                        </div> -->
                    <div>




                        <div class="st2-drag-drop-main-div">
                            <div style="display: flex;flex-direction: column;">
                                <div style="height: 50px;">&nbsp;</div>
                                <div style="flex: 1;" class="example-container example-container-left">

                                    <div class="st2-left-text"> Select games (Drag & drop) and rearrange as per the
                                        playing
                                        order </div>

                                    <div class="drag-drop-left">
                                        <div class="example-box" *ngFor="let item of todo;let i = index"
                                            draggable="true" (dragstart)="drag($event,i)" (dragend)="dragEnd()">
                                            <div>
                                                <img [id]="'img'+i" [src]="getJSONparse(item,'imgUrl')" alt="" srcset=""
                                                    draggable="true">
                                            </div>
                                            <div>
                                                {{getJSONparse(item,'name')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="right-final-div">
                                <div
                                    style="display:flex; height:50px; font-size: 22px; font-weight:600; justify-content:center; align-content:center">
                                    <span *ngIf="done.length == 0">Add Games to the Session</span>
                                    <span *ngIf="done.length > 0">Add or Remove Games</span>
                                </div>
                                <div class="example-container example-container-right">

                                    <div class="example-list example-list-right" id="dropDiv" (drop)="drop1($event)"
                                        (dragover)="allowDrop($event)" cdkDropList [cdkDropListData]="done"
                                        (cdkDropListDropped)="drop($event)">
                                        <div class="example-box-right" *ngFor="let item of done; let i = index" cdkDrag>
                                            <!-- draggable="true" (dragstart)="dragInside($event,i)" -->
                                            <!-- (dragend)="dragInsideEnd()" (drop)="insideDrop(i)" -->
                                            <div>
                                                <span>{{i+1}}</span>
                                                <span><img src="../../../assets/img/3Lines.svg" alt=""></span>

                                            </div>
                                            <div>
                                                <span>{{getJSONparse(item,'name')}}</span>
                                                <img src="../../../assets/img/Delete Button.svg" alt=""
                                                    style="cursor: pointer;" (click)="deleteFromRight(item,i)">
                                            </div>
                                        </div>

                                        <div *ngIf="errorFlagForGameDelete" class=""
                                            style="display:flex; justify-content:center; margin-top:10px">
                                            <span style="color:#DB0000"><img src="../../../assets/error_icon.svg" alt=""
                                                    style="margin-right:2px">{{errorFlagMessageForGameDelete}}</span>
                                        </div>
                                        <div *ngIf="errorFlag" class=""
                                            style="display:flex; justify-content:center; margin-top:10px">
                                            <span style="color:#DB0000"><img src="../../../assets/error_icon.svg" alt=""
                                                    style="margin-right:2px">{{errorFlagMessage}}</span>
                                        </div>
                                        <div class="bottom-text-main-div" *ngIf="done.length == 0">
                                            <div>+ Drag & Drop here</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="last-btn-div">
                                    <input style="width: 35%;" type="image" src="../../../assets/img/save&conti-btn.svg"
                                        (click)="saveAndMoveToNew()">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>