<div style="margin-right: 3vw;" class="main_py_assign_container">
    <div class="physical_game_main_div">
        <div class="phsical_heading" style="display: flex;">
            <img src="../../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg " alt="" style="width: 22px;cursor: pointer;" (click)="goBack()">
            Digital Games
        </div>
       
        <div class="py_expiry_main_div">
            <div class="py_expiry_validity_div">
                <div class="py_expiry_validity_sub_div">
                    <div>No. of Licenses</div>
                    <div class="py_expiry_validity_inp_div">
                        <input type="number" name="" id="" [(ngModel)]="noOfLicenses" (keydown)="onKeyDown($event)"  (input)="dateValidityChangeHandler()" class="dg_inp">
                        <!-- <span>Months</span> -->
                    </div>
                </div>  
                <div>&nbsp;</div>
            </div>
            <div class="py_expiry_start_end_div" style="margin-top: 1rem;">
                <div class="py_expiry_element_div">
                    <div>Start Date</div>
                    <input type="date" name="" id="" class="date_inp dg_inp" [(ngModel)]="start_date" (change)="dateValidityChangeHandler()"  style="margin-right: 10px;">
                </div>
                <div class="py_expiry_element_div">
                    <div>End Date</div>
                    <div>{{end_date | date:'dd/MM/yyyy'}}</div>
                </div>
            </div>
            <div class="py_expiry_validity_div">
                <div class="py_expiry_validity_sub_div">
                    <div>Validity</div>
                    <div class="py_expiry_validity_inp_div">
                        <input type="number" name="" id="" [(ngModel)]="validity" (keydown)="onKeyDown($event)"  (input)="dateValidityChangeHandler()" class="dg_inp">
                        <span>Months</span>
                    </div>
                </div>  
                <div>&nbsp;</div>
            </div>
        </div>
        <div class="physical_body_main_div assign_physical_body_main_div" style="width: 100%;align-items: flex-start;">
            <div class="physical_body_sub_div" style="display: flex;gap: 15px;">
                <div class="assign_heading">Select Games</div>
                <div class="py_game_list_main_div" style="background: #FFFFFF;margin-top: 10px;">
                    <div class="grid_2fr" style="grid-template-columns: 1fr;">
                        <div *ngFor="let item of gameList" class="py_game_list_div">
                            <input type="checkbox" name="" id=""  checked="true" disabled ="true">
                            <span>{{item.game_name}}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="assign_new_btn_div" style="margin-top: 0;">
            <button class="btn_custom" (click)="assignGame()">Assign Games</button>
        </div>
    </div>
</div>