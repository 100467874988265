<div style="padding: 2rem;font-family: 'Roboto';">
    <div class="updatePassword_heading">Update Password</div>
    <div *ngIf="allBlankFlag">
        <span class="err_ele_span" style="margin-top: 10px;">
            <img src="../../../assets/error_icon.svg" alt="">
            <span>{{errTxt}}</span>
        </span>
    </div>
    <!-- <div class="close-div"><img src="../../../assets/img/close-md-svgrepo-com.svg" alt="" (click)="close()"></div> -->
    <div class="content-div">
        <div class="grid-1-2 input-div">
            <span>Old Password</span>
            <input type="password" name="name" [(ngModel)]="currentPass">
            <div *ngIf="apiErrFlag">
                <span class="err_ele_span" style="margin-top: 10px;">
                    <img src="../../../assets/error_icon.svg" alt="">
                    <span>{{errTxt}}</span>
                </span>
            </div>
        </div>
        <div class="grid-1-2 input-div">
            <span>New Password</span>
            <input type="password" name="name" [(ngModel)]="newPass">
            <div *ngIf="passwordLenFlag">
                <span class="err_ele_span" style="margin-top: 10px;">
                    <img src="../../../assets/error_icon.svg" alt="">
                    <span>{{errTxt}}</span>
                </span>
            </div>
        </div>

        <div class="grid-1-2 input-div" style="margin-bottom: 0;">
            <span>Confirm New Password</span>
            <input type="password" name="name" [(ngModel)]="confirmPass">
        </div>
        <div *ngIf="passwordFlag">
            <span class="err_ele_span" style="margin-top: 10px;">
                <img src="../../../assets/error_icon.svg" alt="">
                <span>Those password doesn't match. Try again.</span>
            </span>
        </div>
    </div>
    <div style="text-align: center;margin-top: 1.5rem;">
        <button class="btn_custom" (click)="updatePassword()">Update</button>
    </div>
    <!-- <div class="change-password" (click)="updatePassword()"><span>Update Password</span></div> -->
</div>
<style>
    .updatePassword_heading {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .close-div {
        text-align: end;
    }

    .close-div img {
        width: 20px;
        cursor: pointer;
    }

    label {
        display: block;
    }

    input {
        border-radius: 5px;
        border-width: 1px;
        padding: 1px 6px;
    }

    .content-div {
        width: 25vw;
        max-width: 350px;
    }

    .new-password-div label {
        margin-top: 1rem;
    }

    .change-password {
        text-align: center;
        padding: 7px 0;
        font-weight: 500;
        cursor: pointer;
        background: #FCBC2B;
        border-radius: 5px;
        margin-top: 2rem;
    }

    .pass-err {
        font-size: 12px;
    }

    .grid-1-2 {
        display: grid;
        grid-template-columns: 1fr;
        /* gap: 10px; */
    }

    .input-div {
        margin-bottom: 2rem;
    }

    .input-div span {
        font-size: 16px;
        /* font-weight: 500; */
        padding: 3px 0;
        color: #00477B;
    }

    .input-div input {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #BAC9CC;
        border-radius: 22px;
        padding: 6px 1rem;

    }

    .err_ele_span {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .err_ele_span span {
        letter-spacing: 0.2px;
        color: #DB0000;
        font-size: 13px;
    }

    .btn_custom {
        font-size: 1rem;
        border: none;
        outline: none;
        background: transparent;
        background-image: url(../../../assets/btn_green_bg.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        padding: 0px 25px;
        font-size: 18px;
        font-weight: 600;
    }
</style>