<div>
    <div>
        <!-- <div class="step-main-div" *ngIf="steperFlag">
            <div>STEP 1</div>
            <div>STEP 2</div>
            <div class="step-active-css">STEP 3</div>
        </div> -->
        <div class="wh_header_main_div">
            <div>
                <div style="font-weight: 600;font-size: 22px;">Game set-up</div>
                <div>Please review the game details before you finish the setup !</div>
            </div>
            <div class="wh_header_right_sub">
                <div *ngIf="timerFlag">
                    <span>Time left to complete the set-up </span>
                    <span>{{('0'+remainingMinutes).slice(-2)}}:{{('0'+remainingSeconds).slice(-2)}}</span>
                </div>
                <div><img src="../../../assets/profile.png" alt="" (click)="toggleFunc()" style="cursor: pointer;">
                </div>
            </div>
            <div class="header-drowdwon" *ngIf="toggleFlag" (appOutsideClick)="outSideClickHandle($event)">
                <div class="header-drowdwon-content" (click)="goToAccountSetting()">
                    My Profile
                </div>
                <div class="header-drowdwon-content" (click)="goToMyGame()">
                    My Games
                </div>
                <div class="header-drowdwon-content" (click)="goGameReport()">
                    Games Reports
                </div>
                <div class="header-drowdwon-content" (click)="logout()">
                    Logout
                </div>
            </div>
        </div>
        <div class="bn_parent_div">
            <div class="bn_main_container">
                <div class="bn_container_header">
                    <img src="../../../assets/bn_one_line.svg" alt="" style="width: 165px;">
                </div>
                <div class="bn_container_body_main">
                    <div>
                        <div class="bn_text_grid" style="margin-bottom: 1rem;">
                            <span>No of players</span>
                            <span>:</span>
                            <span>{{maxPlayerData}}</span>
                        </div>
                        <div style="margin-bottom: 0.5rem;">
                            Game Board Selection
                            <span class="err_ele_span" *ngIf="crossErrorFlag">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>Please select any cross</span>
                            </span>
                        </div>
                        <div class="linear-main-div">
                            <div class="linear-element">
                                <div class="linear-img-div-1" (click)="linerSelect('4 Linear')"
                                    [ngClass]="selectedLiner == '4 Linear'?'active-linear':''"><img
                                        src="../../../assets/img/4-linear.svg" alt="" srcset=""></div>
                                <div><b>4 Linear</b><br>4 Linear Players per team is 8</div>
                            </div>
                            <div class="linear-element">
                                <div class="linear-img-div-2" (click)="linerSelect('5 Linear')"
                                    [ngClass]="selectedLiner == '5 Linear'?'active-linear':''"><img
                                        src="../../../assets/img/5-linear.svg" alt="" srcset=""></div>
                                <div><b>5 Linear</b><br>5 Linear Players per team is 10</div>
                            </div>
                        </div>
                        <div class="bn_text_grid" style="margin-top: 2rem;">
                            <span>No of teams</span>
                            <span>:</span>
                            <span>{{noOfTeamsData}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="last-btn-div" style="text-align: center; margin-top: 2rem;">
                    <input style="width: 83%; max-width: 200px" type="image"
                        src="../../../assets/img/save&conti-btn.svg" (click)="saveBottleNeckConfig()">
                </div>
            </div>
        </div>

        <!-- <div class="main-container">
            <div class="left-div">
                <div style="font-weight: 500; margin-left: 1rem; font-size: 15px;">Team Configuration</div>
                <div class="team-config-main-div">
                    <div class="element-input-div">
                        <div>
                            <li>Maximum number of players</li>
                            <img placement="bottom" [ngbTooltip]="maxplayers" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg">
                        </div>
                        <input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                            [(ngModel)]="maxPlayerData" [disabled]="disabledFlag"
                            [ngClass]="disabledFlag ? 'disabledInput':''">
                    </div>
                    <div class="element-input-div">
                        <div>
                            <li>Number of Teams</li>
                            <img placement="bottom" [ngbTooltip]="noteams" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg">
                        </div>
                        <input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                            [(ngModel)]="noOfTeamsData" [disabled]="disabledFlag"
                            [ngClass]="disabledFlag ? 'disabledInput':''">
                    </div>
                    <div class="element-input-div">
                        <div>
                            <li>Maximum number of players per team</li>
                            <img placement="bottom" [ngbTooltip]="maxteamplayers" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg">
                        </div>
                        <input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                            [(ngModel)]="maxTeamPlyData" [disabled]="disabledFlag"
                            [ngClass]="disabledFlag ? 'disabledInput':''">
                    </div>
                    <div class="element-input-div">
                        <div>
                            <li style="font-weight: 500;">Session Duration (in minutes)</li>
                            <img placement="bottom" [ngbTooltip]="gameduration" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg">
                        </div>
                        <input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                            [(ngModel)]="sessionTimeData">
                    </div>
                </div>
            </div>
            <div class="right-div">
                <div style="font-weight: 500;font-size: 15px; max-width: 496px; width: 100%;">Cross Selection</div>
                <div style="max-width: 496px;">
                    <div class="linear-main-div">
                        <div class="linear-element">
                            <div class="linear-img-div-1" (click)="linerSelect('4 Linear')"
                                [ngClass]="selectedLiner == '4 Linear'?'active-linear':''"><img
                                    src="../../../assets/img/4-linear.svg" alt="" srcset=""></div>
                            <div>4 Linear</div>
                        </div>
                        <div class="linear-element">
                            <div class="linear-img-div-2" (click)="linerSelect('5 Linear')"
                                [ngClass]="selectedLiner == '5 Linear'?'active-linear':''"><img
                                    src="../../../assets/img/5-linear.svg" alt="" srcset=""></div>
                            <div>5 Linear</div>
                        </div>
                    </div>
                    <div style="text-align: center;margin-top: 50px;">
                        The Gameboard selection is dependent upon the number of players in the group and the number of
                        teams for the game session. Choose the number of teams accordingly. Once started, all teams and
                        players will have the same gameboard loaded on their game screen.
                    </div>
                    <div>
                        <div class="last-btn-div" style="text-align: center; margin-top: 10px;">
                            <input style="width: 32%; max-width: 200px" type="image"
                                src="../../../assets/img/save&conti-btn.svg" (click)="saveBottleNeckConfig()">
                        </div>
                    </div>
                </div>

            </div>
        </div> -->
    </div>
</div>
<style>
    .disabledInput {
        background-color: #dddddd !important;
    }
</style>