<div class="container">
    <div style=" margin-bottom: 10px;">
        <img style="height: auto;" src="../../../assets/img/rocket.svg">
    </div>
    <div style=" margin-bottom: 10px;">
        <p>Are you sure you want to <b>start the session?</b></p>
    </div>
    <div class="d-flex" class="btns" >
        <input type="image" width="35%" src="../../../assets/img/yes.svg" (click)="open()">
        <input type="image" width="35%" src="../../../assets/img/no.svg" (click)="close(false)">
    </div>
</div>
<style>
    ::ng-deep .mat-dialog-container{
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
        background-color: #ffff;
        padding: 0;
       
    }
    .container{
        padding: 20px;
        display: flex;
        align-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }
    .btns{
        width: 5    0%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
</style>