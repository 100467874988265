<div>
    <app-header [oneHeader]="'Facilitator Dashboard'"></app-header>
    <!-- <div class="expiry_msg_div" role="alert" *ngIf="!flag">
        <div>Your validity is going to expire soon! Reach out to us for renewal.</div>
    </div> -->
    <div class="entire">
        <div class="newSessionEntire" *ngIf="newSession">

            <div class="newSession">
                <div class="close"><img src="../../assets/img/close-md-svgrepo-com.svg" alt="" (click)="closePopup()"
                        style="width: 20px; cursor: pointer;"></div>
                <div class="newSes">
                    <p><b style="font-size: 18px;">New Session</b></p>
                </div>
                <div class="newExp">
                    <p>What type of game you want to set-up for your session?</p>
                </div>
                <div class="gameSelection">
                    <div class="gsbox dg">
                        <p class="gsHeader"><b>Digital Game</b></p>
                        <p class="gsDesc">Virtual & Hybrid learning sessions can be made more dynamic and impactful with
                            these powerful games.

                            After you schedule the game, Your session dashboard will give you access to the game
                            resources, player link to share with your cohort and the Game control room.

                        </p>
                        <div class="gsSetup setupDG" [routerLink]="['/configure']">
                            <p>Set-up</p>
                        </div>
                    </div>
                    <div class="gsbox pg">
                        <p class="gsHeader"><b>Physical Game</b></p>
                        <p class="gsDesc">For classroom learning sessions to be interactive and fun, choose from these
                            set of exciting games.
                            Once you schedule your session you'll get access to all the resources you'll need to conduct
                            an awesome session.

                        </p>
                        <div class="gsSetup setupPG" [routerLink]="['/configure/physical']">
                            <p>Set-up</p>
                        </div>
                    </div>
                    <div class="gsbox qe" [ngClass]="{QuesticoAccess:is_questico_access=='0'} ">
                        <p class="gsHeader"><b>Questico</b></p>
                        <p class="gsDesc">"Use this race themed customizable platform to create a gamified learning
                            experience for your cohort, either as a single player or team based game.

                            This link will take you directly to the Questico dashboard where you can create or choose
                            challenges and other parameters that will define your game
                        </p>
                        <div class="gsSetup setupQE" [ngClass]="{QuesticoAccess:is_questico_access=='0'} "
                            (click)="questicoNavigate()">
                            <p>Set-up</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="exploreGame">
            <!-- <p class="exploreGameHeader headers" *ngIf="Games.length>0">My Upcoming Sessions</p> -->
            <!-- <p class="exploreGameHeader headers" *ngIf="Games.length==0">Explore Games</p> -->
            <div class="exGameContainer">
                <!-- For Existing user -->
                <div class="noGameLeft" *ngIf="Games.length>0">
                    <div class="noGameContainer" style="width: 100%;justify-content: flex-start;">
                        <div class="my_session_main_div">
                            <div class="game_heading">My Scheduled Sessions</div>
                            <div>
                                <button class="btn_custom" style="padding: 7px 16px;" [routerLink]="['/scheduled']">View all sessions</button>
                            </div>
                        </div>
                        <div class="game_card_main_div">
                            <div class="exGameBox" *ngFor="let game of Games.slice(0,2)"
                                (click)="openSessionDashboard(game)" [ngClass]="{today:game.schedulesStatus}">
                                <div style="float: right;"
                                    [ngClass]="{physical:game.game_type=='physical',digital:game.game_type=='digital',questico:game.game_type=='questico'} ">
                                    {{game.game_type}}</div>
                                <p class="exGameName">{{game.program_name}}</p>
                                <p class="exGameDate">{{game.program_date}}</p>
                                <p class="exGameCompany">{{game.client_name}}</p>
                                <hr
                                    [ngClass]="{physicalHr:game.game_type=='physical',digitalHr:game.game_type=='digital',questicoHr:game.game_type=='questico'} ">
                                <div *ngFor="let subGame of game.program_games.slice(0,3)">
                                    <p class="subGames">{{subGame.game_name}}</p>
                                </div>
                                <p style="float: right;color: #004784;margin-top: 10px;" class="exGameCompany"
                                    *ngIf="game.program_games.length>3">
                                    more
                                </p>
                            </div>
                        </div>
                        <div class="game_setup_main_div">
                            <div class="setUpGame" (click)="openNewSessionSetup()" style="margin-top: 0;" *ngIf="this.digitalGameCount != 0">
                                <div class="setUpTxt" >
                                    <p>Set-Up</p>
                                    <p>New Session</p>
                                </div>
                                <div>
                                    <img class="setUpImg" src="../../assets/setNewSession.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- For New User -->
                <div class="noGameLeft" *ngIf="Games.length==0">
                    <div class="noGameContainer">
                        <div class="setUpGame" (click)="openNewSessionSetup()" *ngIf="this.digitalGameCount != 0">
                            <div class="setUpTxt">
                                <p>Set-Up</p>
                                <p>New Session</p>
                            </div>
                            <div>
                                <img class="setUpImg" src="../../assets/setNewSession.svg">
                            </div>
                        </div>
                        <div>
                            <div><b style="font-size: 24px;">You have no schedule sessions</b></div>
                            <div class="noSessionMsg">You have no schedule sessions</div>
                        </div>
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;gap: 10px;height: 80vh;">
                    <div class="exGameRight">
                        <p style="margin-bottom: 10px;font-size: 28px;"><b>My Games</b></p>
                        <div>
                            <div class="game_heading" *ngIf="digitalGameCount != 0">Digital Games</div>
                            <div class="game_dlts_main_div" *ngIf="digitalGameCount != 0">
                                <div class="game_dlts_div">
                                    <div class="game_dlts_text">Games</div>
                                    <div class="game_dlts_value">{{digitalGameCount}}</div>
                                    <div class="game_dlts_text">Licenses left</div>
                                    <div class="game_dlts_value">{{LicienceLeft}}</div>
                                    <div class="game_dlts_text">Valid till</div>
                                    <div class="game_dlts_value">{{DigitalValidTill | date:' dd MMMM yyyy' }}</div>
                                </div>
                                <div>
                                    <button class="btn_custom" (click)="digitalMyGames()">View Games</button>
                                </div>
                            </div>
                            <div class="game_heading" *ngIf="physicalGameCount != 0">Physical Games</div>
                            <div class="game_dlts_main_div" *ngIf="physicalGameCount != 0">
                                <div class="game_dlts_div">
                                    <div class="game_dlts_text">Games</div>
                                    <div class="game_dlts_value">{{physicalGameCount}}</div>
                                </div>
                                <div>
                                    <button class="btn_custom" (click)="physicalMyGames()">View Games</button>
                                </div>
                            </div>
                            <div *ngIf="physicalGameCount == 0 && digitalGameCount == 0" style="text-align: center;margin-top: 1rem;">
                                No Game Found
                            </div>
                        </div>
                    </div>
                    <div class="exGameRight" style="padding-bottom: 25px;" (click)="goToExplore()">
                        <p style="margin-bottom: 10px;font-size: 28px;"><b>Explore all Games</b></p>
                        <div class="explore_game_sub_div">
                            <img src="../../assets/digital_game_btn.svg" alt="" (click)="goToGameResource('digital')">
                            <img src="../../assets/physical_game_btn.svg" alt="" (click)="goToGameResource('physical')">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- <div class="gamesYouLove">
            <p style="font-weight: 500;margin-top: 18px;margin-bottom: 10px;" class="headers">Games you will Love</p>
            <div class="gameLoveContainer  ">
                <div class="gameLoveContainerInside d-flex">
                    <div class="glBox al" (click)="goToResoure('5')">
                        <div class="glBoxImg"><img src="../../assets/afLogo.svg"></div>
                        <div class="glBoxTxt">
                            <p class="glName"><b>Animal Farm</b></p>
                            <p class="gameExp">Build Trust and Collaboration</p>
                            <p class="gameExp">in your teams.</p>
                        </div>
                    </div>
                    <div class="glBox wh" (click)="goToResoure('3')">
                        <div class="glBoxImg"><img src="../../assets/whLogo.svg"></div>
                        <div class="glBoxTxt">
                            <p class="glName"><b>Warehouse</b></p>
                            <p class="gameExp">A Race Against Time for Planning,</p>
                            <p class="gameExp">Organizing, and Execution</p>
                        </div>
                    </div>
                    <div class="glBox vc" (click)="goToResoure('11')">
                        <div class="glBoxImg" style="margin: auto;"><img src="../../assets/img/assembley_line.jpg"
                                style="background:1px solid #b9b9b9;border-radius: 10px">
                        </div>
                        <div class="glBoxTxt">
                            <p class="glName"><b>Assembly Line</b></p>
                            <p class="gameExp">Experience Innovation, Growth,</p>
                            <p class="gameExp">and Continuous Improvement</p>
                        </div>
                    </div>
                    <div class="glBox bn" (click)="goToResoure('2')">
                        <div class="glBoxImg"><img src="../../assets/img/bottleneck.svg"></div>
                        <div class="glBoxTxt">
                            <p class="glName"><b>Bottleneck</b></p>
                            <p class="gameExp">Overcoming Obstacles Through</p>
                            <p class="gameExp">Collaboration and Strategy.</p>
                        </div>
                    </div>
                    <div (click)="goToExplorePage()" style="margin: auto 0;"><img class="exGameViewall viewBtn"
                            src="../../assets/viewAll.svg"></div>
                </div>
            </div>
        </div> -->
    </div>
</div>