import { ChangeDetectorRef, Component, OnInit, Inject, HostListener } from '@angular/core';
import { Route, Router } from '@angular/router';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, interval } from 'rxjs';
import { VCCommomDialogComponent, WHCommomDialogComponent } from '../commom-dialog/commom-dialog.component';
import { DatePipe } from '@angular/common';
import { DashboardService } from 'src/app/Shared/Services/Dashboard.service';
let totalConfigGame: any[] = []
let totalSelctedGame: any[] = []
let configSelect: any
let slotDeleteFlag: boolean = true
let intervalRef: any
let pageDestoryFlag: boolean = false
@Component({
  selector: 'app-new-games',
  templateUrl: './new-games.component.html',
  styleUrls: ['./new-games.component.css'],
  providers: [DatePipe],
})
export class NewGamesComponent implements OnInit {
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return pageDestoryFlag;
  }
  constructor(
    private dataSharing: DataSharingServiceService,
    private router: Router,
    private httpSv: HttpService,
    private dashboardShare: DashboardService,
    private dialog: MatDialog,
    private toster: ToastrService
  ) { }
  moveToHomePage() {
    this.router.navigate(['/dashboard'])
  }
  ngOnInit(): void {
    pageDestoryFlag = false
    console.log(this.dataSharing.newSetup)
    if (!this.dataSharing.newSetup) {
      this.NewSession = 'Edit Session'
      pageDestoryFlag = true
      console.log(this.dataSharing.gameEditAllDelete)
      if (this.dataSharing.gameEditAllDelete) {
        pageDestoryFlag = false
        localStorage.setItem('epidt', this.dataSharing.gameEditDeleteId)
        localStorage.setItem('sessionType', this.NewSession)
        localStorage.setItem('pageDestroy', pageDestoryFlag.toString())
      }
      if (this.dataSharing.sessionData.singleEdit) {
        this.editFlagger = false
      }
    }
    this.selectedGames = this.dataSharing.selectedGameForConfig.map((ele: any) => {
      return JSON.parse(ele)
    })
    totalSelctedGame = this.selectedGames
    if (this.selectedGames.length == 0) {
      console.log('111')
      pageDestoryFlag = true
      localStorage.setItem('pageDestroy', 'true')
      // alert('22')
      this.router.navigate(['/configure'])
    }
    this.sessionData = this.dataSharing.sessionData
    if (this.dataSharing.currentSelect == null) {
      console.log('22222')
      pageDestoryFlag = true
      localStorage.setItem('pageDestroy', 'true')
      this.router.navigate(['/configure'])
    }
    localStorage.setItem('flag', 'true')
    // console.log(this.dataSharing.sessionData.programId)
    // this.httpSv.getProgramDtls({program_id:this.dataSharing.sessionData.programId}).subscribe((res:any)=>{
    //   if(res['status']){

    //   }
    // })
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    const currentSelect = this.dataSharing.currentSelect
    const reqIndex = this.selectedGames.findIndex((ele: any) => ele['gameId'] == currentSelect)
    const gameDtl = this.selectedGames[reqIndex]
    const id = this.selectedGames[reqIndex]['name'] + reqIndex
    this.handleGameCofigClick(gameDtl, id, reqIndex)
  }
  async ngOnDestroy(): Promise<void> {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (localStorage.getItem('pageDestroy') == 'false' && localStorage.getItem('sessionType') == 'Edit Session' && Object.values(this.tickMarkFlag).every(value => value === false)) {
      this.httpSv.deleteBookedSlot({ program_id: localStorage.getItem('epidt') }).subscribe((res: any) => {
        localStorage.removeItem('epidt')
        localStorage.removeItem('sessionType')
        localStorage.removeItem('pageDestroy')
        this.router.navigate(['/scheduled'])
      })

    }
    this.dataSharing.gameEditAllDelete = false
    this.dataSharing.gameEditDeleteId = null
    // localStorage.removeItem('epidt')

    if (this.dashboardShare.editStepFalg) {
      if (this.dashboardShare.editStepFalg.step2) {
        clearInterval(intervalRef)
      }
    } else {
      console.log(slotDeleteFlag)
      if (slotDeleteFlag && localStorage.getItem('flag') != 'false') {
        try {
          await this.httpSv.deleteBookedSlot({ program_id: localStorage.getItem('uuid') }).toPromise()
          //   .subscribe((res: any) => {
          //   clearInterval(intervalRef)
          // })
        } catch (error) {
          console.error('Error deleting booked slot:', error);
        }
      }
      else if (localStorage.getItem('flag') == 'false') {
        clearInterval(intervalRef)
      }
      this.selectedGames = []
      this.unsub = true
      this.dataSharing.selectedGameForConfig = []
      this.dataSharing.sessionData = {}
      this.dataSharing.currentSelect = null
      this.dataSharing.totalConfigDone = []
      this.dataSharing.sessionTempEditData = {}
      this.dataSharing.newSetup = true
      this.dataSharing.lastGameConfigId = null
      this.dataSharing.timeSlotData = null
      localStorage.removeItem('uuid')
      localStorage.removeItem('flag')
      clearInterval(intervalRef)
    }
    for (let i = 1; i <= 10; i++) {
      clearInterval(i);
    }

  }
  NewSession: any = 'New Session'
  Digital: any = 'Digital'
  Name: any = 'Configure Games'
  unsub: boolean = false
  editFlagger: boolean = true
  tickMarkFlag: any = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  }
  loopFunc() {
    let data = interval(1000).subscribe(() => {
      const currentSelect = this.dataSharing.currentSelect
      if (currentSelect == null) {
        data.unsubscribe()
        return
      }
      if (this.configSelect != currentSelect) {
        const reqIndex = this.selectedGames.findIndex((ele: any) => ele['gameId'] == currentSelect)
        const gameDtl = this.selectedGames[reqIndex]

        const id = this.selectedGames[reqIndex]['name'] + reqIndex
        this.handleGameCofigClick(gameDtl, id, reqIndex)
        switch (this.dataSharing.lastGameConfigId) {
          case 1:
            this.tickMarkFlag['1'] = true
            if (this.NewSession == 'Edit Session' && !pageDestoryFlag && this.dataSharing.gameEditAllDelete) pageDestoryFlag = true
            break;
          case 2:
            this.tickMarkFlag['2'] = true
            if (this.NewSession == 'Edit Session' && !pageDestoryFlag && this.dataSharing.gameEditAllDelete) pageDestoryFlag = true
            break;
          case 3:
            this.tickMarkFlag['3'] = true
            if (this.NewSession == 'Edit Session' && !pageDestoryFlag && this.dataSharing.gameEditAllDelete) pageDestoryFlag = true
            break;
          case 4:
            this.tickMarkFlag['4'] = true
            if (this.NewSession == 'Edit Session' && !pageDestoryFlag && this.dataSharing.gameEditAllDelete) pageDestoryFlag = true
            break;
          case 5:
            this.tickMarkFlag['5'] = true
            if (this.NewSession == 'Edit Session' && !pageDestoryFlag && this.dataSharing.gameEditAllDelete) pageDestoryFlag = true
            break;
          case 6:
            this.tickMarkFlag['6'] = true
            if (this.NewSession == 'Edit Session' && !pageDestoryFlag && this.dataSharing.gameEditAllDelete) pageDestoryFlag = true
            break;
        }
      }
      if (this.unsub)
        data.unsubscribe()
    })

  }
  selectedGames: any
  sessionData: any
  configSelect: any
  handleGameCofigClick(gameDtl: any, id: any, index: any) {
    if (document.getElementsByClassName('game-select-css').length > 0) {
      document.getElementsByClassName('game-select-css')[0].classList.remove('game-select-css')
    }
    document.getElementById(id)?.classList.add('game-select-css')
    this.configSelect = gameDtl['gameId']
    this.dataSharing.currentSelect = gameDtl['gameId']
    configSelect = index
    this.loopFunc()

  }
  goToStep2Edit() {
    if (this.NewSession != 'Edit Session') {
      const dialogRef = this.dialog.open(DeleteEditConfirmDialog, {
        disableClose: true,
        // minWidth: '30rem',
        maxWidth: '30rem',
        data: "config_page"
      })
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {

          this.httpSv.deleteGameOnEdit(this.sessionData.programId).subscribe((res: any) => {
            if (res) {
              this.dashboardShare.editStepFalg = {
                step1: true,
                step2: true,
                step3: false
              }
              this.dataSharing.sessionData = {
                sessionName: this.sessionData.sessionName,
                sessionDate: this.sessionData.sessionDate,
                clientName: this.sessionData.clientName,
                programId: this.sessionData.programId,
                maxPlayer: this.sessionData.sessionPlayer,
                // timeSlotDtls: {
                //   date:this.sessionConfigDate,
                //   time:this.sessionConfigTime
                // },
                // noOfSlot           
              }
              const temp = this.dashboardShare.editStep3
              temp['sessionName'] = this.sessionData.sessionName
              temp['sessionDate'] = this.sessionData.sessionDate
              temp['clientName'] = this.sessionData.clientName
              temp['programId'] = this.sessionData.programId
              temp['maxPlayer'] = this.sessionData.sessionPlayer
              console.log(this.NewSession == 'Edit Session', this.NewSession)
              temp['editOldSession'] = this.NewSession == 'Edit Session' ? true : false
              // if (this.dataSharing.totalConfigDone.length > 0)

              pageDestoryFlag = true
              localStorage.setItem('pageDestroy', 'true')
              this.dataSharing.newSetup = true
              this.router.navigate(['/configure'])
            }

          }, (err: any) => {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
          })


        }
      })
    } else {
      this.dashboardShare.editStepFalg = {
        step1: true,
        step2: true,
        step3: false
      }
      this.dataSharing.sessionData = {
        sessionName: this.sessionData.sessionName,
        sessionDate: this.sessionData.sessionDate,
        clientName: this.sessionData.clientName,
        programId: this.sessionData.programId,
        maxPlayer: this.sessionData.sessionPlayer,
        // timeSlotDtls: {
        //   date:this.sessionConfigDate,
        //   time:this.sessionConfigTime
        // },
        // noOfSlot           
      }
      const temp = this.dashboardShare.editStep3
      temp['sessionName'] = this.sessionData.sessionName
      temp['sessionDate'] = this.sessionData.sessionDate
      temp['clientName'] = this.sessionData.clientName
      temp['programId'] = this.sessionData.programId
      temp['maxPlayer'] = this.sessionData.sessionPlayer
      console.log(this.NewSession == 'Edit Session', this.NewSession)
      temp['editOldSession'] = this.NewSession == 'Edit Session' ? true : false
      console.log(pageDestoryFlag)
      temp['intialGameValue'] = pageDestoryFlag
      pageDestoryFlag = true
      localStorage.setItem('pageDestroy', 'true')
      localStorage.setItem('pageDestroy', 'true')
      this.dataSharing.newSetup = true
      this.router.navigate(['/configure'])
    }

  }
}


//Value Continuum
@Component({
  selector: 'app-new-vc',
  templateUrl: './vc-new.html',
  styleUrls: ['./new-games.component.css']
})
export class VcNewGamesComponent implements OnInit {
  constructor(
    private httpSv: HttpService,
    private detectChange: ChangeDetectorRef,
    private dialog: MatDialog,
    private toster: ToastrService,
    private dataService: DataSharingServiceService,
    private router: Router,
    private dashboardService: DashboardService

  ) { }
  disabledFlag: boolean = true
  editFlag: boolean = false
  timerFlag: boolean = true
  ngOnInit(): void {
    console.log(this.dataService.newSetup)
    const role: any = localStorage.getItem('role')
    if (role == 1) {
      this.disabledFlag = false
    }
    this.steperFlag = this.dataService.newSetup


    if (this.dataService.sessionTempEditData['1']) {
      const temp = this.dataService.sessionTempEditData['1']
      this.myPairLeft = temp.myPairLeft
      this.myPairRight = temp.myPairRight
      this.slectedPairList = temp.slectedPairList
      this.done = temp.done
    }
    this.httpSv.getVcPairs().subscribe((res: any) => {
      if (res['status']) {
        this.vcPairsData = res['result']
        if (this.done.length == 0) {
          for (let i = 0; i < 10; i++) {
            this.done.push(this.vcPairsData[i])
            this.slectedPairList.push(this.vcPairsData[i].id)
          }
        }
      }
    }, (err: any) => {
      // this.toster.error(err.error.message)
    })
    this.getMyLib()
    console.log(this.dataService.sessionTempEditData)
    if (this.dataService.sessionTempEditData['1'] == null && !this.steperFlag) {
      this.fetchGameData()
    }
    else if (this.dataService.sessionTempEditData['1'] != null && !this.steperFlag) {
      this.timerFlag = false
    }
    else {
      this.timerEpochTime = this.dataService.timeSlotData.timeLeft.timerEpochTime
      clearInterval(intervalRef)
      intervalRef = setInterval(() => {
        if (this.remainingTime != 0) {
          this.updateRemainingTime();
        } else {
          console.log('11122233')
          clearInterval(intervalRef)
        }
      }, 1000);
    }
  }
  updateRemainingTime() {
    const currentTime = new Date().getTime();
    this.remainingTime = Math.max(0, Math.floor((this.timerEpochTime - currentTime) / 1000));
    if (this.remainingTime == 0) {
      console.log(intervalRef)
      this.toster.error('Oops! Times Up', '', { timeOut: 2000 })
      clearInterval(intervalRef)
      pageDestoryFlag = true
      localStorage.setItem('pageDestroy', 'true')
      this.dashboardService.editStep3 = null
      this.dashboardService.editStepFalg = null
      this.dataService.selectedGameForConfig = []
      this.dataService.sessionData = {}
      this.dataService.currentSelect = null
      this.dataService.totalConfigDone = []
      this.dataService.sessionTempEditData = {}
      this.dataService.newSetup = true
      this.dataService.lastGameConfigId = null
      if (localStorage.getItem('flag') != 'false') {
        this.router.navigate(['/configure'])
      } else { this.router.navigate(['/scheduled']) }
    }
  }
  get remainingMinutes(): number {
    if (this.remainingTime)
      return Math.floor(this.remainingTime / 60);
    else
      return 0
  }

  get remainingSeconds(): number {
    if (this.remainingTime)
      return this.remainingTime % 60;
    else
      return 0
  }
  timerEpochTime: any
  remainingTime: any
  steperFlag: boolean = true
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.dataService.sessionTempEditData['1'] = {
      myPairLeft: this.myPairLeft,
      myPairRight: this.myPairRight,
      slectedPairList: this.slectedPairList,
      done: this.done
    }
    clearInterval(intervalRef)
  }
  fetchGameData() {
    this.timerFlag = false
    this.httpSv.getVcGameDtls(this.dataService.sessionData.programId).subscribe((res: any) => {
      if (res['status']) {
        this.done = []
        this.slectedPairList = []
        this.done = res['result'][0].value_pairs
        res['result'][0].value_pairs.forEach((ele: any) => {
          this.slectedPairList.push(ele.id)
        });
        this.timerFlag = false
        console.log(this.slectedPairList)
      }
    }, (err: any) => {
      // this.toster.error(err.error.message)
    })
  }
  moveToNextGame(configId: any) {
    slotDeleteFlag = false
    localStorage.setItem('flag', 'false')
    if (this.dataService.totalConfigDone.findIndex((ele: any) => ele == configId) == -1)
      this.dataService.totalConfigDone.push(configId)
    let currentSelect = this.dataService.currentSelect
    // if (this.dataService.totalConfigDone.length == 1 && this.dataService.totalConfigDone.length == this.dataService.selectedGameForConfig.length) {
    //   this.router.navigate(['/scheduled'])
    //   this.dataService.selectedGameForConfig = []
    //   this.dataService.sessionData = {}
    //   this.dataService.currentSelect = null
    //   this.dataService.totalConfigDone = []
    //   this.dataService.sessionTempEditData = {}
    //   this.dataService.newSetup = true
    //   this.dataService.lastGameConfigId = null
    // }
    // else
    if (this.dataService.totalConfigDone.length == this.dataService.selectedGameForConfig.length) {
      const dialogRef = this.dialog.open(SessionCompleteDialog, {
        disableClose: true,
        minWidth: '350px',
        // minHeight: '185px'
      })
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          pageDestoryFlag = true
          localStorage.setItem('pageDestroy', 'true')
          this.router.navigate(['/scheduled'])
          this.dataService.selectedGameForConfig = []
          this.dataService.sessionData = {}
          this.dataService.currentSelect = null
          this.dataService.totalConfigDone = []
          this.dataService.sessionTempEditData = {}
          this.dataService.newSetup = true
          this.dataService.lastGameConfigId = null
          this.dashboardService.editStep3 = null
        }
      })
    }
    else {
      const reqIndex = this.dataService.selectedGameForConfig.findIndex((ele: any) => JSON.parse(ele)['gameId'] == currentSelect)
      if (reqIndex + 1 == this.dataService.selectedGameForConfig.length) {
        let i = 0
        let flag = 1
        while (flag != -1) {
          flag = this.dataService.totalConfigDone.findIndex((ele: any) => ele == JSON.parse(this.dataService.selectedGameForConfig[i])['gameId'])
          if (flag != -1)
            i++
        }
        this.dataService.currentSelect = JSON.parse(this.dataService.selectedGameForConfig[i])['gameId']
      }
      else {
        let i = reqIndex + 1
        let flag = 1
        while (flag != -1) {
          flag = this.dataService.totalConfigDone.findIndex((ele: any) => ele == JSON.parse(this.dataService.selectedGameForConfig[i])['gameId'])
          if (flag != -1) {
            if ((i + 1) == this.dataService.selectedGameForConfig.length) {
              i = 0
            }
            else
              i++
          }
        }
        this.dataService.currentSelect = JSON.parse(this.dataService.selectedGameForConfig[i])['gameId']
      }
      this.dataService.lastGameConfigId = 1
    }
  }
  getMyLib() {
    this.httpSv.getVcLibraryPairs().subscribe((res: any) => {
      if (res['status']) {
        this.myLibrary = res['result']
      }
    }, (err: any) => {
      if (err.error.message == 'No data found!') {
        this.myLibrary = []
      }
      // this.toster.error(err.error.message)
    })
  }
  getCssClass(id: any) {
    if (document.getElementById(id)) {
      let h = document.getElementById(id)?.offsetHeight
      if ((h ? h : 20) >= 73 && (h ? h : 20) <= 90) {
        return 'bigBox'
      }
      else if ((h ? h : 20) >= 90) {
        return 'largestBox'
      }

    }
    return ''
  }

  statementPairMainTooptip: string = 'Select a total of 15 Statement pairs ( from Kairos Library or My Library). The facilitator can choose to run all or some of the selected pairs using the game control room during the session.'
  statementPairTooptip: string = 'These 10 Statement pairs are the seemingly opposite statements at the two ends of the continuum. The facilitator can choose to run all or some of the selected pairs during the session.'
  kairosLibraryTooltip: string = 'These are pre-defined pairs provided to you covering a broad spectrum of preferences. Drag & Drop to choose from these pairs.'
  myLibraryTooltip: string = 'You can create and add a maximum of 30 pairs in the My Library section.  You can choose a maximum of 15 of these pairs for each of your sessions.'
  selectedPairTooltip: string = 'You can create and add a maximum of 15 pairs in the My Library section.'

  myPairLeft: string = ''
  myPairRight: string = ''
  selectedBslLib: boolean = true
  vcPairsData: any = []
  myLibrary: any = []
  done: any = [];
  slectedPairList: any[] = []
  drop(event: any) {
    if (this.slectedPairList.length < 15) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {

        if (document.getElementById(this.vcPairsData[event.previousIndex]['pair_left'] + this.vcPairsData[event.previousIndex]['id'])) {
          if ((this.slectedPairList.findIndex((ele: any) => ele == this.vcPairsData[event.previousIndex]['id'])) != -1) {
            this.toster.error('This pair already selected', '', { timeOut: 2000 })
            return
          }
          this.done.push(this.vcPairsData[event.previousIndex])
          document.getElementById(this.vcPairsData[event.previousIndex]['pair_left'] + this.vcPairsData[event.previousIndex]['id'])?.classList.add('change')
          this.slectedPairList.push(this.vcPairsData[event.previousIndex]['id'])
        }
        else {
          if ((this.slectedPairList.findIndex((ele: any) => ele == this.myLibrary[event.previousIndex]['id'])) != -1) {
            this.toster.error('This pair already selected', '', { timeOut: 2000 })
            return
          }
          this.done.push(this.myLibrary[event.previousIndex])
          document.getElementById(this.myLibrary[event.previousIndex]['pair_left'] + this.myLibrary[event.previousIndex]['id'])?.classList.add('change')
          this.slectedPairList.push(this.myLibrary[event.previousIndex]['id'])
        }
        this.detectChange.detectChanges()
      }
    }
    else {
      this.toster.error('Maximum of 15 games can configure', '', { timeOut: 2000 })
    }
  }
  removeFromSelect(event: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    else {
      let index = this.vcPairsData.findIndex((ele: any) => ele.id == this.done[event.previousIndex]['id'])
      let tempIndex = this.slectedPairList.findIndex((ele: any) => ele == this.done[event.previousIndex]['id'])
      this.done.splice(event.previousIndex, 1)
      this.slectedPairList.splice(tempIndex, 1)
      if (document.getElementById(this.vcPairsData[index]['pair_left'] + this.vcPairsData[index]['id']))
        document.getElementById(this.vcPairsData[index]['pair_left'] + this.vcPairsData[index]['id'])?.classList.remove('change')
      else
        document.getElementById(this.myLibrary[index]['pair_left'] + this.myLibrary[index]['id'])?.classList.remove('change')
    }
  }
  deleteFromRight(data: any, index: any) {
    document.getElementById(data['pair_left'] + data['id'])?.classList.remove('change')
    this.done.splice(index, 1)
    this.slectedPairList.splice(index, 1)
  }
  currentLib = 'bsl'
  handleLibChange(lib: any) {

    if (this.currentLib == lib) {
      return
    }
    else {
      if (lib == 'bsl') {
        document.getElementById('my-lib')?.classList.remove('left-incline-lib')
        document.getElementById('bsl-lib')?.classList.add('right-incline-lib')
        this.selectedBslLib = true

      }
      else {
        document.getElementById('bsl-lib')?.classList.remove('right-incline-lib')
        document.getElementById('my-lib')?.classList.add('left-incline-lib')
        this.selectedBslLib = false

      }
      this.currentLib = lib
    }
  }
  slectedPairs(item: any) {
    if ((this.slectedPairList.findIndex((ele: any) => ele == item['id'])) != -1)
      return true
    else return false
  }
  deleteMyLibPair(data: any) {
    const dialogRef = this.dialog.open(VcDeleteDialog, {
      disableClose: false,
      minWidth: '500px'
    })
    let doneIndex = this.done.findIndex((ele: any) => ele.id == data.id)
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.httpSv.deleteMyLibPair(data.id).subscribe((res: any) => {
          this.getMyLib()
          if (doneIndex != -1) {
            this.done.splice(doneIndex, 1)
            this.slectedPairList.splice(this.slectedPairList.findIndex((ele: any) => ele == data.id), 1)
          }
        })
      }
    }, (err: any) => {
      this.toster.error(err.error.message)
    })
  }
  closeEditSection() {
    if (this.slectedPairList.length >= 3 && this.slectedPairList.length <= 15) {
      this.editFlag = false
    } else {
      this.toster.error('Pair should must be more than 2 and less than 16', '', { timeOut: 2000 })
    }
  }
  saveConfig() {
    if (this.slectedPairList.length >= 3 && this.slectedPairList.length <= 15) {
      let data = {
        program_id: this.dataService.sessionData.programId,
        game_id: 1,
        max_player: this.dataService.timeSlotData.players,
        game_duration: this.dataService.timeSlotData.timeslots.length * 60,
        pair_id: this.slectedPairList
      }

      this.httpSv.configVcGame(data).subscribe((res: any) => {
        localStorage.setItem('flag', 'false')
        const dialogRef = this.dialog.open(SuccessDialog, {
          disableClose: true,
          minWidth: '250px',
          data: this.timerFlag
        })
        dialogRef.afterClosed().subscribe((res: any) => {
          if (res) {
            this.moveToNextGame(1)
            slotDeleteFlag = false
          }
        })
      }, (err: any) => {
        this.toster.error(err.error.message)
      })





    } else {
      this.toster.error('Pair should must be more than 2 and less than 16', '', { timeOut: 2000 })
    }

  }
  addNewMyLib() {
    if (this.myLibrary.length == 30) {
      this.toster.error('Maximum 30 pairs can be add in my library', '', { timeOut: 2000 })
      return
    }
    if (this.myPairLeft == '' || this.myPairRight == '') {
      this.toster.error('Please fill the pairs', '', { timeOut: 2000 })
      return
    }
    else {
      if ((this.myLibrary.findIndex((ele: any) => (ele.pair_left.toLowerCase() == this.myPairLeft.toLowerCase() && ele.pair_right.toLowerCase() == this.myPairRight.toLowerCase()))) == -1) {
        let data = {
          pair_left: this.myPairLeft,
          pair_right: this.myPairRight,
          program_id: this.dataService.sessionData.programId
        }
        this.httpSv.addPairInMyLib(data).subscribe((res: any) => {
          if (res['status']) {
            this.httpSv.getVcLibraryPairs().subscribe((res: any) => {
              if (res['status']) {
                this.myLibrary = res['result']
                this.toster.success('Pair added', '', { timeOut: 2000 })
                this.myPairLeft = this.myPairRight = ''
              }
            }, (err: any) => {
              this.toster.error(err.error.message, '', { timeOut: 2000 })
            })
          }
        }, (err: any) => {
          this.toster.error(err.error.pair_left[0], '', { timeOut: 2000 })
        })
      }
      else {
        this.toster.error('Same pair already exits', '', {
          timeOut: 2000
        })
      }
    }
  }
  characterLimit(event: any, dir: any) {
    if (dir == 'left') {
      if (this.myPairLeft.length < 15) {
        return true
      }
      else {
        this.toster.error('Pair should must be less than 16 characters')
        return false
      }
    }
    else {
      if (this.myPairRight.length < 15) {
        return true
      }
      else {
        this.toster.error('Pair should must be less than 16 characters')
        return false
      }
    }
  }
  toggleFlag: boolean = false
  tempCount: any = 0
  outSideClickHandle(event: any) {
    if (event) {
      if (this.toggleFlag) {
        if (this.tempCount == 1) {
          this.toggleFlag = false
          this.tempCount = 0
        }
        else
          this.tempCount = 1
      }
    }
  }
  goToAccountSetting() {
    this.toggleFlag = false
    this.router.navigate(['/accountSetting'])
  }
  goToMyGame() {
    this.dataService.myGames = null
    this.router.navigate(['myGames'])
  }
  goGameReport(){
    this.dashboardService.archiveFlag = true
    this.router.navigate(['/scheduled'])
  }
  logout() {
    this.router.navigate(['/login'])
    // this.httpSv.logOut().subscribe((res: any) => {
    //   this.router.navigate(['/login'])
    // })
  }
  toggleFunc() {
    this.toggleFlag = !this.toggleFlag
    if (!this.toggleFlag)
      this.tempCount = 0
  }
}

// VC pair delete dialog
@Component({
  selector: 'delete-vc-dialog',
  templateUrl: './vc-pair-delete-dialog.html',
})
export class VcDeleteDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<VcDeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  ngOnInit(): void {

  }
  close(data: boolean) {
    this.dialogRef.close(data)
  }
}
// success dialog
@Component({
  selector: 'success-dialog',
  templateUrl: './success-dialog.html',
})
export class SuccessDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SuccessDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }
  ngOnInit(): void {
  }
  close(data: boolean) {
    this.dialogRef.close(data)
  }
}




//Warehouse main ts
@Component({
  selector: 'app-new-warehouse',
  templateUrl: './warehouse-new.html',
  styleUrls: ['./warehouse.css']
})
export class WarehouseNewGamesComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private toster: ToastrService,
    private httpSv: HttpService,
    private dataShare: DataSharingServiceService,
    private router: Router,
    private dashboardService: DashboardService
  ) { }
  disabledFlag: boolean = true
  timerFlag: boolean = true
  ngOnInit(): void {
    const role: any = localStorage.getItem('role')
    // if (role == 1) {
    //   this.disabledFlag = false
    //   this.maxPlayerData = ''
    //   this.noOfTeamsData = ''
    //   this.perTeamPlyData = ''
    // }
    if (this.dataShare.timeSlotData) {
      this.disabledFlag = false
      this.maxPlayerData = this.dataShare.timeSlotData.players
      this.noOfTeamsData = ''
      this.perTeamPlyData = '0'
      this.sessionTimeData = this.dataShare.timeSlotData.timeslots.length * 60
    }
    this.steperFlag = this.dataShare.newSetup
    if (this.dataShare.sessionTempEditData['3'] == null && !this.steperFlag) {
      this.fetchGameData()

    } else if (this.dataShare.sessionTempEditData['3'] != null && !this.steperFlag) {
      this.timerFlag = false
    }
    if (this.dataShare.sessionTempEditData['3']) {
      const temp = this.dataShare.sessionTempEditData['3']
      this.maxPlayerData = temp.maxPlayerData
      this.noOfTeamsData = temp.noOfTeamsData
      this.perTeamPlyData = temp.perTeamPlyData
      this.gameTimeData = temp.gameTimeData
      this.sessionTimeData = temp.sessionTimeData
      this.selectedTheme = temp.selectedTheme
      this.selectedThemeGame = temp.selectedThemeGame
      this.noOfRoundEmptyArray = temp.noOfRoundEmptyArray
      this.roundData = temp.roundData
      this.selectedOrder = temp.selectedOrder
      this.totalOrder = temp.totalOrder
    }
    console.log(this.dataShare)
    this.maxDurationBySlots = this.dataShare.timeSlotData.timeslots.length * 60
    this.timerEpochTime = this.dataShare.timeSlotData.timeLeft.timerEpochTime
    this.timerEpochTime = this.dataShare.timeSlotData.timeLeft.timerEpochTime
    clearInterval(intervalRef)
    intervalRef = setInterval(() => {
      if (this.remainingTime != 0) {
        if (this.remainingTime != 0) {
          this.updateRemainingTime();
        } else {
          console.log('11122233')
          clearInterval(intervalRef)
        }
      }
    }, 1000);
  }
  maxDurationBySlots: any
  showSideOrder: boolean = false
  themeTooltipFlag: any = {
    com: false,
    snack: false,
    grocery: false,
    apprels: false,
    vechile: false,
  }
  flag: boolean = true
  testFunc() {
    console.log('asdas', this.flag)
  }
  steperFlag: boolean = true
  updateRemainingTime() {
    const currentTime = new Date().getTime();
    this.remainingTime = Math.max(0, Math.floor((this.timerEpochTime - currentTime) / 1000));
    if (this.remainingTime == 0) {
      pageDestoryFlag = true
      localStorage.setItem('pageDestroy', 'true')
      this.toster.error('Oops! Times Up', '', { timeOut: 2000 })
      clearInterval(intervalRef)
      if (localStorage.getItem('flag') != 'false') {
        this.router.navigate(['/configure'])
      } else { this.router.navigate(['/scheduled']) }
    }
  }
  get remainingMinutes(): number {
    if (this.remainingTime)
      return Math.floor(this.remainingTime / 60);
    else
      return 0
  }

  get remainingSeconds(): number {
    if (this.remainingTime)
      return this.remainingTime % 60;
    else
      return 0
  }
  timerEpochTime: any
  remainingTime: any
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.dataShare.sessionTempEditData['3'] = {
      maxPlayerData: this.maxPlayerData,
      noOfTeamsData: this.noOfTeamsData,
      perTeamPlyData: this.perTeamPlyData,
      gameTimeData: this.gameTimeData,
      sessionTimeData: this.sessionTimeData,
      selectedTheme: this.selectedTheme,
      selectedThemeGame: this.selectedThemeGame,
      noOfRoundEmptyArray: this.noOfRoundEmptyArray,
      roundData: this.roundData,
      selectedOrder: this.selectedOrder,
      totalOrder: this.totalOrder
    }
    clearInterval(intervalRef)
  }
  fillNoPlyr() {
    this.perTeamPlyData = this.noOfTeamsData ? Math.ceil(this.maxPlayerData / this.noOfTeamsData) : 0
  }
  fetchGameData() {
    this.timerFlag = false
    this.httpSv.getWhGameDtls(this.dataShare.sessionData.programId).subscribe((res: any) => {
      if (res['status']) {
        this.timerFlag = false
        let temp = res['result'][0]
        this.maxPlayerData = temp.max_player
        this.noOfTeamsData = temp.no_of_player_per_team
        this.perTeamPlyData = temp.no_of_teams
        this.gameTimeData = temp.game_time
        this.sessionTimeData = temp.session_duration
        this.selectedTheme = temp.theme_type
        this.handleTheameChange({ value: temp.theme_type })
        this.noOfRoundEmptyArray = []
        temp.rounds.forEach((ele: any) => {
          this.noOfRoundEmptyArray.push('')
        })
        this.roundData = temp.rounds
        for (let i = 0; i < this.roundData.length; i++) {
          switch (this.roundData[i].order_id) {
            case 1:
              this.roundData[i]['value'] = 'Simple1'
              break
            case 2:
              this.roundData[i]['value'] = 'Simple2'
              break
            case 4:
              this.roundData[i]['value'] = 'Advanced1'
              break
            case 5:
              this.roundData[i]['value'] = 'Advanced2'
              break
          }
        }
        this.roundData.forEach((ele: any, index: any) => {
          console.log(ele)

        })
        console.log(this.roundData)
        this.selectedOrder = 'simple'
        this.totalOrder = temp.rounds.length
      }
    }, (err: any) => {
      // this.toster.error(err.error.message)
    })
  }
  moveToNextGame(configId: any) {
    slotDeleteFlag = false
    localStorage.setItem('flag', 'false')
    if (this.dataShare.totalConfigDone.findIndex((ele: any) => ele == configId) == -1)
      this.dataShare.totalConfigDone.push(configId)
    let currentSelect = this.dataShare.currentSelect
    // if (this.dataShare.totalConfigDone.length == 1 && this.dataShare.totalConfigDone.length == this.dataShare.selectedGameForConfig.length) {
    //   this.router.navigate(['/scheduled'])
    //   this.dataShare.selectedGameForConfig = []
    //   this.dataShare.sessionData = {}
    //   this.dataShare.currentSelect = null
    //   this.dataShare.totalConfigDone = []
    //   this.dataShare.sessionTempEditData = {}
    //   this.dataShare.newSetup = true
    //   this.dataShare.lastGameConfigId = null
    // }
    // else 
    if (this.dataShare.totalConfigDone.length == this.dataShare.selectedGameForConfig.length) {
      const dialogRef = this.dialog.open(SessionCompleteDialog, {
        disableClose: true,
        minWidth: '350px',
        // minHeight: '185px'
      })
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          pageDestoryFlag = true
          localStorage.setItem('pageDestroy', 'true')
          this.router.navigate(['/scheduled'])
          this.dataShare.selectedGameForConfig = []
          this.dataShare.sessionData = {}
          this.dataShare.currentSelect = null
          this.dataShare.totalConfigDone = []
          this.dataShare.sessionTempEditData = {}
          this.dataShare.newSetup = true
          this.dataShare.lastGameConfigId = null
          this.dashboardService.editStep3 = null
        }
      })
    }
    else {
      const reqIndex = this.dataShare.selectedGameForConfig.findIndex((ele: any) => JSON.parse(ele)['gameId'] == currentSelect)
      if (reqIndex + 1 == this.dataShare.selectedGameForConfig.length) {
        let i = 0
        let flag = 1
        while (flag != -1) {
          flag = this.dataShare.totalConfigDone.findIndex((ele: any) => ele == JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId'])
          if (flag != -1)
            i++
        }
        this.dataShare.currentSelect = JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId']

      }
      else {
        let i = reqIndex + 1
        let flag = 1
        while (flag != -1) {
          flag = this.dataShare.totalConfigDone.findIndex((ele: any) => ele == JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId'])
          if (flag != -1) {
            if ((i + 1) == this.dataShare.selectedGameForConfig.length) {
              i = 0
            }
            else
              i++
          }
        }
        this.dataShare.currentSelect = JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId']
      }
      this.dataShare.lastGameConfigId = 3
    }
  }
  maxPlayerData: any = '7'
  noOfTeamsData: any = '7'
  perTeamPlyData: any = '1'
  gameTimeData: any = ''
  sessionTimeData: any = ''

  noOfPlayersTooltip: string = 'The max number of players who can log in on the game link. The link will not work once this limit is crossed. It cannot exceed 100.'
  playerInTeamsTooltip: string = 'The maximum number of players who can join one game room.. Beyond this number, anyone attempting to enter that game room will not be allowed.'
  noOfTeamsTooltip: string = 'The number of game rooms ( one room is one team ) that will be created in this session basis the number of players per team that is entered.'
  warehouseThemeTooltip: string = 'Choose from one of the themes provided for the store and items. Choose one that you think is closest to the business of this group or one that the group can better relate to.'
  noOfRoundsTooltip: string = 'Maximum of 7 rounds is possible. For each round- select the target order basis the level of difficulty.. Refer to the target order details provided to you in the description.'
  gameTimeTooltip: string = 'This is the time duration given to all teams to complete their allowed number of rounds in the game. It also includes the time for practice before round1.'
  maxplayers = 'The max number of players who can log in on the game link.  The link will not work once this limit is crossed.';
  noteams = ' The number of game boards/ that will be created in this session, where one board is one team.';
  noplayersteam = 'The maximum number of players who can join one game board. Beyond this number, anyone attempting to enter that game room will not be allowed.';
  gametime = 'This is the time duration given to all teams to complete their allowed number of rounds in the game. It also includes the time for practice. ';
  sessionduration = 'Once this time duration is lapsed this particular game expires for players and no resources will be accessible.  The game control room would have a countdown timer to warn the facilitator of the same. The facilitator can end the game at any time before the session duration ends. It is wise to give a time frame that represents the maximum duration possible for that session.';
  compTheameDtl: any[] = [
    {
      type: 'Handheld', items: [
        { src: '../../../assets/img/warehouse/mobilePhone.svg', name: 'Mobile' },
        { src: '../../../assets/img/warehouse/tab.svg', name: 'Tab', }
      ]
    },
    {
      type: 'Servers', items: [
        { src: '../../../assets/img/warehouse/server.svg', name: 'Server' }
      ]
    },
    {
      type: 'Personal Computers', items: [
        { src: '../../../assets/img/warehouse/computer.svg', name: 'Desktop' },
        { src: '../../../assets/img/warehouse/laptop.svg', name: 'Laptop' }
      ]
    },
  ]
  snackBeverageDtl: any[] = [
    {
      type: 'Fastfood', items: [
        { src: '../../../assets/img/warehouse/noodles.svg', name: 'Noodles' },
      ]
    },
    {
      type: 'Snacks', items: [
        { src: '../../../assets/img/warehouse/juce.svg', name: 'Soft Drinks' },
        { src: '../../../assets/img/warehouse/pop.svg', name: 'Popcorn' },
      ]
    },
    {
      type: 'Desserts', items: [
        { src: '../../../assets/img/warehouse/choco.svg', name: 'Chocolate' },
        { src: '../../../assets/img/warehouse/ice.svg', name: 'Ice-cream' }
      ]
    },
  ]
  grocerieDtl: any[] = [
    {
      type: 'Personal Care', items: [
        { src: '../../../assets/img/warehouse/soap.svg', name: 'Soap' },
        { src: '../../../assets/img/warehouse/shampoo.svg', name: 'Shampoo' }
      ]
    },
    {
      type: 'Staple food', items: [
        { src: '../../../assets/img/warehouse/wheat.svg', name: 'Wheat Bags' },
        { src: '../../../assets/img/warehouse/salt.svg', name: 'Salt Box' }
      ]
    },

    {
      type: 'Home Care', items: [
        { src: '../../../assets/img/warehouse/broom.svg', name: 'Broom' }
      ]
    },
  ]
  apparelsAccessoriesDtl: any[] = [
    {
      type: 'Clothing', items: [
        { src: '../../../assets/img/warehouse/shirt.svg', name: 'Shirt' },
        { src: '../../../assets/img/warehouse/dress.svg', name: 'Dress' },

      ]
    },
    {
      type: 'Accessories', items: [
        { src: '../../../assets/img/warehouse/belt.svg', name: 'Belt' }
      ]
    },
    {
      type: 'Footwears', items: [
        { src: '../../../assets/img/warehouse/shoe.svg', name: 'Shoes' },
        { src: '../../../assets/img/warehouse/socks.svg', name: 'Socks' }
      ]
    },
  ]
  vechiclesDtl: any[] = [
    {
      type: 'Trucks', items: [
        { src: '../../../assets/img/warehouse/truck.svg', name: 'Truck' },
      ]
    },
    {
      type: 'Two wheelers', items: [
        { src: '../../../assets/img/warehouse/bike.svg', name: 'Motorcycle' },
        { src: '../../../assets/img/warehouse/scooter.svg', name: 'Scooters' }
      ]
    },
    {
      type: 'Four wheelers', items: [
        { src: '../../../assets/img/warehouse/car.svg', name: 'Car' },
        { src: '../../../assets/img/warehouse/jeep.svg', name: 'Jeep' },
      ]
    },
  ]
  selectedTheme: any = 'Computer Hardware'
  selectedThemeGame: any[] = this.compTheameDtl
  handleTheameChange(value: any) {
    let val = value.value
    this.selectedTheme = val
    this.dropDwonFlag = false
    if (val == 'Computer Hardware')
      this.selectedThemeGame = this.compTheameDtl
    else if (val == 'Snacks & Beverages')
      this.selectedThemeGame = this.snackBeverageDtl
    else if (val == 'Groceries')
      this.selectedThemeGame = this.grocerieDtl
    else if (val == 'Apparels & Accessories')
      this.selectedThemeGame = this.apparelsAccessoriesDtl
    else if (val == 'Vehicles')
      this.selectedThemeGame = this.vechiclesDtl
  }
  totalOrder: any = '4'
  noOfRoundEmptyArray: any[] = ['', '', '', '']
  roundData: any[] = [
    { round: "Round 1", level: "Order 1", order: "Simple", order_id: "1", value: 'Simple1' },
    { round: "Round 2", level: "Order 2", order: "Simple", order_id: "2", value: 'Simple2' },
    { round: "Round 3", level: "Order 1", order: "Advanced", order_id: "4", value: 'Advanced1' },
    { round: "Round 4", level: "Order 2", order: "Advanced", order_id: "5", value: 'Advanced2' }
  ]
  handleNoOfRound(value: any) {
    const val = parseInt(value.value)
    if (val == this.noOfRoundEmptyArray.length) {

    }
    else if (val > this.noOfRoundEmptyArray.length) {
      let temp = val - this.noOfRoundEmptyArray.length
      for (let i = 0; i < temp; i++) {
        this.noOfRoundEmptyArray.push('')
        this.roundData.push({ round: `Round ${this.roundData.length + 1}`, level: "Order 1", order: "Simple", order_id: "1", value: 'Simple1' })
      }
    }
    else if (val < this.noOfRoundEmptyArray.length) {
      let temp = this.noOfRoundEmptyArray.length - val
      for (let i = 0; i < temp; i++) {
        this.noOfRoundEmptyArray.pop()
        this.roundData.pop()
      }
    }

  }
  handleLevelChange(value: any, index: any) {
    const val = value.value
    if (val == 'Advanced1') {
      this.roundData[index]['order'] = 'Advanced'
      this.roundData[index]['order_id'] = "4"
      // switch (this.roundData[index]['level']) {
      //   case "Order 1":
      //     this.roundData[index]['order_id'] = "4"
      //     break;
      //   case "Order 2":
      //     this.roundData[index]['order_id'] = "5"
      //     break;
      //   case "Order 3":
      //     this.roundData[index]['order_id'] = "6"
      //     break;

      // }
    }
    else if (val == 'Advanced2') {
      this.roundData[index]['order'] = 'Advanced'
      this.roundData[index]['order_id'] = "5"
    }
    else if (val == 'Simple1') {
      this.roundData[index]['order'] = 'Simple'
      this.roundData[index]['order_id'] = "1"
      // switch (this.roundData[index]['level']) {
      //   case "Order 1":
      //     this.roundData[index]['order_id'] = "1"
      //     break;
      //   case "Order 2":
      //     this.roundData[index]['order_id'] = "2"
      //     break;
      //   case "Order 3":
      //     this.roundData[index]['order_id'] = "3"
      //     break;

      // }
    }
    else if (val == 'Simple2') {
      this.roundData[index]['order'] = 'Simple'
      this.roundData[index]['order_id'] = "2"
    }
  }
  handelOrderChange(value: any, index: any) {
    const val = value.value
    if (val == "Order 1") {
      this.roundData[index]['level'] = 'Order 1'
      switch (this.roundData[index]['order']) {
        case 'Simple':
          this.roundData[index]['order_id'] = '1'
          break;
        case 'Advanced':
          this.roundData[index]['order_id'] = '4'
          break;
      }
    }
    else if (val == "Order 2") {
      this.roundData[index]['level'] = 'Order 2'
      switch (this.roundData[index]['order']) {
        case 'Simple':
          this.roundData[index]['order_id'] = '2'
          break;
        case 'Advanced':
          this.roundData[index]['order_id'] = '5'
          break;
      }
    }
    else if (val == "Order 3") {
      this.roundData[index]['level'] = 'Order 3'
      switch (this.roundData[index]['order']) {
        case 'Simple':
          this.roundData[index]['order_id'] = '3'
          break;
        case 'Advanced':
          this.roundData[index]['order_id'] = '6'
          break;
      }
    }

  }
  selectedOrder: string = 'simple'
  order(data: string) {
    this.selectedOrder = data
  }

  validateNumber(event: any): void {
    const keyCode = event.keyCode;
    // Allow only numbers and some special keys like arrow keys, delete, and backspace
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || [8, 37, 39, 46].includes(keyCode))) {
      event.preventDefault();
    }
  }
  hoverFlag: any = {
    com: false,
    snack: false,
    groceries: false,
    apparels: false,
    vechicle: false,
  }
  onHover(data: any) {
    console.log('call11')
    this.hoverFlag = {
      com: false,
      snack: false,
      groceries: false,
      apparels: false,
      vechicle: false,
    }
    if (data != 'removed') {
      console.log('call22')
      this.hoverFlag[data] = true
    }
  }
  dropDwonFlag: boolean = false
  toggle() {
    this.dropDwonFlag = !this.dropDwonFlag
  }





  saveWareHouseConfig() {
    if (this.maxPlayerData != '') {
      if (parseInt(this.maxPlayerData) <= 500) {
        if (this.noOfTeamsData != '') {
          if (parseInt(this.noOfTeamsData) >= 1 && parseInt(this.noOfTeamsData) < 11) {
            if (this.perTeamPlyData != '') {
              if (parseInt(this.perTeamPlyData) < 21) {
                if (this.gameTimeData != '' && this.gameTimeData) {
                  if (this.sessionTimeData != '') {
                    if (parseInt(this.sessionTimeData) > parseInt(this.gameTimeData)) {
                      let data = {
                        program_id: this.dataShare.sessionData['programId'],
                        game_id: 3,
                        max_player: this.maxPlayerData,
                        no_of_teams: this.perTeamPlyData,
                        no_of_player_per_team: this.noOfTeamsData,
                        game_time: this.gameTimeData,
                        session_duration: this.sessionTimeData,
                        theme_type: this.selectedTheme,
                        rounds: this.roundData
                      }
                      // const dialogRef = this.dialog.open(WHCommomDialogComponent, {
                      //   hasBackdrop: true,
                      //   disableClose: true,
                      //   data: {
                      //     slotDtl: this.dataShare.timeSlotData,
                      //     noOfPly: this.maxPlayerData,
                      //     noOfTeam: this.noOfTeamsData,
                      //     noOfOrder: this.roundData.length
                      //   }
                      // })
                      // dialogRef.afterClosed().subscribe((result: any) => {
                      //   if (result) {
                      this.httpSv.configWhGame(data).subscribe((res: any) => {
                        localStorage.setItem('flag', 'false')
                        const dialogRef = this.dialog.open(SuccessDialog, {
                          disableClose: true,
                          minWidth: '300px',
                          data: this.timerFlag
                          // minHeight: '300px'
                        })
                        dialogRef.afterClosed().subscribe((res: any) => {
                          if (res) {
                            this.moveToNextGame(3)
                          }
                        })
                      }, (err: any) => {
                        this.toster.error(err.error.message)
                      })
                      //   }
                      // })

                    }
                    else {
                      this.toster.error('Session time must be more than game time', '', { timeOut: 2000 })
                    }
                  }
                  else {
                    this.toster.error('Please fill the session time', '', { timeOut: 2000 })
                  }
                }
                else {
                  this.toster.error('Please fill the game time', '', { timeOut: 2000 })
                }
              }
              else {
                this.toster.error('Maximum number of teams is 20', '', { timeOut: 2000 })
              }
            }
            else {
              this.toster.error('Please fill the number of players per teams', '', { timeOut: 2000 })
            }
          } else {
            this.toster.error('Number of players in a teams must be between 1 & 10', '', { timeOut: 2000 })
          }
        }
        else {
          this.toster.error('Please fill the number of teams', '', { timeOut: 2000 })
        }
      }
      else {
        this.toster.error('Maximum player is 500 allowed', '', { timeOut: 2000 })
      }
    }
    else {
      this.toster.error('Please fill the maximum players', '', { timeOut: 2000 })
    }

  }
  toggleFlag: boolean = false
  tempCount: any = 0
  outSideClickHandle(event: any) {
    if (event) {
      if (this.toggleFlag) {
        if (this.tempCount == 1) {
          this.toggleFlag = false
          this.tempCount = 0
        }
        else
          this.tempCount = 1
      }
    }
  }
  tempCountTheame: any = 0
  outSideClickHandleTheame(event: any) {
    if (event) {
      if (this.dropDwonFlag) {
        this.dropDwonFlag = false
        // if (this.tempCountTheame == 1) {
        //   this.dropDwonFlag = false
        //   this.tempCountTheame = 0
        // }
        // else
        //   this.tempCountTheame = 1
      }
    }
  }
  goToAccountSetting() {
    this.toggleFlag = false
    this.router.navigate(['/accountSetting'])
  }
  goToMyGame() {
    this.dataShare.myGames = null
    this.router.navigate(['myGames'])
  }
  goGameReport(){
    this.dashboardService.archiveFlag = true
    this.router.navigate(['/scheduled'])
  }
  logout() {
    this.router.navigate(['/login'])
    // this.httpSv.logOut().subscribe((res: any) => {
    //   this.router.navigate(['/login'])
    // })
  }
  toggleFunc() {
    this.toggleFlag = !this.toggleFlag
    if (!this.toggleFlag)
      this.tempCount = 0
  }
}
//Animal Farm main ts
@Component({
  selector: 'app-new-animalfarm',
  templateUrl: './animalfarm-new.html',
  styleUrls: ['./animalfarm.css']
})
export class AnimalfarmNewGamesComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private toster: ToastrService,
    private httpSv: HttpService,
    private dataShare: DataSharingServiceService,
    private router: Router,
    private dashboardService: DashboardService
  ) { }
  disabledFlag: boolean = true
  timerFlag: boolean = true
  ngOnInit(): void {
    const role: any = localStorage.getItem('role')
    // if (role == 1) {
    //   this.disabledFlag = false
    //   this.maxPlayerData = ''
    //   this.noOfTeamsData = ''
    //   this.perTeamPlyData = ''
    // }
    if (this.dataShare.timeSlotData) {
      this.disabledFlag = false
      this.maxPlayerData = this.dataShare.timeSlotData.players
      this.noOfTeamsData = null
      this.perTeamPlyData = null
      this.sessionTimeData = this.dataShare.timeSlotData.timeslots.length * 60
    }
    this.steperFlag = this.dataShare.newSetup
    if (this.dataShare.sessionTempEditData['5'] == null && !this.steperFlag)
      this.fetchGameData()
    else if (this.dataShare.sessionTempEditData['5'] != null && !this.steperFlag) {
      this.timerFlag = false
    }
    if (this.dataShare.sessionTempEditData['5']) {
      const temp = this.dataShare.sessionTempEditData['5']
      this.maxPlayerData = temp.maxPlayerData
      this.noOfTeamsData = temp.noOfTeamsData
      this.perTeamPlyData = temp.perTeamPlyData
      this.gameTimeData = temp.gameTimeData
      this.sessionTimeData = temp.sessionTimeData
      this.noOfRoundEmptyArray = temp.noOfRoundEmptyArray
      this.roundData = temp.roundData
      this.selectedOrder = temp.selectedOrder,
        this.totalOrder = temp.totalOrder
    }
    console.log(this.dataShare)
    this.maxDurationBySlots = this.dataShare.timeSlotData.timeslots.length * 60
    this.timerEpochTime = this.dataShare.timeSlotData.timeLeft.timerEpochTime
    this.timerEpochTime = this.dataShare.timeSlotData.timeLeft.timerEpochTime
    clearInterval(intervalRef)
    intervalRef = setInterval(() => {
      if (this.remainingTime != 0) {
        if (this.remainingTime != 0) {
          this.updateRemainingTime();
        } else {
          console.log('11122233')
          clearInterval(intervalRef)
        }
      }
    }, 1000);
  }


  steperFlag: boolean = true
  maxDurationBySlots: any
  showSideOrder: boolean = false
  toggleFlag: boolean = false
  tempCount: any = 0
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.dataShare.sessionTempEditData['5'] = {
      maxPlayerData: this.maxPlayerData,
      noOfTeamsData: this.noOfTeamsData,
      perTeamPlyData: this.perTeamPlyData,
      gameTimeData: this.gameTimeData,
      sessionTimeData: this.sessionTimeData,
      noOfRoundEmptyArray: this.noOfRoundEmptyArray,
      roundData: this.roundData,
      selectedOrder: this.selectedOrder,
      totalOrder: this.totalOrder
    }
    clearInterval(intervalRef)
  }
  updateRemainingTime() {
    const currentTime = new Date().getTime();
    this.remainingTime = Math.max(0, Math.floor((this.timerEpochTime - currentTime) / 1000));
    if (this.remainingTime == 0) {
      pageDestoryFlag = true
      localStorage.setItem('pageDestroy', 'true')
      this.toster.error('Oops! Times Up', '', { timeOut: 2000 })
      clearInterval(intervalRef)
      if (localStorage.getItem('flag') != 'false') {
        this.router.navigate(['/configure'])
      } else { this.router.navigate(['/scheduled']) }
    }
  }
  get remainingMinutes(): number {
    if (this.remainingTime)
      return Math.floor(this.remainingTime / 60);
    else
      return 0
  }

  get remainingSeconds(): number {
    if (this.remainingTime)
      return this.remainingTime % 60;
    else
      return 0
  }
  timerEpochTime: any
  remainingTime: any
  toggleFunc() {
    this.toggleFlag = !this.toggleFlag
    if (!this.toggleFlag)
      this.tempCount = 0
  }
  fillNoPlyr() {
    this.noOfTeamsData = this.perTeamPlyData ? Math.ceil(this.maxPlayerData / this.perTeamPlyData) : 0
  }
  fetchGameData() {
    this.timerFlag = false
    this.httpSv.getAfGameDtls(this.dataShare.sessionData.programId).subscribe((res: any) => {
      if (res['status']) {
        this.timerFlag = false
        let temp = res['result'][0]
        this.maxPlayerData = temp.max_player
        this.noOfTeamsData = temp.no_of_teams
        this.perTeamPlyData = temp.no_of_player_per_team
        this.gameTimeData = temp.game_time
        this.sessionTimeData = temp.session_duration
        this.noOfRoundEmptyArray = []
        temp.rounds.forEach((ele: any) => {
          this.noOfRoundEmptyArray.push('')
        })
        this.roundData = temp.rounds
        this.roundData.forEach((ele: any) => {
          ele['value'] = ele.order_id
        })
        this.selectedOrder = 'simple'
        this.totalOrder = temp.rounds.length
      }
    }, (err: any) => {
      // this.toster.error(err.error.message)
    })
  }
  moveToNextGame(configId: any) {
    slotDeleteFlag = false
    localStorage.setItem('flag', 'false')
    if (this.dataShare.totalConfigDone.findIndex((ele: any) => ele == configId) == -1)
      this.dataShare.totalConfigDone.push(configId)
    let currentSelect = this.dataShare.currentSelect
    // if (this.dataShare.totalConfigDone.length == 1 && this.dataShare.totalConfigDone.length == this.dataShare.selectedGameForConfig.length) {
    //   this.router.navigate(['/scheduled'])
    //   this.dataShare.selectedGameForConfig = []
    //   this.dataShare.sessionData = {}
    //   this.dataShare.currentSelect = null
    //   this.dataShare.totalConfigDone = []
    //   this.dataShare.sessionTempEditData = {}
    //   this.dataShare.newSetup = true
    //   this.dataShare.lastGameConfigId = null
    // }
    // else 
    if (this.dataShare.totalConfigDone.length == this.dataShare.selectedGameForConfig.length) {
      const dialogRef = this.dialog.open(SessionCompleteDialog, {
        disableClose: true,
        minWidth: '350px',
        // minHeight: '185px'
      })
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          pageDestoryFlag = true
          localStorage.setItem('pageDestroy', 'true')
          this.router.navigate(['/scheduled'])
          this.dataShare.selectedGameForConfig = []
          this.dataShare.sessionData = {}
          this.dataShare.currentSelect = null
          this.dataShare.totalConfigDone = []
          this.dataShare.sessionTempEditData = {}
          this.dataShare.newSetup = true
          this.dataShare.lastGameConfigId = null
          this.dashboardService.editStep3 = null
        }
      })

    }
    else {
      const reqIndex = this.dataShare.selectedGameForConfig.findIndex((ele: any) => JSON.parse(ele)['gameId'] == currentSelect)
      if (reqIndex + 1 == this.dataShare.selectedGameForConfig.length) {
        let i = 0
        let flag = 1
        while (flag != -1) {
          flag = this.dataShare.totalConfigDone.findIndex((ele: any) => ele == JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId'])
          if (flag != -1)
            i++
        }
        this.dataShare.currentSelect = JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId']

      }
      else {
        let i = reqIndex + 1
        let flag = 1
        while (flag != -1) {
          flag = this.dataShare.totalConfigDone.findIndex((ele: any) => ele == JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId'])
          if (flag != -1) {
            if ((i + 1) == this.dataShare.selectedGameForConfig.length) {
              i = 0
            }
            else
              i++
          }
        }
        this.dataShare.currentSelect = JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId']
        this.dataShare.lastGameConfigId = 5
      }
    }
  }
  outSideClickHandle(event: any) {
    if (event) {
      if (this.toggleFlag) {
        if (this.tempCount == 1) {
          this.toggleFlag = false
          this.tempCount = 0
        }
        else
          this.tempCount = 1
      }
    }
  }
  tempCountTheame: any = 0
  dropDwonFlag: boolean = false
  goToAccountSetting() {
    this.toggleFlag = false
    this.router.navigate(['/accountSetting'])
  }
  goToMyGame() {
    this.dataShare.myGames = null
    this.router.navigate(['myGames'])
  }
  goGameReport(){
    this.dashboardService.archiveFlag = true
    this.router.navigate(['/scheduled'])
  }
  logout() {
    this.router.navigate(['/login'])
    // this.httpSv.logOut().subscribe((res: any) => {
    //   this.router.navigate(['/login'])
    // })
  }
  maxPlayerData: any = '7'
  noOfTeamsData: any = '1'
  perTeamPlyData: any = '7'
  gameTimeData: any = null
  sessionTimeData: any = null


  // maxplayers = 'The max number of players who can log in on the game link.  The link will not work once this limit is crossed.';
  // noteams = ' The number of game boards/ that will be created in this session, where one board is one team.';
  // noplayersteam = 'The maximum number of players who can join one game board. Beyond this number, anyone attempting to enter that game room will not be allowed.';
  // gametime = 'This is the time duration given to all teams to complete their allowed number of rounds in the game. It also includes the time for practice. ';
  // sessionduration = 'Once this time duration is lapsed this particular game expires for players and no resources will be accessible.  The game control room would have a countdown timer to warn the facilitator of the same. The facilitator can end the game at any time before the session duration ends. It is wise to give a time frame that represents the maximum duration possible for that session.';


  noOfPlayersTooltip: string = 'The max number of players who can log in on the game link. The link will not work once this limit is crossed. It cannot exceed 100.'
  playerInTeamsTooltip: string = 'The maximum number of players who can join one game room.. Beyond this number, anyone attempting to enter that game room will not be allowed.'
  noOfTeamsTooltip: string = 'The number of game rooms ( one room is one team ) that will be created in this session basis the number of players per team that is entered.'
  noOfRoundsTooltip: string = 'Maximum of 7 rounds is possible. For each round- select the target order basis the level of difficulty.. Refer to the target order details provided to you in the description.'
  gameTimeTooltip: string = 'This is the time duration given to all teams to complete their allowed number of rounds in the game. It also includes the time for practice before round1.'
  maxplayers = 'The max number of players who can log in on the game link.  The link will not work once this limit is crossed.';
  noteams = ' The number of game boards/ that will be created in this session, where one board is one team.';
  noplayersteam = 'The maximum number of players who can join one game board. Beyond this number, anyone attempting to enter that game room will not be allowed.';
  gametime = 'This is the time duration given to all teams to complete their allowed number of rounds in the game. It also includes the time for practice. ';
  sessionduration = 'Once this time duration is lapsed this particular game expires for players and no resources will be accessible.  The game control room would have a countdown timer to warn the facilitator of the same. The facilitator can end the game at any time before the session duration ends. It is wise to give a time frame that represents the maximum duration possible for that session.';







  totalOrder: any = '4'
  noOfRoundEmptyArray: any[] = ['', '', '', '']
  roundData: any[] = [
    { round: "Round 1", level: "Order 1", order: "Simple", order_id: "1", time: null, value: '1' },
    { round: "Round 2", level: "Order 2", order: "Simple", order_id: "2", time: null, value: '2' },
    { round: "Round 3", level: "Order 2", order: "Simple", order_id: "2", time: null, value: '2' },
    { round: "Round 4", level: "Order 3", order: "Simple", order_id: "3", time: null, value: '3' }
  ]


  selectedOrder: string = 'simple'

  validateNumber(event: any): void {
    const keyCode = event.keyCode;
    // Allow only numbers and some special keys like arrow keys, delete, and backspace
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || [8, 37, 39, 46].includes(keyCode))) {
      event.preventDefault();
    }
  }
  hoverFlag: any = {
    com: false,
    snack: false,
    groceries: false,
    apparels: false,
    vechicle: false,
  }
  onHover(data: any) {
    console.log('call11')
    this.hoverFlag = {
      com: false,
      snack: false,
      groceries: false,
      apparels: false,
      vechicle: false,
    }
    if (data != 'removed') {
      console.log('call22')
      this.hoverFlag[data] = true
    }
  }


  handleNoOfRound(value: any) {
    const val = parseInt(value.value)
    if (val == this.noOfRoundEmptyArray.length) {

    }
    else if (val > this.noOfRoundEmptyArray.length) {
      let temp = val - this.noOfRoundEmptyArray.length
      for (let i = 0; i < temp; i++) {
        this.noOfRoundEmptyArray.push('')
        this.roundData.push({ round: `Round ${this.roundData.length + 1}`, level: "Order 1", order: "Simple", order_id: "1", time: null })
      }
    }
    else if (val < this.noOfRoundEmptyArray.length) {
      let temp = this.noOfRoundEmptyArray.length - val
      for (let i = 0; i < temp; i++) {
        this.noOfRoundEmptyArray.pop()
        this.roundData.pop()
      }
    }

  }
  handleLevelChange(value: any, index: any) {
    const val = value.value
    switch (val) {
      case '1':
        this.roundData[index]['order_id'] = "1"
        this.roundData[index]['level'] = "Order 1"
        this.roundData[index]['order'] = "Simple"
        this.roundData[index]['value'] = "1"
        break
      case '2':
        this.roundData[index]['order_id'] = "2"
        this.roundData[index]['level'] = "Order 2"
        this.roundData[index]['order'] = "Simple"
        this.roundData[index]['value'] = "2"
        break
      case '3':
        this.roundData[index]['order_id'] = "3"
        this.roundData[index]['level'] = "Order 3"
        this.roundData[index]['order'] = "Simple"
        this.roundData[index]['value'] = "3"
        break
      case '4':
        this.roundData[index]['order_id'] = "4"
        this.roundData[index]['level'] = "Order 4"
        this.roundData[index]['order'] = "Advanced"
        this.roundData[index]['value'] = "4"
        break
      case '5':
        this.roundData[index]['order_id'] = "5"
        this.roundData[index]['level'] = "Order 5"
        this.roundData[index]['order'] = "Advanced"
        this.roundData[index]['value'] = "5"
        break
      case '6':
        this.roundData[index]['order_id'] = "6"
        this.roundData[index]['level'] = "Order 6"
        this.roundData[index]['order'] = "Advanced"
        this.roundData[index]['value'] = "6"
        break
    }
    // if (val == 'Advanced') {
    //   this.roundData[index]['order'] = 'Advanced'
    //   switch (this.roundData[index]['level']) {
    //     case "Order 1":
    //       this.roundData[index]['order_id'] = "4"
    //       break;
    //     case "Order 2":
    //       this.roundData[index]['order_id'] = "5"
    //       break;
    //     case "Order 3":
    //       this.roundData[index]['order_id'] = "6"
    //       break;

    //   }
    // }
    // else if (val == 'Simple') {
    //   this.roundData[index]['order'] = 'Simple'
    //   switch (this.roundData[index]['level']) {
    //     case "Order 1":
    //       this.roundData[index]['order_id'] = "1"
    //       break;
    //     case "Order 2":
    //       this.roundData[index]['order_id'] = "2"
    //       break;
    //     case "Order 3":
    //       this.roundData[index]['order_id'] = "3"
    //       break;

    //   }
    // }
  }
  handelOrderChange(value: any, index: any) {
    const val = value.value
    if (val == "Order 1") {
      this.roundData[index]['level'] = 'Order 1'
      switch (this.roundData[index]['order']) {
        case 'Simple':
          this.roundData[index]['order_id'] = '1'
          break;
        case 'Advanced':
          this.roundData[index]['order_id'] = '4'
          break;
      }
    }
    else if (val == "Order 2") {
      this.roundData[index]['level'] = 'Order 2'
      switch (this.roundData[index]['order']) {
        case 'Simple':
          this.roundData[index]['order_id'] = '2'
          break;
        case 'Advanced':
          this.roundData[index]['order_id'] = '5'
          break;
      }
    }
    else if (val == "Order 3") {
      this.roundData[index]['level'] = 'Order 3'
      switch (this.roundData[index]['order']) {
        case 'Simple':
          this.roundData[index]['order_id'] = '3'
          break;
        case 'Advanced':
          this.roundData[index]['order_id'] = '6'
          break;
      }
    }
  }
  handleRoundTime(value: any, index: any) {
    const val = value.value
    this.roundData[index]['time'] = val
  }
  order(data: string) {
    this.selectedOrder = data
  }
  orderError: any = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false
  }
  timeError: any = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false
  }
  roundValidation() {
    let flag = true
    try {
      this.roundData.forEach((ele: any, index: any) => {
        if (ele['value'] == null) {
          this.orderError[index] = true
          setTimeout(() => {
            this.orderError[index] = false
          }, 2000)
          flag = false
          throw new Error('Break from loop')
        }
        if (ele['time'] == null) {
          flag = false
          this.timeError[index] = true
          setTimeout(() => {
            this.timeError[index] = false
          }, 2000)
          throw new Error('Break from loop')
        }
      })
    } catch (error) {

    }
    if (flag)
      return true
    else return false
  }
  saveAnimalFarmConfig() {

    if (this.perTeamPlyData == null) {
      this.toster.error('Please fill the number of teams', '', { timeOut: 2000 })
      return
    }
    if (this.gameTimeData == null) {
      this.toster.error('Please fill the game time', '', { timeOut: 2000 })
      return
    }
    if (this.perTeamPlyData > 20 || (this.perTeamPlyData < 2 && this.perTeamPlyData != null) || this.noOfTeamsData > 20 || this.gameTimeData >= this.maxDurationBySlots || !this.roundValidation()) {
      return
    }
    let data = {
      program_id: this.dataShare.sessionData['programId'],
      game_id: 5,
      max_player: this.maxPlayerData,
      no_of_teams: this.noOfTeamsData,
      no_of_player_per_team: this.perTeamPlyData,
      game_time: this.gameTimeData,
      session_duration: this.sessionTimeData,
      rounds: this.roundData
    }
    console.log(data)
    this.httpSv.configAfGame(data).subscribe((res: any) => {
      localStorage.setItem('flag', 'false')
      const dialogRef = this.dialog.open(SuccessDialog, {
        disableClose: true,
        minWidth: '300px',
        data: this.timerFlag
        // minHeight: '300px'
      })
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          this.moveToNextGame(5)
        }
      })
    }, (err: any) => {
      this.toster.error(err.error.message)
    })

  }

  // saveAnimalFarmConfig() {
  //   if (this.maxPlayerData != '') {
  //     if (parseInt(this.maxPlayerData) <= 500) {
  //       if (this.noOfTeamsData != '') {
  //         if (parseInt(this.perTeamPlyData) >= 1 && parseInt(this.perTeamPlyData) < 11) {
  //           if (this.perTeamPlyData != '') {
  //             if (parseInt(this.noOfTeamsData) > 1 && parseInt(this.noOfTeamsData) < 21) {
  //               if (this.gameTimeData != '' && this.gameTimeData) {
  //                 if (this.sessionTimeData != '') {
  //                   if (parseInt(this.sessionTimeData) > parseInt(this.gameTimeData)) {
  //                     if (this.roundValidation()) {
  //                       let data = {
  //                         program_id: this.dataShare.sessionData['programId'],
  //                         game_id: 5,
  //                         max_player: this.maxPlayerData,
  //                         no_of_teams: this.noOfTeamsData,
  //                         no_of_player_per_team: this.perTeamPlyData,
  //                         game_time: this.gameTimeData,
  //                         session_duration: this.sessionTimeData,
  //                         rounds: this.roundData
  //                       }
  //                       console.log(data)
  //                       this.httpSv.configAfGame(data).subscribe((res: any) => {
  //                         localStorage.setItem('flag', 'false')
  //                         const dialogRef = this.dialog.open(SuccessDialog, {
  //                           disableClose: true,
  //                           minWidth: '300px',
  //                           data: this.timerFlag
  //                           // minHeight: '300px'
  //                         })
  //                         dialogRef.afterClosed().subscribe((res: any) => {
  //                           if (res) {
  //                             this.moveToNextGame(5)
  //                           }
  //                         })
  //                       }, (err: any) => {
  //                         this.toster.error(err.error.message)
  //                       })
  //                     }
  //                   }
  //                   else {
  //                     this.toster.error('Session time must be more than game time', '', { timeOut: 2000 })
  //                   }
  //                 }
  //                 else {
  //                   this.toster.error('Please fill the session time', '', { timeOut: 2000 })
  //                 }
  //               }
  //               else {
  //                 this.toster.error('Please fill the game time', '', { timeOut: 2000 })
  //               }
  //             }
  //             else {
  //               this.toster.error('Number of players per teams must be in between 2 & 20', '', { timeOut: 2000 })
  //             }
  //           }
  //           else {
  //             this.toster.error('Please fill the number of players per teams', '', { timeOut: 2000 })
  //           }
  //         }
  //         else {
  //           this.toster.error('Number of teams must be in between 1 & 20', '', { timeOut: 2000 })
  //         }
  //       }
  //       else {
  //         this.toster.error('Please fill the number of teams', '', { timeOut: 2000 })
  //       }
  //     }
  //     else {
  //       this.toster.error('Maximum players is allowed till 500 ', '', { timeOut: 2000 })
  //     }
  //   }
  //   else {
  //     this.toster.error('Please fill the maximum players', '', { timeOut: 2000 })
  //   }

  // }

}
//Bottleneck main ts
@Component({
  selector: 'app-new-bottleneck',
  templateUrl: './bottleneck-new.html',
  styleUrls: ['./bottleneck.css']
})
export class BottleneckNewGamesComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private toster: ToastrService,
    private httpSv: HttpService,
    private dataShare: DataSharingServiceService,
    private router: Router,
    private dashboardService: DashboardService
  ) { }
  disabledFlag: boolean = true
  timerFlag: boolean = true
  ngOnInit(): void {
    const role: any = localStorage.getItem('role')
    if (role == 1) {
      this.disabledFlag = false
      this.maxPlayerData = ''
      this.noOfTeamsData = ''
      this.maxTeamPlyData = ''
    }
    if (this.dataShare.timeSlotData) {
      this.disabledFlag = false
      this.maxPlayerData = this.dataShare.timeSlotData.players
      this.noOfTeamsData = '0'
      // this.perTeamPlyData = '0'
      this.sessionTimeData = this.dataShare.timeSlotData.timeslots.length * 60
    }
    this.steperFlag = this.dataShare.newSetup
    if (this.dataShare.sessionTempEditData['2'] == null && !this.steperFlag) {
      this.fetchGameData()
    }
    else if (this.dataShare.sessionTempEditData['2'] != null && !this.steperFlag) {
      this.timerFlag = false
    }
    else {
      this.timerEpochTime = this.dataShare.timeSlotData.timeLeft.timerEpochTime
      clearInterval(intervalRef)
      intervalRef = setInterval(() => {
        if (this.remainingTime != 0) {
          this.updateRemainingTime();
        } else {
          console.log('11122233')
          clearInterval(intervalRef)
        }
      }, 1000);
    }
    if (this.dataShare.sessionTempEditData['2']) {
      const temp = this.dataShare.sessionTempEditData['2']
      this.maxPlayerData = temp.maxPlayerData,
        this.noOfTeamsData = temp.noOfTeamsData,
        this.maxTeamPlyData = temp.maxTeamPlyData,
        this.sessionTimeData = temp.sessionTimeData,
        this.selectedLiner = temp.selectedLiner
    }
  }


  steperFlag: boolean = true
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.dataShare.sessionTempEditData['2'] = {
      maxPlayerData: this.maxPlayerData,
      noOfTeamsData: this.noOfTeamsData,
      maxTeamPlyData: this.maxTeamPlyData,
      sessionTimeData: this.sessionTimeData,
      selectedLiner: this.selectedLiner
    }
    clearInterval(intervalRef)
  }
  fetchGameData() {
    this.timerFlag = false
    this.httpSv.getBnGameDtls(this.dataShare.sessionData.programId).subscribe((res: any) => {
      if (res['status']) {
        let temp = res['result'][0]
        this.maxPlayerData = temp.max_player
        this.noOfTeamsData = temp.no_of_teams
        this.maxTeamPlyData = temp.max_player_per_team
        this.sessionTimeData = temp.session_duration
        this.selectedLiner = temp.board_type
        this.timerFlag = false
      }
    }, (err: any) => {
      // this.toster.error(err.error.message)
    })
  }
  updateRemainingTime() {
    const currentTime = new Date().getTime();
    this.remainingTime = Math.max(0, Math.floor((this.timerEpochTime - currentTime) / 1000));
    if (this.remainingTime == 0) {
      console.log(intervalRef)
      this.toster.error('Oops! Times Up', '', { timeOut: 2000 })
      clearInterval(intervalRef)
      pageDestoryFlag = true
      localStorage.setItem('pageDestroy', 'true')
      this.dashboardService.editStep3 = null
      this.dashboardService.editStepFalg = null
      this.dataShare.selectedGameForConfig = []
      this.dataShare.sessionData = {}
      this.dataShare.currentSelect = null
      this.dataShare.totalConfigDone = []
      this.dataShare.sessionTempEditData = {}
      this.dataShare.newSetup = true
      this.dataShare.lastGameConfigId = null
      if (localStorage.getItem('flag') != 'false') {
        this.router.navigate(['/configure'])
      } else { this.router.navigate(['/scheduled']) }
    }
  }
  get remainingMinutes(): number {
    if (this.remainingTime)
      return Math.floor(this.remainingTime / 60);
    else
      return 0
  }

  get remainingSeconds(): number {
    if (this.remainingTime)
      return this.remainingTime % 60;
    else
      return 0
  }
  timerEpochTime: any
  remainingTime: any
  moveToNextGame(configId: any) {
    slotDeleteFlag = false
    localStorage.setItem('flag', 'false')
    if (this.dataShare.totalConfigDone.findIndex((ele: any) => ele == configId) == -1)
      this.dataShare.totalConfigDone.push(configId)
    let currentSelect = this.dataShare.currentSelect
    // if (this.dataShare.totalConfigDone.length == 1 && this.dataShare.totalConfigDone.length == this.dataShare.selectedGameForConfig.length) {
    //   this.router.navigate(['/scheduled'])
    //   this.dataShare.selectedGameForConfig = []
    //   this.dataShare.sessionData = {}
    //   this.dataShare.currentSelect = null
    //   this.dataShare.totalConfigDone = []
    //   this.dataShare.sessionTempEditData = {}
    //   this.dataShare.newSetup = true
    //   this.dataShare.lastGameConfigId = null
    // }
    // else 
    if (this.dataShare.totalConfigDone.length == this.dataShare.selectedGameForConfig.length) {
      const dialogRef = this.dialog.open(SessionCompleteDialog, {
        disableClose: true,
        minWidth: '350px',
        // minHeight: '185px'
      })
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          pageDestoryFlag = true
          localStorage.setItem('pageDestroy', 'true')
          this.router.navigate(['/scheduled'])
          this.dataShare.selectedGameForConfig = []
          this.dataShare.sessionData = {}
          this.dataShare.currentSelect = null
          this.dataShare.totalConfigDone = []
          this.dataShare.sessionTempEditData = {}
          this.dataShare.newSetup = true
          this.dataShare.lastGameConfigId = null
          this.dashboardService.editStep3 = null
        }
      })
    }
    else {
      const reqIndex = this.dataShare.selectedGameForConfig.findIndex((ele: any) => JSON.parse(ele)['gameId'] == currentSelect)
      if (reqIndex + 1 == this.dataShare.selectedGameForConfig.length) {
        let i = 0
        let flag = 1
        while (flag != -1) {
          flag = this.dataShare.totalConfigDone.findIndex((ele: any) => ele == JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId'])
          if (flag != -1)
            i++
        }
        this.dataShare.currentSelect = JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId']

      }
      else {
        let i = reqIndex + 1
        let flag = 1
        while (flag != -1) {
          flag = this.dataShare.totalConfigDone.findIndex((ele: any) => ele == JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId'])
          if (flag != -1) {
            if ((i + 1) == this.dataShare.selectedGameForConfig.length) {
              i = 0
            }
            else
              i++
          }
        }
        this.dataShare.currentSelect = JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId']
      }
      this.dataShare.lastGameConfigId = 2
    }
  }
  maxPlayerData: any = '7'
  noOfTeamsData: any = '1'
  maxTeamPlyData: any = '7'
  sessionTimeData: any = ''

  gameduration = 'Once this time duration is lapsed this particular game expires for players and no resources will be accessible. The facilitator can end the game at any time. The game control room would have a countdown timer to warn the facilitator of the same.';
  maxplayers = ' The max number of players who can log in on the game link.  The link will not work once   this limit is crossed.';
  noteams = 'The number of game boards/ that will be created in this session, where one board is one team.';
  maxteamplayers = 'The maximum number of players who can join one game board. Beyond this number, anyone attempting to enter that game room will not be allowed.';

  selectedLiner: any = ''
  linerSelect(type: any) {
    this.selectedLiner = type
    if (type == '4 Linear') {
      this.maxTeamPlyData = 8
      this.noOfTeamsData = Math.ceil(this.maxPlayerData / this.maxTeamPlyData)
    } else if (type == '5 Linear') {
      this.maxTeamPlyData = 10
      this.noOfTeamsData = Math.ceil(this.maxPlayerData / this.maxTeamPlyData)
    }
  }
  validateLiner() {
    if (parseInt(this.maxTeamPlyData) < 2) {
      this.toster.error('Minimum player per team is 2', '', { timeOut: 2000 })
      return false
    }
    else if (this.selectedLiner == '4 Linear' && parseInt(this.maxTeamPlyData) > 8) {
      this.toster.error('4 Linear can maximum have 8 player per team', '', { timeOut: 2000 })
      return false
    }
    else if (this.selectedLiner == '5 Linear' && parseInt(this.maxTeamPlyData) > 10) {
      this.toster.error('5 Linear can maximum have 10 player per team', '', { timeOut: 2000 })
      return false
    }
    return true
  }
  saveBottleNeckConfig() {
    if (this.selectedLiner) {
      if (this.maxPlayerData != '') {
        if (parseInt(this.maxPlayerData) > 1) {
          if (this.noOfTeamsData != '') {
            if (parseInt(this.noOfTeamsData) >= 1 && parseInt(this.noOfTeamsData) < 21) {
              if (this.maxTeamPlyData != '') {
                if (this.sessionTimeData != '') {
                  if (this.selectedLiner) {
                    if (this.validateLiner()) {
                      let data = {
                        program_id: this.dataShare.sessionData['programId'],
                        game_id: 2,
                        max_player: this.maxPlayerData,
                        no_of_teams: this.noOfTeamsData,
                        max_player_per_team: this.maxTeamPlyData,
                        session_duration: this.sessionTimeData,
                        board_type: this.selectedLiner
                      }
                      this.httpSv.configBnGame(data).subscribe((res: any) => {
                        localStorage.setItem('flag', 'false')
                        const dialogRef = this.dialog.open(SuccessDialog, {
                          disableClose: true,
                          minWidth: '300px',
                          // minHeight: '300px'
                        })
                        dialogRef.afterClosed().subscribe((res: any) => {
                          if (res) {
                            this.moveToNextGame(2)
                          }
                        })
                      }, (err: any) => {
                        this.toster.error(err.error.message)
                      })
                    }
                  }
                  else {
                    this.toster.error('Please select any cross', '', { timeOut: 2000 })
                  }
                }
                else {
                  this.toster.error('Please fill the session time', '', { timeOut: 2000 })
                }
              }
              else {
                this.toster.error('Please fill the number of players per teams', '', { timeOut: 2000 })
              }
            }
            else {
              this.toster.error('Number of teams must be between 1 & 20', '', { timeOut: 2000 })
            }
          }
          else {
            this.toster.error('Please fill the number of teams', '', { timeOut: 2000 })
          }
        }
        else {
          this.toster.error('Maximum player must be more than one player', '', { timeOut: 2000 })
        }
      }
      else {
        this.toster.error('Please fill the maximum players', '', { timeOut: 2000 })
      }
    } else {
      this.crossErrorFlag = true
      setTimeout(() => {
        this.crossErrorFlag = false
      })
    }
  }
  crossErrorFlag: boolean = false
  toggleFlag: boolean = false
  tempCount: any = 0
  outSideClickHandle(event: any) {
    if (event) {
      if (this.toggleFlag) {
        if (this.tempCount == 1) {
          this.toggleFlag = false
          this.tempCount = 0
        }
        else
          this.tempCount = 1
      }
    }
  }
  goToAccountSetting() {
    this.toggleFlag = false
    this.router.navigate(['/accountSetting'])
  }
  goToMyGame() {
    this.dataShare.myGames = null
    this.router.navigate(['myGames'])
  }
  goGameReport(){
    this.dashboardService.archiveFlag = true
    this.router.navigate(['/scheduled'])
  }
  logout() {
    this.router.navigate(['/login'])
    // this.httpSv.logOut().subscribe((res: any) => {
    //   this.router.navigate(['/login'])
    // })
  }
  toggleFunc() {
    this.toggleFlag = !this.toggleFlag
    if (!this.toggleFlag)
      this.tempCount = 0
  }
}


//Big-Picture main ts
@Component({
  selector: 'app-new-bigpicture',
  templateUrl: './big-picture-new.html',
  styleUrls: ['./big-picture.css']
})
export class BigPictureNewGamesComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private toster: ToastrService,
    private httpSv: HttpService,
    private dataShare: DataSharingServiceService,
    private router: Router,
    private dashboardService: DashboardService
  ) { }
  timerFlag: boolean = true
  ngOnInit(): void {
    this.steperFlag = this.dataShare.newSetup
    if (this.dataShare.sessionTempEditData['6'] == null && !this.steperFlag) {
      this.fetchGameData()
    }
    if (this.dataShare.sessionTempEditData['6']) {
      const temp = this.dataShare.sessionTempEditData['6']
      this.previewSrc = temp.previewSrc
      this.uploadedSrc = temp.uploadedSrc
      this.imgName = temp.imgName
      this.imgFileData = temp.imgFileData
      this.rows = temp.rows
      this.coloum = temp.coloum
      this.totalPiece = temp.totalPiece
      this.noOfGameCode = temp.noOfGameCode
      // this.selectedLiner = temp.selectedLiner
    } else {
      this.timerEpochTime = this.dataShare.timeSlotData.timeLeft.timerEpochTime
      this.timerEpochTime = this.dataShare.timeSlotData.timeLeft.timerEpochTime
      clearInterval(intervalRef)
      intervalRef = setInterval(() => {
        if (this.remainingTime != 0) {
          this.updateRemainingTime();
        } else {
          console.log('11122233')
          clearInterval(intervalRef)
        }
      }, 1000);
    }
  }
  updateRemainingTime() {
    const currentTime = new Date().getTime();
    this.remainingTime = Math.max(0, Math.floor((this.timerEpochTime - currentTime) / 1000));
    if (this.remainingTime == 0) {
      this.toster.error('Oops! Times Up', '', { timeOut: 2000 })
      pageDestoryFlag = true
      localStorage.setItem('pageDestroy', 'true')
      clearInterval(intervalRef)
      if (localStorage.getItem('flag') != 'false') {
        this.router.navigate(['/configure'])
      } else { this.router.navigate(['/scheduled']) }
    }
  }
  get remainingMinutes(): number {
    if (this.remainingTime)
      return Math.floor(this.remainingTime / 60);
    else
      return 0
  }

  get remainingSeconds(): number {
    if (this.remainingTime)
      return this.remainingTime % 60;
    else
      return 0
  }
  timerEpochTime: any
  remainingTime: any
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.dataShare.sessionTempEditData['6'] = {
      previewSrc: this.previewSrc,
      uploadedSrc: this.uploadedSrc,
      imgName: this.imgName,
      imgFileData: this.imgFileData,
      rows: this.rows,
      coloum: this.coloum,
      totalPiece: this.totalPiece,
      noOfGameCode: this.noOfGameCode,
    }
    clearInterval(intervalRef)
  }
  fetchGameData() {
    this.timerFlag = false
    this.httpSv.getBpGameDtls(this.dataShare.sessionData.programId).subscribe((res: any) => {
      if (res['status']) {
        let temp = res['result'][0]
        this.previewSrc = temp.image
        // this.uploadedSrc = temp.image
        // this.imgName = temp.imgName
        // this.imgFileData = temp.imgFileData
        this.rows = temp.rows
        this.coloum = temp.columns
        this.totalPiece = parseInt(temp.rows) * parseInt(temp.columns)
        this.noOfGameCode = temp.no_of_codes
        this.timerFlag = false
      }
    }, (err: any) => {
      // this.toster.error(err.error.message)
    })
  }
  steperFlag: boolean = true
  previewSrc: any = null
  uploadedSrc: any
  imgName: any = null
  imgFileData: any
  onImageChange(evt: any) {
    let width
    let height
    let image: any = evt.target.files[0];
    this.imgFileData = evt.target.files[0];
    const fsize = evt.target.files[0].size;
    const fileSize = Math.round((fsize / 1024));
    const name = evt.target.files[0].name;
    if (fileSize > 8192) {
      this.toster.error('Sorry image size is too big', '', { timeOut: 2000 })
      return
    }
    let fr = new FileReader;
    fr.onload = () => { // when file has loaded
      var img: any = new Image();

      img.onload = () => {
        width = img.width;
        height = img.height;
        if ((width / height) > 1.777777 && (width / height) < 1.82) {
          this.uploadedSrc = img.src
          this.imgName = name
          this.previewSrc = null
        }
        else {
          this.toster.error('Image ratio is not in 16:9', '', { timeOut: 2000 })
        }

      };

      img.src = fr.result; // This is the data URL 
    };

    fr.readAsDataURL(image);
    (<HTMLInputElement>document.getElementById('hiddenImgInput')).value = ""

    // imgType.nativeElement.value = "";
  }
  uploadedFlag: boolean = false
  upload() {
    if (this.uploadedSrc) {
      this.previewSrc = this.uploadedSrc
      this.uploadedFlag = true
    }
    else
      this.toster.error('Please upload a file first')
  }


  rows: any = ''
  coloum: any = ''
  totalPiece: any = ''
  noOfGameCode: any = ''
  handleRowColoumChange() {
    if (this.rows != '' && this.coloum != '') {
      this.totalPiece = parseInt(this.rows) * parseInt(this.coloum)
    }
  }
  moveToNextGame(configId: any) {
    slotDeleteFlag = false
    localStorage.setItem('flag', 'false')
    if (this.dataShare.totalConfigDone.findIndex((ele: any) => ele == configId) == -1)
      this.dataShare.totalConfigDone.push(configId)
    let currentSelect = this.dataShare.currentSelect
    // if (this.dataShare.totalConfigDone.length == 1 && this.dataShare.totalConfigDone.length == this.dataShare.selectedGameForConfig.length) {
    //   this.router.navigate(['/scheduled'])
    //   this.dataShare.selectedGameForConfig = []
    //   this.dataShare.sessionData = {}
    //   this.dataShare.currentSelect = null
    //   this.dataShare.totalConfigDone = []
    //   this.dataShare.sessionTempEditData = {}
    //   this.dataShare.newSetup = true
    //   this.dataShare.lastGameConfigId = null
    // }
    // else 
    if (this.dataShare.totalConfigDone.length == this.dataShare.selectedGameForConfig.length) {
      const dialogRef = this.dialog.open(SessionCompleteDialog, {
        disableClose: true,
        minWidth: '350px',
        // minHeight: '185px'
      })
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          pageDestoryFlag = true
          localStorage.setItem('pageDestroy', 'true')
          this.router.navigate(['/scheduled'])
          this.dataShare.selectedGameForConfig = []
          this.dataShare.sessionData = {}
          this.dataShare.currentSelect = null
          this.dataShare.totalConfigDone = []
          this.dataShare.sessionTempEditData = {}
          this.dataShare.newSetup = true
          this.dataShare.lastGameConfigId = null
          this.dashboardService.editStep3 = null
        }
      })
    }
    else {
      const reqIndex = this.dataShare.selectedGameForConfig.findIndex((ele: any) => JSON.parse(ele)['gameId'] == currentSelect)
      if (reqIndex + 1 == this.dataShare.selectedGameForConfig.length) {
        let i = 0
        let flag = 1
        while (flag != -1) {
          flag = this.dataShare.totalConfigDone.findIndex((ele: any) => ele == JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId'])
          if (flag != -1)
            i++
        }
        this.dataShare.currentSelect = JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId']

      }
      else {
        let i = reqIndex + 1
        let flag = 1
        while (flag != -1) {
          flag = this.dataShare.totalConfigDone.findIndex((ele: any) => ele == JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId'])
          if (flag != -1) {
            if ((i + 1) == this.dataShare.selectedGameForConfig.length) {
              i = 0
            }
            else
              i++
          }
        }
        this.dataShare.currentSelect = JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId']
      }
      this.dataShare.lastGameConfigId = 6
    }
  }
  saveBigPictureConfig() {
    if (this.previewSrc) {
      if (this.totalPiece != '' && this.totalPiece) {
        if (this.totalPiece != '0') {
          if (this.noOfGameCode != '' && this.noOfGameCode) {
            if (this.noOfGameCode != '0') {
              if (this.noOfGameCode <= this.totalPiece) {
                let data = new FormData()
                data.append('program_id', this.dataShare.sessionData.programId)
                data.append('game_id', '6')
                data.append('rows', this.rows)
                data.append('columns', this.coloum)
                data.append('no_of_codes', this.noOfGameCode)
                if (this.imgFileData)
                  data.append('image', this.imgFileData)
                // else
                //   data.append('image', this.previewSrc)
                this.httpSv.configBpGame(data).subscribe((res: any) => {
                  localStorage.setItem('flag', 'false')
                  if (res['status'] == true) {
                    const dialogRef = this.dialog.open(SuccessDialog, {
                      hasBackdrop: true,
                      disableClose: true,
                      data: this.timerFlag
                    })
                    dialogRef.afterClosed().subscribe((res: any) => {
                      if (res) {
                        this.moveToNextGame(6)
                        // this.router.navigate(['/scheduled'])
                      }
                    })
                  }
                }, (err: any) => {
                  this.toster.error(err.error.message)
                })
              }
              else {
                this.toster.error('Number of total pieces must be more than or equal to game code', '', { timeOut: 2000 })
              }
            }
            else {
              this.toster.error('Number of game code must be more than 0', '', { timeOut: 2000 })
            }
          }
          else {
            this.toster.error('Please select the number of game code', '', { timeOut: 2000 })
          }
        } else {
          this.toster.error('Rows and Columns can not be zero', '', { timeOut: 2000 })
        }
      }
      else {
        this.toster.error('Please fill the rows and columns', '', { timeOut: 2000 })
      }
    }
    else {
      this.toster.error('Please uplaod image', '', { timeOut: 2000 })
    }
  }
  handleNoOfRound(value: any) {
    const val = parseInt(value.value)
    console.log(this.noOfGameCode)
  }
}



//Picture-Perfect main ts
@Component({
  selector: 'app-new-picture-perfect',
  templateUrl: './picture-perfect-new.html',
  styleUrls: ['./picture-perfect.css']
})
export class PicturePerfectNewGamesComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private toster: ToastrService,
    private httpSv: HttpService,
    private dataShare: DataSharingServiceService,
    private router: Router,
    private dashboardService: DashboardService
  ) { }
  ngOnInit(): void {
    const role: any = localStorage.getItem('role')
    if (role == 1) {
      this.disabledFlag = false
      this.maxPlayerData = ''
      this.noOfTeamsData = ''
      this.sessionTimeData = ''
    }
    this.steperFlag = this.dataShare.newSetup
    if (this.dataShare.sessionTempEditData['4'] == null && !this.steperFlag) {
      this.fetchGameData()
    }
    if (this.dataShare.sessionTempEditData['4']) {
      const temp = this.dataShare.sessionTempEditData['4']
      this.maxPlayerData = temp.maxPlayerData
      this.noOfTeamsData = temp.noOfTeamsData
      this.sessionTimeData = temp.sessionTimeData
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.dataShare.sessionTempEditData['4'] = {
      maxPlayerData: this.maxPlayerData,
      noOfTeamsData: this.noOfTeamsData,
      sessionTimeData: this.sessionTimeData,
    }
  }
  fetchGameData() {
    // this.httpSv.getBpGameDtls(this.dataShare.sessionData.programId).subscribe((res: any) => {
    //   if (res['status']) {
    //     let temp = res['result'][0]
    //     this.maxPlayerData = temp.maxPlayerData
    //     this.noOfTeamsData = temp.noOfTeamsData
    //     this.sessionTimeData = temp.sessionTimeData
    //   }
    // }, (err: any) => {
    // this.toster.error(err.error.message)
    // })
  }
  moveToNextGame(configId: any) {
    slotDeleteFlag = false
    localStorage.setItem('flag', 'false')
    if (this.dataShare.totalConfigDone.findIndex((ele: any) => ele == configId) == -1)
      this.dataShare.totalConfigDone.push(configId)
    let currentSelect = this.dataShare.currentSelect
    // if (this.dataShare.totalConfigDone.length == 1 && this.dataShare.totalConfigDone.length == this.dataShare.selectedGameForConfig.length) {
    //   this.router.navigate(['/scheduled'])
    //   this.dataShare.selectedGameForConfig = []
    //   this.dataShare.sessionData = {}
    //   this.dataShare.currentSelect = null
    //   this.dataShare.totalConfigDone = []
    //   this.dataShare.sessionTempEditData = {}
    //   this.dataShare.newSetup = true
    //   this.dataShare.lastGameConfigId = null
    // }
    // else 
    if (this.dataShare.totalConfigDone.length == this.dataShare.selectedGameForConfig.length) {
      const dialogRef = this.dialog.open(SessionCompleteDialog, {
        disableClose: true,
        minWidth: '350px',
        // minHeight: '185px'
      })
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          pageDestoryFlag = true
          localStorage.setItem('pageDestroy', 'true')
          this.router.navigate(['/scheduled'])
          this.dataShare.selectedGameForConfig = []
          this.dataShare.sessionData = {}
          this.dataShare.currentSelect = null
          this.dataShare.totalConfigDone = []
          this.dataShare.sessionTempEditData = {}
          this.dataShare.newSetup = true
          this.dataShare.lastGameConfigId = null
          this.dashboardService.editStep3 = null
        }
      })
    }
    else {
      const reqIndex = this.dataShare.selectedGameForConfig.findIndex((ele: any) => JSON.parse(ele)['gameId'] == currentSelect)
      if (reqIndex + 1 == this.dataShare.selectedGameForConfig.length) {
        let i = 0
        let flag = 1
        while (flag != -1) {
          flag = this.dataShare.totalConfigDone.findIndex((ele: any) => ele == JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId'])
          if (flag != -1)
            i++
        }
        this.dataShare.currentSelect = JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId']

      }
      else {
        let i = reqIndex + 1
        let flag = 1
        while (flag != -1) {
          flag = this.dataShare.totalConfigDone.findIndex((ele: any) => ele == JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId'])
          if (flag != -1) {
            if ((i + 1) == this.dataShare.selectedGameForConfig.length) {
              i = 0
            }
            else
              i++
          }
        }
        this.dataShare.currentSelect = JSON.parse(this.dataShare.selectedGameForConfig[i])['gameId']
      }
      this.dataShare.lastGameConfigId = 4
    }
  }
  disabledFlag: boolean = true
  steperFlag: boolean = true
  maxPlayerData: any = '7'
  noOfTeamsData: any = '1'
  sessionTimeData: any = ''

  maxplayers = 'The max number of players who can log in on the game link.  The link will not work once this limit is crossed.';
  noteams = ' The number of game boards/ that will be created in this session, where one board is one team.';
  sessionduration = 'Once this time duration is lapsed this particular game expires for players and no resources will be accessible.  The game control room would have a countdown timer to warn the facilitator of the same. The facilitator can end the game at any time before the session duration ends. It is wise to give a time frame that represents the maximum duration possible for that session.';


  savePicturePerfectConfig() {

  }



}



// Session Complete dialog
@Component({
  selector: 'session-complete-dialog',
  templateUrl: './session-config-dialog.html',
})
export class SessionCompleteDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SessionCompleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
    private dataShare: DataSharingServiceService
  ) { }
  ngOnInit(): void {

    this.sessionData = {
      name: this.dataShare.sessionData.sessionName,
      clientName: this.dataShare.sessionData.clientName,
      date: this.dataShare.sessionData.sessionDate,
      time: this.dataShare.sessionData.sessionSlot,
      maxPly: this.dataShare.sessionData.sessionPlayer,


    }
    let allGames = this.dataShare.selectedGameForConfig
    allGames = allGames.map((ele: any) => JSON.parse(ele))
    this.sessionData['games'] = allGames
    console.log(JSON.parse(this.sessionData.games[0]))
    console.log(this.sessionData.games)
  }
  sessionData: any
  close() {
    this.dialogRef.close(true)
  }
}


///Delete Edit Confirm dialog
@Component({
  selector: 'app-delete-edit',
  templateUrl: './delete_confirm_edit.html',
})
export class DeleteEditConfirmDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteEditConfirmDialog>,
  ) { }
  ngOnInit(): void {

  }
  close(action: boolean) {
    this.dialogRef.close(action)
  }
}