<div style="height: 100vh;width: 100%;">
  <app-header></app-header>
  <div class="sessions">
    <div class="sessionTxt" [ngClass]="{selectedSession:selectedSession=='scheduled'} "
      (click)="changeSession('scheduled')">Scheduled Sessions</div>
    <div class="sessionTxt" [ngClass]="{selectedSession:selectedSession=='archive'} "
      (click)="changeSession('archive')">Archive Sessions</div>
  </div>
  <div class="entire" *ngIf="selectedSession == 'scheduled'">
    <!-- <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8"
      style="width: 100%;box-shadow: none;">
      <div>
        <ng-container matColumnDef="back">
          <th mat-header-cell *matHeaderCellDef class="header-element" style="min-width: 5rem;"><img
              src="../../../assets/img/back-svgrepo-com.svg" alt="" srcset="" style="max-width: 25px; cursor: pointer;"
              (click)="goToDashBoard()"></th>
          <td mat-cell *matCellDef="let element" class="rows" (click)="
                expandedElement = expandedElement === element ? null : element; session(expandedElement)">
            <div *ngIf="openSessionData.id!=element.id"> &nbsp;</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="header-element">DATE</th>
          <td mat-cell *matCellDef="let element" class="rows" (click)="
                expandedElement = expandedElement === element ? null : element; session(expandedElement)">
            <div *ngIf="openSessionData.id!=element.id"> <b>{{ element.program_date }}</b></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="program">
          <th mat-header-cell *matHeaderCellDef class="header-element">SESSION</th>
          <td mat-cell *matCellDef="let element" class="rows" (click)="
                  expandedElement = expandedElement === element ? null : element; session(expandedElement)">
            <div *ngIf="openSessionData.id!=element.id"> {{ element.program_name }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="game">
          <th mat-header-cell *matHeaderCellDef class="header-element">CLIENT</th>
          <td mat-cell *matCellDef="let element; let i = index" class="rows" (click)="
                expandedElement = expandedElement === element ? null : element; session(expandedElement)">
            <div *ngIf="openSessionData.id!=element.id"> {{element.client_name}}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="header-element-full">
            <div class="row-end">STATUS</div>
          </th>
          <td mat-cell *matCellDef="let element" style="padding: 20px 0px">
            <div (click)="
                expandedElement = expandedElement === element ? null : element;session(expandedElement)"
              [ngClass]="{draft:(element.status  =='Draft'&& openSessionData.id!=element.id),live:(element.status=='Live' && openSessionData.id!=element.id),
                    configured:element.status=='Configured'&& openSessionData.id!=element.id,completed:element.status=='Completed' && openSessionData.id!=element.id }">
              <div *ngIf="openSessionData.id!=element.id" [ngClass]="{remove:openSessionData.id==element.id} ">
                {{element.status}}</div>
            </div>
          </td>
        </ng-container>

      </div>
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element; let i = index" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail" [@detailExpand]="
              element === expandedElement ? 'expanded' : 'collapsed'
            " (clicked)="(expandedElement)">
            <div class="expand-entire" [ngClass]="{redBorder:isDelete}" (click)="
            expandedElement = expandedElement === element ? null : element; setid()">
              <div *ngIf="isDelete" class="entireDeletePopup">
                <div class="deletePopup" (click)="expandedElement = null">
                  <div style="text-align: center; width: 40%;">
                    <img style="width: 60%;" src="../../../assets/img/omE5s3.tif.svg" alt="">
                  </div>
                  <div style="text-align: center; margin: 10px 0px;">
                    <div>Are you sure you want to</div>
                    <div style="font-weight: 500;"><b>delete</b> this session</div>
                    <div style="display: flex; justify-content: center ; gap: 20px;margin-top: 20px;">

                      <input style="width: 30%;" class="deleteBtn" type="image" src="../../../assets/img/Group 7967.svg"
                        (click)="deleteSession(true,element.id)">
                      <input style="width: 30%;" class="deleteBtn" type="image" src="../../../assets/img/Group 7968.svg"
                        (click)="deleteSession(false); expandedElement = null">
                    </div>
                  </div>

                </div>
              </div>
              <div class="expand-program">
                <b> <b>{{ element.program_date }}</b></b>
              </div>
              <div class="expand-program">
                <b>{{ element.program_name }}</b>
              </div>
              <div class="expand-program">
                <b>{{ element.client_name }}</b>
              </div>
              <div class="expand-program">
                <div [ngClass]="{draft:element.status=='Draft',live:element.status=='Live',
                    configured:element.status=='Configured',completed:element.status=='Completed'  }">
                  {{element.status}}</div>
              </div>
              <div class="expand-game">
                <div class="expand-game-details" *ngFor="let subject of element.program_games let j = index">
                  <div class="game-detail">
                    <div class="game-detail-each-names">
                      <img class="gameImage" *ngIf="subject.game_name=='Value Continuum'"
                        src="../../../assets/img/value_continum.svg">
                      <img class="gameImage" *ngIf="subject.game_name=='Bottle Neck Challenge'"
                        src="../../../assets/img/bottleneck.svg">
                      <img class="gameImage" *ngIf="subject.game_name=='Warehouse'"
                        src="../../../assets/img/warehouse.svg">
                      <img class="gameImage" *ngIf="subject.game_name=='Animal Farm'"
                        src="../../../assets/img/animal_faram.svg">
                      <img class="gameImage" *ngIf="subject.game_name=='Big Picture'"
                        src="../../../assets/img/bigPicture_withtout_bg.svg">
                      <img class="gameImage" *ngIf="subject.game_name=='Human Matrix'"
                        src="../../../assets/img/human_matrix.jpg" class="pyGameImg">
                      <img class="gameImage" *ngIf="subject.game_name=='String Theory'"
                        src="../../../assets/img/string_theory.jpg" class="pyGameImg">
                      <img class="gameImage" *ngIf="subject.game_name=='Stromshelter'"
                        src="../../../assets/img/strom.jpg" class="pyGameImg">
                      <img class="gameImage" *ngIf="subject.game_name=='Assemblyline'"
                        src="../../../assets/img/assembley_line.jpg" class="pyGameImg">
                      <img class="gameImage" *ngIf="subject.game_name=='Magic Mats'"
                        src="../../../assets/img/magic_mats.jpg" class="pyGameImg">
                      <img class="gameImage" *ngIf="subject.game_name=='Stranded'" src="../../../assets/img/stander.jpg"
                        class="pyGameImg">
                      {{subject.game_name}}
                    </div>
                  </div>
                </div>

              </div>
              <div class="sessionsButtons">
                <div class="sessionbtn" (click)="openSession(element)"><img class="sessionImg"
                    src="../../../assets/img/Edit Button.svg"><b>Session Dashboard</b></div>
                <div class="sessionbtn" (click)="sessionDeleteHandler(element)">
                  <img class="sessionImg" src="../../../assets/img/Delete Button.svg"> <b>Delete Session</b>
                </div>
              </div>
            </div>

          </div>


        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let search; columns: columnsToDisplay" class="example-element-row"
        [class.example-expanded-row]="expandedElement === search"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table> -->
    <div>
      <div class="header-info-main-div">
        <div><img src="../../../assets/img/back-svgrepo-com.svg" alt="" srcset=""
            style="max-width: 25px; cursor: pointer; margin: 0 24px;" (click)="goToDashBoard()"></div>
        <div class="header-text-div">DATE</div>
        <div class="header-text-div">SESSION TIME</div>
        <div class="header-text-div">SESSION</div>
        <div class="header-text-div">CLIENT</div>
        <div class="header-text-div">STATUS</div>
      </div>
      <div>
        <div class="single-game-main-div" *ngFor="let item of allGameList;let i=index">
          <div [id]="'oneLine'+i" class="display-block">
            <div class="single-game-sub-div" (click)="handleToggleClick(i,'dropDown')">
              <div><b>{{item.program_date}}</b></div>
              <div>{{timeSlot(item.slots?.start_time, item.slots?.end_time)}}</div>
              <div>{{item.program_name}}</div>
              <div>{{item.client_name}}</div>
              <div [ngClass]="{draft:item.status=='Draft',live:item.status=='Live',
                      configured:item.status=='Configured',completed:item.status=='Completed'  }" style="width: 7rem;">{{item.status}}</div>
            </div>
            <div class="border-div" [id]="'borderLine'+i">&nbsp;</div>
          </div>
          <div [id]="'dropDown'+i" class="display-none" style="font-size: 14px;">
            <div class="dropdwon" [ngClass]="isDelete?'borderRedMainBox':''" (click)="handleToggleClick(i,'oneLine')">
              <div class="m-w-13_5"><b>{{item.program_date}}</b></div>
              <div class="m-w-13_5">{{timeSlot(item.slots?.start_time, item.slots?.end_time)}}</div>
              <div class="m-w-13_5">{{item.program_name}}</div>
              <div class="m-w-13_5">{{item.client_name}}</div>
              <div [ngClass]="{draft:item.status=='Draft',live:item.status=='Live',
                      configured:item.status=='Configured',completed:item.status=='Completed'  }"
                style="min-width: 7rem;">{{item.status}}</div>
              <div
                style="width: -webkit-fill-available;  width: -moz-available; width: fill-available;margin-left: 17px;">
                <div style="display: grid; grid-template-columns: 1fr 1fr;">
                  <div class="dropdwon-list expand-game">
                    <div class="expand-game-details"
                      *ngFor="let subject of item.program_games let j = index">
                      <div class="drop-game-list game-detail">
                        <div class="game-detail-each-names">
                          <img class="gameImage" *ngIf="subject.game_name=='Value Continuum'"
                            src="../../../assets/img/value_continum.svg">
                          <img class="gameImage" *ngIf="subject.game_name=='Bottle Neck Challenge'"
                            src="../../../assets/img/bottleneck.svg">
                          <img class="gameImage" *ngIf="subject.game_name=='Warehouse'"
                            src="../../../assets/img/warehouse.svg">
                          <img class="gameImage" *ngIf="subject.game_name=='Animal Farm'"
                            src="../../../assets/img/animal_faram.svg">
                          <img class="gameImage" *ngIf="subject.game_name=='Big Picture'"
                            src="../../../assets/img/bigPicture_withtout_bg.svg">
                          <img class="gameImage" *ngIf="subject.game_name=='Human Matrix'"
                            src="../../../assets/img/human_matrix.jpg" class="pyGameImg">
                          <img class="gameImage" *ngIf="subject.game_name=='String Theory'"
                            src="../../../assets/img/string_theory.jpg" class="pyGameImg">
                          <img class="gameImage" *ngIf="subject.game_name=='Stromshelter'"
                            src="../../../assets/img/strom.jpg" class="pyGameImg">
                          <img class="gameImage" *ngIf="subject.game_name=='Assemblyline'"
                            src="../../../assets/img/assembley_line.jpg" class="pyGameImg">
                          <img class="gameImage" *ngIf="subject.game_name=='Magic Mats'"
                            src="../../../assets/img/magic_mats.jpg" class="pyGameImg">
                          <img class="gameImage" *ngIf="subject.game_name=='Stranded'"
                            src="../../../assets/img/stander.jpg" class="pyGameImg">
                          <div>{{subject.game_name}}</div>
                        </div>
                      </div>
                      <!-- <div class="drop-game-list">
                                          <img class="gameImage" src="../../../assets/img/bottleneck.svg"
                                              class="game-icon-img">
                                          <div>Bottleneck Challenge</div>
                                      </div> -->
                    </div>
                  </div>
                  <div style="margin: auto;" class="sessionsButtons">
                    <div class="sessionbtn" (click)="openSession(item)"><img class="sessionImg"
                        src="../../../assets/img/Edit Button.svg"><b>Session
                        Dashboard</b></div>
                    <div class="sessionbtn" (click)="sessionDeleteHandler($event,item)">
                      <img class="sessionImg" src="../../../assets/img/Delete Button.svg"> <b>Delete Session</b>
                    </div>
                  </div>



                  <!-- delete confirmation popup -->


                  <div *ngIf="isDelete" class="entireDeletePopup">
                    <div class="deletePopup" (click)="stopParent($event)">
                      <div style="text-align: center; width: 40%;">
                        <img style="width: 60%;" src="../../../assets/img/omE5s3.tif.svg" alt="">
                      </div>
                      <div style="text-align: center; margin: 10px 0px;">
                        <div>Are you sure you want to</div>
                        <div style="font-weight: 500;"><b>delete</b> this session</div>
                        <div style="display: flex; justify-content: center ; gap: 20px;margin-top: 20px;">

                          <input style="width: 30%;" class="deleteBtn" type="image"
                            src="../../../assets/img/Group 7967.svg" (click)="deleteSession(true,$event,item.id)">
                          <input style="width: 30%;" class="deleteBtn" type="image"
                            src="../../../assets/img/Group 7968.svg" (click)="deleteSession(false,$event)">
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="entire" *ngIf="selectedSession == 'archive'">
    <app-archive-schedule></app-archive-schedule>
  </div>



</div>