<div style="height: 100vh;">
    <app-header [oneHeader]='DigitalGames' [adminFlag]="true"></app-header>
    <div class="game_content_container">
        <div class="game-content">
            <div class="content_left">
                <div class="back_button">
                    <img style="height: 34px; cursor:pointer;" src="../../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"/>
                    <span>Animal Farm</span>
                </div>
                <div class="game_desc">
                    <div style="display: flex; flex-direction:row; height: 60px; justify-content:flex-end; align-items:center; margin-right: 20px;">
                        <img style="width: 80px; height: 26px;" src="../../../../assets/edit_white_button.svg">
                    </div>
                    <div style="overflow-y: auto;">
                        <p>
                            Matrix as a game offers an opportunity to delve into an exploration of how and why collaboration and its various facets. The game creates an environment for interactions, trust, working with others and reaching win-win solutions, which form the crux of the session.
                        </p>
                    </div>
                </div>
            </div>
            <div class="content_right">
                <div class="diplay:flex;">
                    <img class="add_legend_btn" src="../../../../assets/add_legend.svg" (click)="addLegend()">
                </div>
                <div style="display:flex; width:100%">
                    <div class="legend_list">
                        <div class="legend">
                            <img style="height:20px; width:20; margin-top:15px" src="../../../../assets/3_line_btn.svg" alt="">
                            <div class="legend_container">
                                <div class="legend_header">
                                    <span>Legend Name</span>
                                    <img style="width: 80px; height: 26px;" src="../../../../assets/edit_white_button.svg">
                                </div>
                                <div class="legend_body">
                                    <div style="display: flex; justify-content:space-between; margin:0x 5px; align-items:center;">
                                        <div class="container">
                                            <img style="height:20px; width:20;" src="../../../../assets/3_line_btn.svg" alt="">
                                            <img style="height:20px; width:20;" src="../../../../assets/play_btn.svg" alt="">
                                            <div class="text">This is a long text that overflows.</div>
                                        </div>
                                        <img style="height:25px; width:25;"  src="../../../../assets/delete_btn.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <img style="height:25px; width:25; margin-top:15px"  src="../../../../assets/delete_btn.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="preview_container">
            <div class="preview_title">
                <div class="main-content-head">
                    <span style="font-size: 24px; font-weight:bold;">{{name}} <span style="font-size: 24px; font-weight:500;">(Preview)</span></span>
                </div>
                <div>
                    <div  style="text-align: end;padding-right: 1rem;">
                        <img *ngIf="!zoomFlag" style="height:5vh;" src="../../../assets/img/zoom-out-fixed-svgrepo-com.svg" (click)="zoom()" alt="" class="zoom-img">
                        <img *ngIf="zoomFlag" style="height:5vh;" src="../../../assets/img/zoom-in-fixed-svgrepo-com.svg" (click)="zoom()" alt="" class="zoom-img">
                    </div>
                </div>
            </div>
            <div class="guide_preview" [ngClass]="zoomFlag?'grid-1':''" *ngIf="type == 'pdf'">
                <div  *ngIf="!zoomFlag"></div>
                <div>
                    <pdf-viewer [src]="src" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false"
                        [zoom]="0" [zoom-scale]="zoomScale" [stick-to-page]="true" [render-text]="true"
                        [external-link-target]="'blank'" [autoresize]="true" [show-borders]="true"
                        class="pdf-viewer">
                    </pdf-viewer>
                </div>
                <div *ngIf="!zoomFlag"></div>
            </div>
        </div>
    </div>
</div>