<app-header [oneHeader]=header [adminFlag]="true"></app-header>
<div style="height: 90vh; background-color:#F8F8F8" class="main_game_assign_div">
    <div class="main_game_assign_item" style="min-width: 400px;">
        <div class="user_name_main_div">
            <div>{{user_dtls.first_name}}&nbsp;{{user_dtls.last_name}}</div>
            <div (click)="editUserProfile()">Edit</div>
        </div>
        <div class="white_card" *ngIf="user_dtls.bio">
            {{user_dtls.bio}}
        </div>
        <div class="white_card">
            <div class="user_info_div">
                <span>Organisation :</span>
                <span>{{user_dtls.organisation}}</span>
            </div>
            <div class="user_info_div">
                <span>Email address :</span>
                <span>{{user_dtls.email}}</span>
            </div>
            <div class="user_info_div">
                <span>Phone No. :</span>
                <span>{{user_dtls.contact}}</span>
            </div>
            <div class="user_info_div">
                <span>Registered on :</span>
                <span>{{user_dtls.registered_on}}</span>
            </div>
            <div class="user_info_div">
                <span>User name :</span>
                <span>{{user_dtls.email}}</span>
            </div>
            <div class="user_info_div">
                <span>Password :</span>
                <span>* * * * * * * *</span>
            </div>
            <div class="user_info_div">
                <span>User Type :</span>
                <span>{{getRole(user_dtls.role_id)}}</span>
            </div>
        </div>
        <div class="white_card" style="flex: 1;">
            <div class="reach_out_heading_div">
                <img src="../../../../assets/raisedHand.svg" alt="">
                <div>Reach out</div>
            </div>
            <div class="reach_out_reason_div" *ngIf="user_dtls.reachout_id">
                <div *ngFor="let item of user_dtls.reachout_reason">
                    {{item.reason_txt}}
                </div>
                <div *ngIf="user_dtls.querie">{{user_dtls.querie}}</div>
            </div>
            <div class="no_reach_out_div" *ngIf="!user_dtls.reachout_id">
                <h3>No Reach Out</h3>
            </div>
        </div>
    </div>
    <div class="main_game_assign_item">
        <div class="leftContainer">
            <div class="individualSelections" [ngClass]="{highlightdigital:selectedExplore=='digital'} "
                (click)="selectedExploreGame('digital')">
                <img src="../../../assets/img/digitalGame.svg"
                    [ngClass]="{rounded:selectedExplore=='digital',circle:selectedExplore!='digital'} ">
                <b>Digital Games</b>
                <i class="arrow right arrow-digital" *ngIf="selectedExplore=='digital'"></i>
            </div>
            <div class="individualSelections" [ngClass]="{highlightphysical:selectedExplore=='physical'} "
                (click)="selectedExploreGame('physical')">
                <img src="../../../assets/img/physicalGame.svg"
                    [ngClass]="{rounded:selectedExplore=='physical',circle:selectedExplore!='physical'} ">
                <b>Physical Games</b>
                <i class="arrow right arrow-physical" *ngIf="selectedExplore=='physical'"></i>
            </div>
        </div>
    </div>
    <div style="width: 100%;">
        <div *ngIf="selectedExplore == 'digital'">
            <div class="physical_game_main_div" *ngIf="!di_assign_flag">
                <div class="phsical_heading">Digital Games</div>
                <div class="physical_body_main_div">
                    <div class="digital_game_info_div" *ngIf="user_digital_subcription.length > 0">
                        <div>
                            <div class="assign_heading">No. of Licenses</div>
                            <div style="margin: 10px 0;">Left : {{user_digital_subcription[0].no_of_licenses_left}}</div>
                            <div>Purchased : {{user_digital_subcription[0].no_of_licenses}}</div>
                        </div>
                        <div>
                            <div class="assign_heading">Validity of Licenses</div>
                            <div style="margin: 10px 0;">Valid till : {{user_digital_subcription[0].end_date*1000 | date:'dd/MM/yyyy'}}</div>
                            <div>Purchased on : {{user_digital_subcription[0].start_date*1000 | date:'dd/MM/yyyy'}}</div>
                        </div>
                    </div>
                    <div>
                        <div class="assign_heading">Assigned Games</div>
                        <div class="white_card" style="min-width: 300px;">
                            <div *ngIf="user_digital_subcription.length > 0">
                                <div class="py_game_aceess_main_div" *ngFor="let item of user_digital_subcription">
                                    <div>&nbsp;</div>
                                    <div>{{item.game_name}}</div>
                                </div>
                            </div>
                            <div *ngIf="user_digital_subcription.length == 0" class="py_not_found">
                                <h3>No Game Assigned</h3>
                            </div>
                        </div>
                        <div class="assign_new_btn_div" style="margin-top: 3rem;">
                            <button class="assign_new_btn" (click)="assignNewGame('digital')">Assign new games</button>
                        </div>
                    </div>
                </div>
            </div>
            <digital-game-assignment *ngIf="di_assign_flag" [gameAccess]="user_digital_subcription"
                [user_id]="user_id" (backEmitter)="receiveMessage($event)"></digital-game-assignment>
        </div>
        <div *ngIf="selectedExplore == 'physical'" style="height: 98%;">
            <div class="physical_game_main_div" *ngIf="!py_assign_flag">
                <div class="phsical_heading">Physical Games</div>
                <div class="physical_body_main_div">
                    <div>
                        <div class="assign_heading">Assigned Games</div>
                        <div class="white_card" style="min-width: 300px;">
                            <div *ngIf="user_physical_subcription.length > 0">
                                <div class="py_game_aceess_main_div" *ngFor="let item of user_physical_subcription">
                                    <div>&nbsp;</div>
                                    <div>{{item.game_name}}</div>
                                </div>
                            </div>
                            <div *ngIf="user_physical_subcription.length == 0" class="py_not_found">
                                <h3>No Game Assigned</h3>
                            </div>
                        </div>
                        <div class="assign_new_btn_div">
                            <button class="assign_new_btn" (click)="assignNewGame('physical')">Assign new games</button>
                        </div>
                    </div>
                </div>
            </div>
            <physical-game-assignment *ngIf="py_assign_flag" [gameAccess]="user_physical_subcription"
                [user_id]="user_id" (backEmitter)="receiveMessage($event)"></physical-game-assignment>
        </div>
    </div>
</div>