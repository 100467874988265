import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class KariosInterceptorInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  token: any = localStorage.getItem('token');
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //   if(this.token){
    //   const reqCopy = request.clone({
    //     setHeaders:{
    //       Authorization:"Bearer "+localStorage.getItem('token')
    //     }
    //   })
    //   return next.handle(reqCopy);
    //   }
    //   else{
    //     const reqCopy = request.clone({});
    //     this.router.navigate(['/login']);
    //     return next.handle(reqCopy);
    //   }
    // }
    const reqCopy = request.clone({
      setHeaders: {
        Authorization: "Bearer " + localStorage.getItem('token')
      }
    })
    return next.handle(reqCopy).pipe(retry(1),
    catchError((error: HttpErrorResponse) => {
      if(error.error.message == 'Unauthenticated Access!' && error.status === 401){
       this.router.navigate(['/login'])

      }
     return throwError(error);
     
    }));
  }
}

