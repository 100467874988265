import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/Shared/Services/Dashboard.service';

@Component({
  selector: 'app-archive-dashboard',
  templateUrl: './archive-dashboard.component.html',
  styleUrls: ['./archive-dashboard.component.css']
})
export class ArchiveDashboardComponent implements OnInit {

  constructor(
    private dashboardService: DashboardService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.dashboardService.selectedArchiveSession != null) {
      const data = this.dashboardService.selectedArchiveSession
      this.sessionName = data.program_name
      this.clientName = data.client_name
      this.sessionDate = data.program_date
      this.maxPlyr = data.time_slots.players
      this.timeSlotData = `${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(data.time_slots.first_start_time * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(data.time_slots.last_expiry_time * 1000))}`
      this.gamesConfig = data.program_games
      this.program_id = data.id
      this.programStartTime = this.formateEpoch(data.start_time)
      this.programEndTime = this.formateEpoch(data.expiry_time)
      console.log()
      switch (this.gamesConfig[0].game_id) {
        case 1:
          this.selectedGame = 1
          this.getVCDtls()
          break;
        case 3:
          this.selectedGame = 3
          this.getWHDtls()
          break
      }
      



    }
    else{
      this.router.navigate(['/scheduled'])
    }
    this.dashboardService.archiveFlag = true
  }
  programStartTime: any
  programEndTime: any
  headerMsg: string = "Archive Dashboard"
  sessionName: any
  clientName: any
  sessionDate: any
  maxPlyr: any
  timeSlotData: any
  gamesConfig: any = []
  program_id: any
  vcPlayedFlag: boolean = true
  whPlayedFlag: boolean = true
  selectedGame: any = 0

  vcArchiveData: any
  getVCDtls() {
    this.dashboardService.getArchiveVCDtls(this.program_id).subscribe((res: any) => {
      if (res['status']) {
        this.vcArchiveData = res['result']
      }
      else {
        this.vcPlayedFlag = false
      }
    })
  }
  whArchiveData: any
  getWHDtls() {
    // this.program_id
    this.dashboardService.getArchiveWHDtls(this.program_id).subscribe((res: any) => {
      if (res['status']) {
        this.whArchiveData = res['result']
      }
      else {
        this.whPlayedFlag = false
      }
    })
  }
  formateEpoch(epoch: any) {
    if (epoch)
      return new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(epoch * 1000))
    else return '00:00'
  }
  switchGame(id: any) {
    if (this.selectedGame == id) return
    else {
      this.selectedGame = id
      switch (this.selectedGame) {
        case 1:
          this.getVCDtls()
          break;
        case 3:
          this.getWHDtls()
          break
      }
    }

  }
  getSecToMin(sec:any){
    if(sec) {
      let min = (Math.round(sec/60)).toString()
      let s = (`0`+ sec%60).slice(-2)
      return `${min}:${s}`
    }else{
      return '00:00'
    }
  }
  getGameDuration(sec:any){
    if(sec) {
      let min = (Math.round(sec/60)).toString()
      min = min.length > 1 ? min : `0`+min
      let s = (`0`+ sec%60).slice(-2)
      return `${min}.${s}`
    }else{
      return '00'
    }
  }
}
