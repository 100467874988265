<app-header [oneHeader]=header [adminFlag]="true"></app-header>
<div style="height: 90vh; background-color:#F8F8F8">
    <div style="display: flex;">
        <button style="max-width: 10vw; border: none; background: none; padding: 0; margin: 2rem 3.1rem 2rem;" (click)="changeCompRoute('addUser')">
            <img style="width:100%" src="../../../../assets/addNewUser.svg" alt="add new user">
        </button>
    </div>
    <div class="tableShadow" style="margin: 0 50px; justify-content:center; overflow-y: auto">
        <table>
            <thead>
                <tr>
                    <th scope="col"><span>{{''}}</span></th>
                    <th scope="col"><span>User</span></th>
                    <th scope="col"><span>Email</span></th>
                    <th scope="col"><span>User type</span></th>
                    <th scope="col"><span>Registration</span></th>
                    <th scope="col"><span>Last Login</span></th>
                    <th scope="col"><span>Options</span></th>
                </tr>
            </thead>
            <tbody class="user-table-body" style="max-height: 65vh;">
                <tr *ngFor="let user of allUsers; let i = index" (mouseenter)="onRowHover(true, user)" (mouseleave)="onRowHover(false, user)" [class.hovered]="user.reachout_flag == '1'? true : false">
                    <td scope="row" [class.show-hand-icon]="user.reachout_flag == '1'? true : false"></td>
                    <td scope="row">{{ user.first_name}}&nbsp;{{ user.last_name}}</td>
                    <td scope="row" style="overflow-wrap: anywhere;">{{ user.email }}</td>
                    <td scope="row">{{ getRole(user.role_id) }}</td>
                    <td scope="row">{{ getDateFormat(user.created_at) }}</td>
                    <td scope="row">{{ getDateFormat(user.last_login_at) }}</td>
                    <td scope="row">
                        <div class="custom-dropdown" [class.open]="user.isDropdownOpen">
                            <button (click)="toggleDropdown(user)"><img src="../../../../assets/three-dots-svgrepo-com.svg" alt="" class="three_dot_svg"></button>
                            <div *ngIf="user.isDropdownOpen" class="dropdown-menu">
                                <div (click)="onOptionClick('assignGame', user)">Assign Game</div>
                                <div (click)="onOptionClick('editUser', user)">Edit User</div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>