<div style="text-align: center; padding: 20px;">
    <div style="text-align: end;"><img src="../../../assets/img/close-md-svgrepo-com.svg" alt=""
            style="width: 20px;cursor:pointer" (click)="close()"></div>
    <div style="font-size: 18px;font-weight: 600;">Scan the code to go Mobile!</div>
    <qrcode [qrdata]="playerLink" [width]="250" [errorCorrectionLevel]="'M'"></qrcode>
    <div>
       <p> For best game experience, use Google chrome browser only and play in full screen landscape mode.</p>
    </div>
</div>
<style>
        ::ng-deep .mat-dialog-container {
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
        background-color: #ffff;
        padding: 0;

    }
</style>