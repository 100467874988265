import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';
@Component({
  selector: 'app-game-resources',
  templateUrl: './game-resources.component.html',
  styleUrls: ['./game-resources.component.css']
})
export class GameResourcesComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toster: ToastrService,
    private httpSv: HttpService,
    private changeDetect: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private dataShare: DataSharingServiceService
  ) { }
  gameType: string = ''
  ngOnInit(): void {
    let gameDltId: any = null
    this.route.queryParams.subscribe((result: any) => {
      gameDltId = result.id
      if (parseInt(gameDltId) < 7) this.gameType = 'digital'
      else if(parseInt(gameDltId) > 7 && parseInt(gameDltId) < 14 ) this.gameType = 'physical'

    })
    let reqIndex = this.gamesDtl.findIndex((ele: any) => ele.id == gameDltId)
    if (reqIndex != -1) {
      this.gameDtlIndex = reqIndex
      if (this.src = this.gamesDtl[this.gameDtlIndex]['files'].length > 0) {
        if (this.gamesDtl[this.gameDtlIndex]['files'][0]['type'] != 'ppt') {
          this.src = this.gamesDtl[this.gameDtlIndex]['files'][0]['src']
          this.fileName = this.gamesDtl[this.gameDtlIndex]['files'][0]['fileName']
          this.name = this.gamesDtl[this.gameDtlIndex]['files'][0]['des_name']
          this.type = this.gamesDtl[this.gameDtlIndex]['files'][0]['type']
          this.allFiles = this.gamesDtl[this.gameDtlIndex]['files']
        }
        else {
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.gamesDtl[this.gameDtlIndex]['files'][0]['src'])
          this.fileName = this.gamesDtl[this.gameDtlIndex]['files'][0]['fileName']
          this.name = this.gamesDtl[this.gameDtlIndex]['files'][0]['des_name']
          this.type = this.gamesDtl[this.gameDtlIndex]['files'][0]['type']
          this.allFiles = this.gamesDtl[this.gameDtlIndex]['files']
          this.downloadSrc = this.gamesDtl[this.gameDtlIndex]['files'][0]['downloadSrc']
        }
      }
    }
    else {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      this.router.navigate(['/exploreGames'])
    }
  }
  zoomScale: any = 'page-height'
  zoomFlag: boolean = false
  zoom() {
    if (!this.zoomFlag) {
      this.zoomFlag = true
      this.zoomScale = 'page-width'
    }
    else if (this.zoomFlag) {
      this.zoomFlag = false
      this.zoomScale = 'page-height'
    }
  }
  gameDtlIndex: any
  // ---------------------------------------------------allData below----------------------------------------------->
  gamesDtl: any = [
    {
      id: 1,
      name: 'Value Continuum',
      description: 'Value Continuum is a activity that helps explore individual’s mindsets & preferences and perceptions of others and pushes them to reflect on their own thinking and preferences, thereby promoting open conversations on diversity, empathy and interpersonal interactions.',
      files: [
        { fileName: 'Facilitator Guide', des_name:'Value Continuum', type: 'pdf', src: '../../../assets/pdf/Kairos_Value_Continuum_ Guide.pdf' },
        // { fileName: 'Value Continuum Intro', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/Final_Value_+Continuum_Intro_video.mp4' },
        { fileName: 'Learning to Action Tool', des_name:'Value Continuum-Empathy Affirmations & Stakeholder Map', type: 'ppt', src: '../../../assets/ppt/Value-Continuum-Empathy-Affirmations-_-Stakeholder-Map.html', downloadSrc: '../../../assets/ppt/Value Continuum - Empathy Affirmations & Stakeholder Map.pptx' },
      ]
    },
    {
      id: 2,
      name: 'Bottleneck Challenge',
      description: "The Bottleneck Challenge is an engaging team activity where participants find themselves on a mountain road, stuck in a peculiar jam. The objective is for the traffic from both sides ( represented by team members) to get to the other side and resolve the jam, while playing by certain rules that govern the movement. The activity is a powerful tool for reflecting on one's behaviour when given a task",
      files: [
        { fileName: 'Facilitator Guide', des_name:'Bottleneck Challenge', type: 'pdf', src: '../../../assets/pdf/Kairos_O&A_Bottleneck_Challenge.pdf' },
        { fileName: 'Learning to Action Tool', des_name:'Bottleneck Challenge-Problem Solving Learning to action tool', type: 'ppt', src: '../../../assets/ppt/Bottleneck-Challenge-Problem-Solving-Learning-to-action-tool.html', downloadSrc: '../../../assets/ppt/Bottleneck Challenge - Problem Solving Learning to action tool.pptx' },
      ]
    },
    {
      id: 3,
      name: 'Warehouse',
      description: 'Warehouse Game is a multifaceted and highly engaging team activity that challenges teams and individuals to think critically and work collaboratively to transport a given target order of items from a warehouse to their team store in the shortest time possible.',
      files: [
        { fileName: 'Facilitator Guide', des_name:'Warehouse', type: 'pdf', src: '../../../assets/pdf/Kairos_Warehouse_Facguide.pdf' },
        // { fileName: 'Warehouse game intro video', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/Final_Warehouse+game_intro_video.mp4' },
        { fileName: 'Learning to Action Tool', type: 'ppt', des_name:'Warehouse-Embracing Change', src: '../../../assets/ppt/Warehouse-Embracing-Change.html', downloadSrc: '../../../assets/ppt/Warehouse-Embracing-Change.pptx' },
      ]
    },
    {
      id: 4,
      name: 'Picture Perfect',
      description: "A challenging game where teams have to recreate an image on a virtual canvas based on the instructions they receive from a guide/leader who alone has access to the image that has to be replicated.The game mimics the real life challenges of communication to get things done in an interdependent environment, where the ability to humanize ones communication has a direct impact on outcomes.",
      files: [
        { fileName: 'Learning to Action tool', des_name:'Picture Perfect-Communication Rulebook', type: 'ppt', src: '../../../assets/ppt/Picture-Perfect-Communication-Rulebook-Learning-to-Action-tool.html', downloadSrc: '../../../assets/ppt/Picture-Perfect-Communication-Rulebook-Learning-to-Action-tool.pptx' },
      ]
    },
    {
      id: 5,
      name: 'Animal Farm',
      description: 'Animal Farm is an engaging activity, a team must transport a specific number of animals & birds into their barn before the other team competing with them in the same game.These animals can be taken from the central barn or anyway else in the farm in order to meet the target before the other team.',
      files: [
        { fileName: 'Facilitator Guide', des_name:'Animal Farm', type: 'pdf', src: '../../../assets/pdf/Kairos_Animal_Farm_Building_trust.pdf' },
        { fileName: 'Learning to Action tool', des_name:'Animal Farm-Collaboration', type: 'ppt', src: '../../../assets/ppt/Animal-Farm-Collaboration.html', downloadSrc: '../../../assets/ppt/Animal-Farm-Collaboration.pptx' },

      ]
    },
    {
      id: 6,
      name: 'Big Picture',
      description: 'Big Picture is a versatile and highly customizable jigsaw themed group activity that works for virtual and in-person settings. Smaller teams have to complete challenges to earn codes. With each code certain pieces of a giant jigsaw get revealed. when all team complete all challenges and enter all codes, the entire Big picture is unveiled. The Challenges and the Image that makes up the jigsaw are customizable to the theme of your session or event.',
      files: [
        { fileName: 'Facilitator Guide', des_name:'Big Picture', type: 'pdf', src: '../../../assets/pdf/Kairos_Digital_BigPicture_Facilitator_guide.pdf' },
        { fileName: 'Learning to Action tool', des_name:'Big Picture-All Purpose', type: 'ppt', src: '../../../assets/ppt/Big-Picture-All-purpose.html', downloadSrc: '../../../assets/ppt/Big-Picture-All-purpose.pptx' },
        { fileName: 'Big Picture Image', des_name:'Big Picture', type: 'img', src: '../../../assets/img/big_picture_resource1.png' },
        // { fileName: 'Facilitator Image2', des_name:'Big Picture', type: 'img', src: '../../../assets/img/big_picture_resource2.jpeg' },

      ]
    },
    {
      id: '8',
      name: 'Human Matrix',
      description: 'Matrix as a game offers an opportunity to delve into an exploration of how and why collaboration and its various facets. The game creates an environment for interactions, trust, working with others and reaching win-win solutions, which form the crux of the session.',
      files: [
        { fileName: 'Facilitator Guide', des_name:'Human Matrix', type: 'pdf', src: '../../../assets/pdf/Matrix_Physical_FacGuide.pdf' },
        { fileName: 'Human Matrix Intro', des_name:'Human Matrix-Intro', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/Human+Matrix-introduction.mp4' },
        { fileName: 'Facilitator Role', des_name:'Human Matrix-Role', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/Human-matrix+facilitator+role.mp4' },
        { fileName: 'Learning to Action Tool', des_name:'Human Matrix-Collaboration', type: 'ppt', src: '../../../assets/ppt/Matrix-Collaboration-Learning-to-Action-Tool.html', downloadSrc: '../../../assets/ppt/Matrix-Collaboration-Learning-to-Action-Tool.pptx' },
      ]
    },
    {
      id: '13',
      name: 'Stranded',
      description: "Strand'ed multifaceted, highly engaging game covers several fundamental behaviours that govern communication and leadership.",
      files: [
        { fileName: 'Facilitator Guide',des_name:'Stranded',  type: 'pdf', src: '../../../assets/pdf/KAIROS_Stranded_Game_.pdf' },
        { fileName: 'Overview and Set up',des_name:'Stranded-Overview and Set up',  type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/-Stranded+Game-Activity+Overview+and+Set+up-BlueSky+Learning-V4.mp4' },
        { fileName: 'Facilitator Role', des_name:'Stranded-Role', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/Stranded+Game-Facilitator+Role-BlueSky+Learning-V3+video+02.mp4' },
        { fileName: ' Learning Connect', des_name:' Learning Connect', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/STRANDED_video.mov' },
        { fileName: 'Learning to Action tool',des_name:'Stranded-Communication Rulebook',  type: 'ppt', src: '../../../assets/ppt/Strand-Ed-Communication-Rulebook-Learning-to-Action-tool.html', downloadSrc: '../../../assets/ppt/Strand-Ed-Communication-Rulebook-Learning-to-Action-tool.pptx' },
      ]
    },
    {
      id: '10',
      name: 'Storm Shelter',
      description: 'The Storm Shelter is an intense and layered activity that explores the journey of moving from managing to leading teams. This multifaceted, highly engaging game covers several fundamental behaviours that govern leadership.',
      files: [
        { fileName: 'Facilitator Guide', des_name:'Storm Shelter', type: 'pdf', src: '../../../assets/pdf/Managing_to_lead _Strom.pdf' },
        { fileName: 'Game Introduction', des_name:'Storm Shelter', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/Storm+Shelter+Game-Introduction+_+Rules-BlueSky+Learning-V3.mp4' },
        { fileName: 'Facilitator Role', des_name:'Storm Shelter', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/Storm+Shelter+Game-Facilitator+Role-BlueSky+Learning-V2.mp4' },
        { fileName: 'Learning to Action Tool', des_name:'Storm Shelter-Leading Teams', type: 'ppt', src: '../../../assets/ppt/Storm-Shelter-Leading-Teams-Learning-to-Action-Tool.html', downloadSrc: '../../../assets/ppt/Storm-Shelter-Leading-Teams-Learning-to-Action-Tool.pptx' },
      ]
    },
    {
      id: '11',
      name: 'Assembly Line',
      description: 'Assembly Line captivating game explores various fundamental principles related to Growth Mindset, Innovation, and Continuous Improvement, offering a multifaceted and engaging experience.',
      files: [
        { fileName: 'Facilitator Guide',des_name:'Assembly Line', type: 'pdf', src: '../../../assets/pdf/Assembly_Line_FacGuide_.pdf' },
        { fileName: 'Game Introduction', des_name:'Assembly Line-Introduction',type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/Assembly+Line-Introduction-and-setup.mp4' },
        { fileName: 'Facilitator Role',des_name:'Assembly Line-Role', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/Assembly+Line-Facilitators+Role-Blue+Sky+Learning-V6.mp4' },
        { fileName: 'Learning to Action tool',des_name:'Assembly Line-Growth Mindset', type: 'ppt', src: '../../../assets/ppt/Assembly-Line-Growth-Mindset-Learning-to-Action-tool.html', downloadSrc: '../../../assets/ppt/Assembly Line - Growth Mindset Learning to Action tool.pptx' },
      ]
    },
    {
      id: '9',
      name: 'String Theory ',
      description: 'String Theory captivating game explores various fundamental principles related to Problem Solving and its various aspects, offering a multifaceted and highly engaging experience.',
      files: [
        { fileName: 'Facilitator Guide',des_name:'String Theory', type: 'pdf', src: '../../../assets/pdf/Problem_Solving_String_Theory.pdf' },
        { fileName: 'Activity setup and Facilitators Note',des_name:'String Theory-Setup', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/String+Theory-Activity+setup+and+Facilitators+Note-Blue+Sky+Learning-V4.mp4' },
        { fileName: 'Facilitator Notes',des_name:'String Theory-Notes', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/String+Theory+Facilitators+Note-Blue+Sky+Learning-V4.mp4' },
        { fileName: 'Learning to Action tool',des_name:'String Theory-Quality Process Orientation', type: 'ppt', src: '../../../assets/ppt/String-Theory-Quality-_-Process-Orientation.html', downloadSrc: '../../../assets/ppt/String-Theory-Quality-_-Process-Orientation.pptx' },
      ]
    },
    {
      id: '12',
      name: 'Magic Mats',
      description: 'Magic Mats as a game lends itself very powerfully to learning conversations around the underlying behaviours that drive result orientation',
      files: [
        { fileName: 'Facilitator Guide', des_name:'Magic Mats', type: 'pdf', src: '../../../assets/pdf/Result_Orentation_Magic_Mats.pdf' },
        { fileName: 'Game Introduction', des_name:'Magic Mats-Introduction', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/Magic+Mat+Game+Game-Introduction-BlueSky+Learning-V6.mp4' },
        { fileName: 'Facilitator Role', des_name:'Magic Mats-Role', type: 'video', src: 'https://playkairos.s3.ap-south-1.amazonaws.com/Physical_Games/videos/Magic+Mat+Game-FACILITATOR_s+ROLE-BlueSky+Learning-V6.mp4' },
        { fileName: 'Learning to Action tool', des_name:'Magic Mats-Result Orientation', type: 'ppt', src: '../../../assets/ppt/Magic-Mats-Result-Orientation.html', downloadSrc: '../../../assets/ppt/Magic-Mats-Result-Orientation.pptx' },
      ]
    },
  ]
  // ---------------------------------------------------allData above ----------------------------------------------->
  src: any
  fileName: any
  name:any;
  type: any
  allFiles: any
  downloadSrc: any
  handleFileChange(index: any, name: any) {
    if (this.gamesDtl[this.gameDtlIndex]['files'][index]['type'] != 'ppt') {
      if (this.fileName == name) return
      else {
        this.src = this.gamesDtl[this.gameDtlIndex]['files'][index]['src']
        this.fileName = this.gamesDtl[this.gameDtlIndex]['files'][index]['fileName']
        this.name = this.gamesDtl[this.gameDtlIndex]['files'][index]['des_name']
        this.type = this.gamesDtl[this.gameDtlIndex]['files'][index]['type']

        this.changeDetect.detectChanges()
      }
    }
    else {
      if (this.fileName == name) return
      else {
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.gamesDtl[this.gameDtlIndex]['files'][index]['src'])
        this.fileName = this.gamesDtl[this.gameDtlIndex]['files'][index]['fileName']
        this.name = this.gamesDtl[this.gameDtlIndex]['files'][index]['des_name']
        this.type = this.gamesDtl[this.gameDtlIndex]['files'][index]['type']
        this.downloadSrc = this.gamesDtl[this.gameDtlIndex]['files'][index]['downloadSrc']
        this.changeDetect.detectChanges()

      }
    }
  }
  goBack() {
    if (this.dataShare.lastUrlForGameResource) {
      this.router.navigate([this.dataShare.lastUrlForGameResource])
      this.dataShare.lastUrlForGameResource = null
    }
    else {
      this.router.navigate(['/exploreGames'])
    }
  }
  tempDownSrc: any

  downloadPPT(src: any) {
    this.tempDownSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.downloadSrc)
    setTimeout(() => {
      this.tempDownSrc = null
    }, 0)
  }
  downloadImage(src:any) {
    // Replace 'image.png' with the actual image URL you want to download
    const imageUrl = src;
    const element = document.getElementById('image_download') as HTMLAnchorElement
    if(element){
      element.href = imageUrl;
      element.click();
    }
  }
}
function ViewChild(arg0: string, arg1: { static: boolean; }): (target: GameResourcesComponent, propertyKey: "downloadLink") => void {
  throw new Error('Function not implemented.');
}

