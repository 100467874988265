<div>
    <div>
        <!-- <div class="step-main-div" *ngIf="steperFlag">
            <div>STEP 1</div>
            <div>STEP 2</div>
            <div class="step-active-css">STEP 3</div>
        </div> -->
        <div class="wh_header_main_div">
            <div>
                <div style="font-weight: 600;font-size: 22px;">Game set-up</div>
                <div>Please review the game details before you finish the setup !</div>
            </div>
            <div class="wh_header_right_sub">
                <div *ngIf="timerFlag">
                    <span>Time left to complete the set-up </span>
                    <span>{{('0'+remainingMinutes).slice(-2)}}:{{('0'+remainingSeconds).slice(-2)}}</span>
                </div>
                <div><img src="../../../assets/profile.png" alt="" (click)="toggleFunc()" style="cursor: pointer;"></div>
            </div>
            <div class="header-drowdwon" *ngIf="toggleFlag" (appOutsideClick)="outSideClickHandle($event)">
                <div class="header-drowdwon-content" (click)="goToAccountSetting()">
                    My Profile
                </div>
                <div class="header-drowdwon-content" (click)="goToMyGame()">
                    My Games
                </div>
                <div class="header-drowdwon-content" (click)="goGameReport()">
                    Games Reports
                </div>
                <div class="header-drowdwon-content" (click)="logout()">
                    Logout
                </div>
            </div>
        </div>
        <div class="pre_selected_main_div" *ngIf="!editFlag">
            <div style="flex: 1; box-shadow: 0px 3px 6px #00000029;border-radius: 20px;">
                <div class="pre_select_header">
                    <div><img src="../../../assets/img/explore_vc.svg" alt="" style="width: 100px;"></div>
                    <div>
                        <div style="text-align: center;font-size: 40px;">{{done.length}}</div>
                        <div style="margin-bottom: 10px;">Statement Pairs
                            <img placement="bottom" [ngbTooltip]="statementPairTooptip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;" >
                        </div>
                    </div>
                </div>
                <div class="pre_select_pair_list">
                    <div class="example-box-right" *ngFor="let item of done; let i = index">
                        <div class="selecred-pair-div">
                            <span>Pair {{i+1}}</span>
                            <div class="arrow-left" [ngClass]="getCssClass('pairDataSelect1'+i)"></div>
                            <div class="selecred-pair-sub-div" [id]="'pairDataSelect1'+i">
                                <div>{{item['pair_left']}}</div>
                                <div><img src="../../../assets/img/U-dia.svg" alt=""></div>
                                <div>{{item['pair_right']}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="quick_btn_main_div">
                <div style="text-align: center;">
                    <img src="../../../assets/img/edit_vc_game_btn.svg" alt="" style="width: 80%;cursor:pointer"
                        (click)="editFlag = true">
                </div>
                <div style="text-align: center;">
                    <img src="../../../assets/img/finih_setup_btn.svg" alt="" style="width: 80%;cursor:pointer"
                        (click)="saveConfig()">
                </div>
            </div>
        </div>
        <div *ngIf="editFlag">
            <div cdkDropListGroup class="drag-drop-main-div ">
                <div class="" style="margin: 0px 35px;">
                    <div class="left-text">
                        <div>Statement Pairs <img placement="bottom" [ngbTooltip]="statementPairMainTooptip" tooltipClass="tooltip"
                            src="../../../assets/img/tooltip.svg" style="margin-left: 5px;"></div>
                        <!-- <div>Select a total of 15 Statement pairs (from BSL Library or My Library)</div> -->
                    </div>
                    <div class="library-change-div">
                        <div class="bsl-lib right-incline-lib" id="bsl-lib" (click)="handleLibChange('bsl')">BSL Library
                            <img placement="bottom" [ngbTooltip]="kairosLibraryTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                        </div>
                        <div class="my-lib" id="my-lib" (click)="handleLibChange('my')">My Library
                            <img placement="bottom" [ngbTooltip]="myLibraryTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                        </div>
                    </div>
                    <div cdkDropList [cdkDropListData]="vcPairsData" class="drag-drop-left"
                        (cdkDropListDropped)="removeFromSelect($event)" *ngIf="selectedBslLib">
                        <div class="pairs-main-div" *ngFor="let item of vcPairsData;let i = index" cdkDrag
                            [id]="item.pair_left+item.id" [ngClass]="slectedPairs(item) ? 'change': ''">
                            <div>
                                {{item.pair_left}}
                            </div>
                            <div>
                                <img src="../../../assets/img/U-dia.svg" alt="" *ngIf="!slectedPairs(item)">
                                <img src="../../../assets/img/U-dia-green.svg" alt="" *ngIf="slectedPairs(item)">
                            </div>
                            <div>
                                {{item.pair_right}}
                            </div>
                        </div>
                    </div>
                    <div cdkDropList [cdkDropListData]="myLibrary" class="drag-drop-left"
                        (cdkDropListDropped)="removeFromSelect($event)" *ngIf="!selectedBslLib">
                        <div style="font-weight: 500; background-color: #E8EFEF;">Create New Pair:</div>
                        <div class="pairs-main-div"
                            style="align-items: center; background-color: #E8EFEF; grid-template-columns: 4fr 1fr 4fr;">
                            <div>
                                <input type="text" class="pairs-inp-box" name="myPairLeft"
                                    (keypress)="characterLimit($event,'left')" [(ngModel)]="myPairLeft">
                            </div>
                            <div>
                                <img src="../../../assets/img/U-dia.svg" alt="">
                            </div>
                            <div>
                                <input type="text" class="pairs-inp-box" name="myPairRight"
                                    (keypress)="characterLimit($event,'right')" [(ngModel)]="myPairRight">
                                <button class="pair-add-last-div" (click)="addNewMyLib()"
                                    style="margin-left: 10px;">+Add</button>
                            </div>
                        </div>
                        <div class="pairs-main-div" *ngFor="let item of myLibrary;let i = index" cdkDrag
                            [id]="item.pair_left+item.id" [ngClass]="slectedPairs(item) ? 'change': ''">
                            <div>
                                {{item.pair_left}}
                            </div>
                            <div>
                                <img src="../../../assets/img/U-dia.svg" alt="" *ngIf="!slectedPairs(item)">
                                <img src="../../../assets/img/U-dia-green.svg" alt="" *ngIf="slectedPairs(item)">
                            </div>
                            <div>
                                {{item.pair_right}}
                                <img src="../../../assets/img/Delete Button.svg" alt="" style="cursor: pointer;"
                                    (click)="deleteMyLibPair(item)">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="right-final-div" style="margin-right: 35px;">
                    <div class="example-container example-container-right">
                        <div class="left-text">
                            <div>Selected Pairs
                                <!--<img placement="bottom" [ngbTooltip]="selectedPairTooltip" tooltipClass="tooltip"
                                    src="../../../assets/img/tooltip.svg" style="margin-left: 5px;" >-->
                            </div>
                        </div>
                        <div cdkDropList [cdkDropListData]="done" class="example-list example-list-right"
                            (cdkDropListDropped)="drop($event)">
                            <div class="example-box-right" *ngFor="let item of done; let i = index" cdkDrag>
                                <div class="selecred-pair-div">
                                    <span>Pair {{i+1}}</span>
                                    <div class="arrow-left" [ngClass]="getCssClass('pairDataSelect'+i)"></div>
                                    <div class="selecred-pair-sub-div" [id]="'pairDataSelect'+i">
                                        <div>{{item['pair_left']}}</div>
                                        <div><img src="../../../assets/img/U-dia.svg" alt=""></div>
                                        <div>{{item['pair_right']}}</div>
                                    </div>
                                    <span><img src="../../../assets/img/3Lines.svg" alt=""></span>
                                    <img src="../../../assets/img/Delete Button.svg" alt="" style="cursor: pointer;"
                                        (click)="deleteFromRight(item,i)">

                                </div>
                            </div>
                            <div class="bottom-text-main-div">
                                <div>+ Drag & Drop here</div>
                            </div>
                        </div>
                    </div>
                    <div class="last-btn-div" style="text-align: center; margin-top: 20px;">
                        <input style="width: 27%; max-width: 200px" type="image"
                            src="../../../assets/img/save&conti-btn.svg" (click)="closeEditSection()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<style>
    .pre_select_pair_list {
        padding: 10px 3rem;
        border-radius: 0 0 20px 20px;
        max-height: 70vh;
        overflow-y: scroll;
    }

    .pre_select_pair_list::-webkit-scrollbar {
        width: 4px;
        height: 50px;
        border-radius: 4px;
        opacity: 0.6;
    }

    .pre_select_pair_list::-webkit-scrollbar-track {
        background: #F0F6F7;
        width: 8px;
    }

    .pre_select_pair_list::-webkit-scrollbar-thumb {
        background: #015C66;
        background: #015C66 0% 0% no-repeat padding-box;
        width: 4px;
        height: 100px;
        border-radius: 4px;
        opacity: 0.6;
    }

    .pre_selected_main_div {
        display: flex;
        margin-left: 15px;
    }

    .pre_select_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        background: linear-gradient(95deg, #CBE4FE, #CDDAFE);
        border-radius: 20px 20px 0px 0px;
    }

    .quick_btn_main_div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        margin: 0 3rem;
    }

    .change {
        /* background-color: #C2F8CB !important; */
        color: #0e9b3e !important;
    }

    .step-main-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 205px;
    }

    .step-main-div div {
        padding: 10px 30px;
        font-size: 18px;
        background-color: #e4e4e4;
    }

    .step-main-div div:first-of-type {
        border-bottom-left-radius: 15px;
    }

    .step-main-div div:last-of-type {
        border-bottom-right-radius: 15px;
    }

    .step-active-css {
        border-top: 3px solid #30E593;
        background-color: #ffffff !important;
    }

    .drag-drop-main-div {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }

    .left-text div:first-of-type {
        font-size: 20px;
        font-weight: 500;
    }

    .left-text div:last-of-type {
        margin-bottom: 20px;
    }

    .drag-drop-left {
        max-height: 55vh;
        overflow-y: scroll;
        border: 2px solid #98B4FD;
        border-radius: 0px 0px 10px 10px;
    }

    .library-change-div {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .library-change-div div {
        flex: 1;
        text-align: center;
        background-color: #d9e4fb;
        cursor: pointer;
    }

    .my-lib,
    .bsl-lib {
        padding: 10px 0px;
    }

    .right-incline-lib {
        position: relative;
        width: 120px;
        padding: 10px 0px;
        font-size: 20px;
        position: relative;
        color: #FFF;
        background: #98B4FD !important;
        z-index: 2;
    }

    .right-incline-lib:after {
        content: " ";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: #98B4FD;
        transform-origin: top left;
        -ms-transform: skew(30deg, 0deg);
        -webkit-transform: skew(30deg, 0deg);
        transform: skew(30deg, 0deg);
    }

    .left-incline-lib {
        position: relative;
        width: 120px;
        padding: 10px 0px;
        font-size: 20px;
        position: relative;
        color: #FFF;
        background: #98B4FD !important;
        z-index: 2;
    }

    .left-incline-lib:after {
        content: " ";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: #98B4FD;
        transform-origin: top left;
        -ms-transform: skew(-30deg, 0deg);
        -webkit-transform: skew(-30deg, 0deg);
        transform: skew(-30deg, 0deg);
    }

    .pairs-main-div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        border-bottom: 1px solid #e5d7d7;
        padding: 10px;
        background-color: #FFFFFF;
    }

    .pairs-main-div div:first-of-type {
        text-align: end;
    }

    .pairs-main-div div:nth-child(2) {
        text-align: center;
    }

    .pairs-main-div div:nth-of-type(3) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .drag-drop-left::-webkit-scrollbar,
    .example-list-right::-webkit-scrollbar {
        margin-top: 5px;
        width: 8px;
        height: 50vh;
        border-radius: 4px;
        opacity: 0.6;
        border: 1px solid #707070;
    }


    .drag-drop-left::-webkit-scrollbar-track,
    .example-list-right::-webkit-scrollbar-track {
        background: #F0F6F7;
        width: 16px;
        border: 1px solid #707070;
        border-radius: 4px;
    }


    .drag-drop-left::-webkit-scrollbar-thumb,
    .example-list-right::-webkit-scrollbar-thumb {
        background: #ACC4C6;
        background: #ACC4C6 0% 0% no-repeat padding-box;
        width: 4px;
        height: 100px;
        border-radius: 4px;
        opacity: 0.6;
    }

    .selecred-pair-div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
    }

    .selecred-pair-div span:first-of-type {
        font-weight: 500;
        margin-right: 38px;
        width: 54px;
    }

    .selecred-pair-div span:nth-of-type(2) {
        margin: 0 10px;
        cursor: all-scroll;
    }

    .selecred-pair-div span:nth-of-type(3) {
        margin-right: 10px;
    }

    .arrow-left {
        border: solid #15B974;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 17px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        display: block;
        position: absolute;
        left: 74px;
    }

    .largestBox {
        padding: 33px !important;
        left: 58px !important;
    }

    .bigBox {
        padding: 25px !important;
        left: 66px !important;
    }

    .selecred-pair-sub-div {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /*background: url(../../../assets/img/boder-vc-pair.svg) no-repeat; */
        padding: 11px 0px;
        /* background-size: contain; */
        border: 2px solid #15B974;
        border-left: none;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }

    .selecred-pair-sub-div:nth-of-type(1) {
        text-align: end;
    }

    .selecred-pair-sub-div:nth-of-type(2) {
        text-align: center;
    }

    .example-list-right {
        padding: 10px;
        min-height: 55vh;
        max-height: 60vh;
        overflow-y: auto;
        background-color: #ffffff;
        border-radius: 10px;
    }

    .bottom-text-main-div {
        width: 100%;
        position: sticky;
        bottom: 0;
    }

    .bottom-text-main-div div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1A1A1A;
        padding: 5px 10px;
        color: #FFFFFF;
        border-radius: 5px;
        width: 14vw;
        margin: auto;
        margin-bottom: 20px;
    }

    .pairs-inp-box {
        max-width: 10vw;
        padding: 10px 2px;
        border-radius: 7px;
        border: 1px solid;
    }

    .pair-add-last-div {
        background-color: #FCBC2B;
        padding: 4px 5px;
        font-weight: 500;
        border-radius: 3px;
        cursor: pointer;
        border: none;
    }

    .wh_header_main_div {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        position: relative;
    }

    .wh_header_right_sub {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .wh_header_right_sub img {
        width: 45px;
    }

    .wh_header_right_sub div:first-of-type {
        background: #CFDBFE 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 8px;
        padding: 10px;
    }

    .wh_header_right_sub div:first-of-type span:last-of-type {
        font-size: 20px;
        font-weight: 600;
    }
    .header-drowdwon {
    position: absolute;
    right: 2px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 11px 11px;
    opacity: 1;
    border-top: none;
    z-index: 1111;
    box-shadow: 0px 3px 6px #00000029;
}

.header-drowdwon-content{
    padding: 5px 15px;
    cursor: pointer;
}
.header-drowdwon-content:hover{
    background-color: #C6E8C9;
}
.header-drowdwon-content:last-of-type:hover{
    border-radius: 0px 0px 11px 11px;
}
</style>