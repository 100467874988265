<app-header oneHeader='Games' [adminFlag]="true"></app-header>
<div class="detailedGamesMainDiv">
    <div class="leftContainer">
        <div class="individualSelections" [ngClass]="{highlightdigital:selectedExplore=='digital'} "
            (click)="selectedExploreGame('digital')">
            <img src="../../../assets/img/digitalGame.svg"
                [ngClass]="{rounded:selectedExplore=='digital',circle:selectedExplore!='digital'} ">
            <b>Digital Games</b>
            <i class="arrow right arrow-digital" *ngIf="selectedExplore=='digital'"></i>
        </div>
        <div class="individualSelections" [ngClass]="{highlightphysical:selectedExplore=='physical'} "
            (click)="selectedExploreGame('physical')">
            <img src="../../../assets/img/physicalGame.svg"
                [ngClass]="{rounded:selectedExplore=='physical',circle:selectedExplore!='physical'} ">
            <b>Physical Games</b>
            <i class="arrow right arrow-physical" *ngIf="selectedExplore=='physical'"></i>
        </div>
    </div>
    <div class="rightContainer_myGame">
        <div class="digital_game_main_div" *ngIf="selectedExplore=='digital'">
            <div class="myGames myGamesDis">
                <div class="myGame_img_div" *ngFor="let game of digitalGamesAllowed">
                  <img src="../../../../assets/img/myGames/vc.png" *ngIf="game.name=='Value Continuum'" (click)="goToGameResource(game.id)">
                  <img src="../../../../assets/bn_one_line.svg" *ngIf="game.name=='Bottle Neck Challenge'" (click)="goToGameResource(game.id)">
                  <img src="../../../../assets/img/explore_wh.svg" *ngIf="game.name=='Ware House'" (click)="goToGameResource(game.id)">
                  <img src="../../../../assets/img/myGames/af.png" *ngIf="game.name=='Animal Farm'" (click)="goToGameResource(game.id)">
                  <img src="../../../../assets/img/myGames/bp.png" *ngIf="game.name=='Big Picture'" (click)="goToGameResource(game.id)">
                  <img src="../../../../assets/img/Picture_Perfect_Logo.png" *ngIf="game.name=='Picture Perfect'" (click)="goToGameResource(game.id)">
                </div>
              </div>
        </div>
        <div class="digital_game_main_div" *ngIf="selectedExplore=='physical'">
            <div class="myGames myGamesDis">
                <div class="myGame_img_div" *ngFor="let item of physicalGamesAllowed">
                    <img src="../../../../assets/img/myGames/human_matrix.svg" alt="" *ngIf="item.game_id == 8">
                    <img src="../../../../assets/img/string_therory.png" alt="" *ngIf="item.game_id == 9">
                    <img src="../../../../assets/img/strom_selter.png" alt="" *ngIf="item.game_id == 10">
                    <img src="../../../../assets/img/assebembly_line.png" alt="" *ngIf="item.game_id == 11">
                    <img src="../../../../assets/img/magic_mats.png" alt="" *ngIf="item.game_id == 12">
                    <img src="../../../../assets/img/stranded.png" alt="" *ngIf="item.game_id == 13">
                </div>
              </div>
        </div>
    </div>
</div>