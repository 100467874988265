import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-game-assignment',
  templateUrl: './game-assignment.component.html',
  styleUrls: ['./game-assignment.component.css']
})
export class GameAssignmentComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private httpSv: HttpService,
    private router: Router,
    public toster: ToastrService,
    private location: Location,
    private dataService: DataSharingServiceService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((result: any) => {
      if (result.id)
        this.user_id = result.id
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        this.location.back();
      }
    })
    this.getUserDetail()
    this.role_name = this.dataService.role_id_name
  }
  header: string = 'Users'
  selectedExplore: any = 'digital'
  user_id: any
  user_dtls: any
  user_subcription: any[] = []
  user_physical_subcription: any[] = []
  user_digital_subcription: any[] = []
  role_name: any
  py_assign_flag: boolean = false
  di_assign_flag: boolean = false

  editUserProfile() {
    this.router.navigate(['/edit-user'], { queryParams: { id: this.user_id } });
  }
  selectedExploreGame(data: any) {
    if(data == this.selectedExplore) return
    // else if(data == 'digital') this.getGameAccessList(1)
    // else if(data == 'physical') this.getGameAccessList(2)
    this.selectedExplore = data;
    this.py_assign_flag = false
    this.di_assign_flag = false
  }
  getRole(role_id: any) {
    return this.role_name[role_id];
  }
  assignNewGame(game_type: string) {
    if (game_type == 'digital') this.di_assign_flag = true
    else if (game_type == 'physical') this.py_assign_flag = true
  }
  receiveMessage(event: any) {
    if (event == true) {
      this.py_assign_flag = false
      this.di_assign_flag = false
    }
    else if (event.reload == true) {
      this.getUserDetail()
      this.py_assign_flag = false
      this.di_assign_flag = false
    }
  }
  getUserDetail() {
    this.httpSv.getUserInfoWithSubcription(this.user_id).subscribe((res: any) => {
      if (res['status']) {
        this.user_dtls = res['results'].userDtls
        this.user_subcription = res['results'].subcriptionDtls
        this.user_digital_subcription = this.user_subcription.filter((ele: any) => ele.game_type == 1)
        this.user_physical_subcription = this.user_subcription.filter((ele: any) => ele.game_type == 2)
      } else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        this.location.back();
      }
    }, (err: any) => {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      this.location.back();
    })
  }
}











// PHYSICAL GAME ASSIGNMENT 
// PHYSICAL GAME ASSIGNMENT 
// PHYSICAL GAME ASSIGNMENT 
// PHYSICAL GAME ASSIGNMENT 
// PHYSICAL GAME ASSIGNMENT 


@Component({
  selector: 'physical-game-assignment',
  templateUrl: './physical-game-assign.html',
  styleUrls: ['./game-assignment.component.css']
})
export class PhysicalGameAssignmentComponent implements OnInit {
  @Input() gameAccess: any
  @Input() user_id: any
  @Output() backEmitter = new EventEmitter<any>();
  constructor(
    private route: ActivatedRoute,
    private httpSv: HttpService,
    private router: Router,
    public toster: ToastrService,
    private location: Location,
    private dataService: DataSharingServiceService,
  ) { }
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    }
    // Check if the pressed key is a digit
    const isDigit = /^\d$/.test(event.key);

    // If not a digit, prevent the input
    if (!isDigit) {
      event.preventDefault();
    }
  }
  ngOnInit(): void {
    this.getAllGame()
    this.gameAccess.forEach((ele: any) => {
      this.gameAcessArray.push(ele.game_id)
    });
  }
  gameList: any[] = []
  selectedGames: any = {}
  selectedGamesList: any[] = []
  start_date: any = null
  end_date: any = null
  validity: any = null
  gameAcessArray: any[] = []
  goBack() {
    this.backEmitter.emit(true)
  }
  handleGameSelection(game_id: any) {
    if (this.selectedGames[game_id]) {
      this.selectedGamesList.splice(this.selectedGamesList.findIndex((ele: any) => ele.id == game_id), 1)
    } else {
      this.selectedGamesList.push(this.gameList.find((ele: any) => ele.id == game_id))
      this.selectedGamesList[this.selectedGamesList.length - 1]['game_kit'] = null
    }
    this.selectedGames[game_id] = !this.selectedGames[game_id]
    console.log(this.selectedGamesList)
  }
  dateValidityChangeHandler() {
    if (this.start_date && this.validity) {
      console.log(this.start_date, this.validity)
      // let temp = this.start_date.split('-').reverse()
      // temp[1] = (+temp[1] + +this.validity).toString().padStart(2, "0")
      let temp = new Date(this.start_date);

      this.end_date = temp.setMonth(temp.getMonth() + this.validity);
      // this.end_date = `${this.end_date.getDate()}/${this.end_date.getMonth()}/${this.end_date.getFullYear()}`
      // console.log(this.end_date)
    } else {
      this.end_date = null
    }

  }
  getAllGame() {
    this.httpSv.getAllGameList().subscribe((res: any) => {
      if (res['status']) {
        this.gameList = res['results'].filter((ele: any) => {
          if (ele.game_type == 2)
            this.selectedGames[ele.id] = false
          return ele.game_type == 2
        })
        this.gameAcessArray.forEach((ele: any) => {
          if (this.selectedGames[ele] == false) delete this.selectedGames[ele]
        })
        console.log(this.selectedGames)
      } else {
        this.toster.warning('No Game Found', '', { timeOut: 2000 })
      }
    }, (err: any) => this.toster.error('Oops! Something went wrong.', '', { timeOut: 2000 }))
  }
  assignGame() {
    if (Object.keys(this.selectedGames).length == 0) {
      this.toster.warning('Already user have all games access', '', { timeOut: 2000 })
    } else {
      let booleanArray = Object.values(this.selectedGames)
      if (booleanArray.every((ele: any) => ele === false)) {
        this.toster.warning('Please select any game(s)', '', { timeOut: 2000 })
      } else {
        if (this.selectedGamesList.some((ele: any) => ele.game_kit == null)) {
          this.toster.warning('Please fill number of game kit(s)', '', { timeOut: 2000 })
        } else {
          if (!this.start_date || !this.end_date) {
            this.toster.warning('Please fill start date and validity', '', { timeOut: 2000 })
          } else {
            const epochStartTime = new Date(this.start_date).getTime() / 1000
            const epochEndTime = this.end_date / 1000
            // const epochEndTime = new Date(this.end_date.split('/').reverse().join('-')).getTime()/1000
            let data: any = {
              user_id: this.user_id,
              game_type: 2,
              start_date: epochStartTime,
              end_date: epochEndTime,
              validity: this.validity,
              games: [],
            }
            this.selectedGamesList.forEach((ele: any) => {
              data.games.push({ id: ele.id, game_kits: ele.game_kit })
            })
            this.httpSv.assignPhysicalGames(data).subscribe((res: any) => {
              if (res['status']) {
                this.backEmitter.emit({ reload: true })
                this.toster.success('Game(s) assigned sucessfully', '', { timeOut: 2000 })
              }
              else {
                this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
              }
            }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
          }
        }
      }
    }
  }
}
// DIGITAL GAME ASSIGNMENT 
// DIGITAL GAME ASSIGNMENT 
// DIGITAL GAME ASSIGNMENT 
// DIGITAL GAME ASSIGNMENT 
// DIGITAL GAME ASSIGNMENT 


@Component({
  selector: 'digital-game-assignment',
  templateUrl: './digital-game-assign.html',
  styleUrls: ['./game-assignment.component.css']
})
export class DigitalGameAssignmentComponent implements OnInit {
  @Input() gameAccess: any
  @Input() user_id: any
  @Output() backEmitter = new EventEmitter<any>();
  constructor(
    private route: ActivatedRoute,
    private httpSv: HttpService,
    private router: Router,
    public toster: ToastrService,
    private location: Location,
    private dataService: DataSharingServiceService,
  ) { }
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    }
    // Check if the pressed key is a digit
    const isDigit = /^\d$/.test(event.key);

    // If not a digit, prevent the input
    if (!isDigit) {
      event.preventDefault();
    }
  }
  ngOnInit(): void {
    this.getAllGame()
    this.gameAccess.forEach((ele: any) => {
      this.gameAcessArray.push(ele.game_id)
    });
  }
  gameList: any[] = []
  selectedGames: any = {}
  selectedGamesList: any[] = []
  start_date: any = null
  end_date: any = null
  validity: any = null
  noOfLicenses: any = null
  gameAcessArray: any[] = []
  goBack() {
    this.backEmitter.emit(true)
  }
  handleGameSelection(game_id: any) {
    if (this.selectedGames[game_id]) {
      this.selectedGamesList.splice(this.selectedGamesList.findIndex((ele: any) => ele.id == game_id), 1)
    } else {
      this.selectedGamesList.push(this.gameList.find((ele: any) => ele.id == game_id))
      this.selectedGamesList[this.selectedGamesList.length - 1]['game_kit'] = null
    }
    this.selectedGames[game_id] = !this.selectedGames[game_id]
    console.log(this.selectedGamesList)
  }
  dateValidityChangeHandler() {
    if (this.start_date && this.validity) {
      console.log(this.start_date, this.validity)
      // let temp = this.start_date.split('-').reverse()
      // temp[1] = (+temp[1] + +this.validity).toString().padStart(2, "0")
      let temp = new Date(this.start_date);

      this.end_date = temp.setMonth(temp.getMonth() + this.validity);
      // this.end_date = `${this.end_date.getDate()}/${this.end_date.getMonth()}/${this.end_date.getFullYear()}`
      // console.log(this.end_date)
    } else {
      this.end_date = null
    }

  }
  getAllGame() {
    this.httpSv.getAllGameList().subscribe((res: any) => {
      if (res['status']) {
        this.gameList = res['results'].filter((ele: any) => {
          if (ele.game_type == 1)
            this.selectedGames[ele.id] = true
          return ele.game_type == 1
        })
        this.gameAcessArray.forEach((ele: any) => {
          if (this.selectedGames[ele] == false) delete this.selectedGames[ele]
        })
        console.log(this.selectedGames)
      } else {
        this.toster.warning('No Game Found', '', { timeOut: 2000 })
      }
    }, (err: any) => this.toster.error('Oops! Something went wrong.', '', { timeOut: 2000 }))
  }
  assignGame() {
    if (Object.keys(this.selectedGames).length == 0) {
      this.toster.warning('Already user have all games access', '', { timeOut: 2000 })
    } else {
      let booleanArray = Object.values(this.selectedGames)
      if (booleanArray.every((ele: any) => ele === false)) {
        this.toster.warning('Please select any game(s)', '', { timeOut: 2000 })
      } else {
        if (this.selectedGamesList.some((ele: any) => ele.game_kit == null)) {
          this.toster.warning('Please fill number of game kit(s)', '', { timeOut: 2000 })
        } else {
          if (!this.start_date || !this.end_date) {
            this.toster.warning('Please fill start date and validity', '', { timeOut: 2000 })
          } else {
            const epochStartTime = new Date(this.start_date).getTime() / 1000
            const epochEndTime = this.end_date / 1000
            // const epochEndTime = new Date(this.end_date.split('/').reverse().join('-')).getTime()/1000
            let data: any = {
              user_id: this.user_id,
              game_type: 1,
              start_date: epochStartTime,
              end_date: epochEndTime,
              validity: this.validity,
              no_of_licenses: this.noOfLicenses,
              games: [],
            }
            this.gameList.forEach((ele: any) => {
              data.games.push({ id: ele.id })
            })
            this.httpSv.assignPhysicalGames(data).subscribe((res: any) => {
              if (res['status']) {
                this.backEmitter.emit({ reload: true })
                this.toster.success('Games assigned sucessfully', '', { timeOut: 2000 })
              }
              else {
                this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
              }
            }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
          }
        }
      }
    }
  }
}