<div class="mainDiv">
    <div style="font-size: 25px;">
        <span style="font-weight: 500;">{{sessionData.name}}</span>
        <span> | </span>
        <span>{{sessionData.clientName}}</span>
    </div>
    <div>
        {{sessionData.date | date:' d MMMM yyyy' }}, {{sessionData.time}}
    </div>
    <div>
        {{sessionData.maxPly}} Players
    </div>
    <div style="width: 100%;">
        <div style="display: flex; justify-content: space-around;">
            <div *ngFor="let item of sessionData.games">
                <img [(src)]="item.imgUrl" alt="" srcset="">
            </div>
        </div>
    </div>
    <div>All games saved Session set up completed</div>
    <div style="text-align: center;    margin-top: 1rem;">
        <input style="width: 70%;" type="image" src="../../../assets/img/ok_btn_red.svg" (click)="close()">
    </div>
</div>

<style>
    ::ng-deep .mat-dialog-container {
        padding: 0 !important;
        border-radius: 10px;
    }

    .mainDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 135px;
        border: 1px solid #707070;
        border-radius: 10px;
        padding: 20px;
        width: 45vw;
        border: 2px solid #F80D0D;
    }

    .mainDiv div {
        margin-bottom: 13px;
    }
</style>