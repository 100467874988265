import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { Location } from '@angular/common';
import { DashboardService } from 'src/app/Shared/Services/Dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { ReachOutComponent } from '../reach-out/reach-out.component';
import { HttpService } from 'src/app/services/http.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-my-games',
  templateUrl: './my-games.component.html',
  styleUrls: ['./my-games.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MyGamesComponent implements OnInit {

  // selectedExplore: any = 'physical'
  selectedExplore: any = 'digital'
  constructor(
    private titleService: Title,
    private router: Router,
    private dataShare: DataSharingServiceService,
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private httpSv: HttpService,
    public toster: ToastrService,
    private _location: Location
  ) {
    this.titleService.setTitle('Kairos | MyGames')
  }

  digitalGames = [
    {
      id: 1,
      name: 'Value Continuum',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 2,
      name: 'Bottle Neck Challenge',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 3,
      name: 'Ware House',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 4,
      name: 'Picture Perfect',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 5,
      name: 'Animal Farm',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 6,
      name: 'Big Picture',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
  ];
  digitalGamesAllowed: any[] = []
  pyhsicalGames: any[] = [
    {
      id: 8,
      name: 'Human Matrix',
      gameKit: null,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 9,
      name: 'String Theory',
      gameKit: null,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 10,
      name: 'Stromshelter',
      gameKit: null,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 11,
      name: 'Assemblyline',
      gameKit: null,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 12,
      name: 'Magic Mats',
      gameKit: null,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 13,
      name: 'Stranded',
      gameKit: null,
      purchased_on: null,
      expiry_date: null
    },
  ];
  pyhsicalGamesAllowed: any[] = []
  adminFlag: boolean = false
  ngOnInit(): void {
    this.getAllwedGamesList()
    if (this.dataShare.myGames) {
      this.selectedExplore = this.dataShare.myGames
      this.dataShare.myGames = null
    }
    if (localStorage.getItem('role')) {
      if (localStorage.getItem('role') == '1') {
        this.adminFlag = true
      }
    }
    this.selectedExplore == 'digital' ? this.getGameAccessList(1) : this.getGameAccessList(2)
  }
  selectedExploreGame(data: any) {
    if (data == this.selectedExplore) return
    else if (data == 'digital') this.getGameAccessList(1)
    else if (data == 'physical') this.getGameAccessList(2)
    this.selectedExplore = data;
  }

  // goToGameResource(gameName: any) {
  //   if (gameName == 'digital') {
  //     this.dataShare.exploreGameSelection = 'digital'
  //     this.router.navigate(['/exploreGames'])
  //     this.dataShare.myGames = 'digital'
  //   }
  //   else if (gameName == 'physical') {
  //     this.dataShare.exploreGameSelection = 'physical'
  //     this.router.navigate(['/exploreGames'])
  //     this.dataShare.myGames = 'physical'
  //   }
  //   else if (gameName == 'questico') {
  //     this.router.navigate(['/game/questico'])
  //     this.dataShare.myGames = 'questico'
  //     this.dataShare.lastUrlForGameResource = '/myGames'
  //   }
  // }
  flag: boolean = true
  backClicked() {
    this.router.navigate(['/dashboard'])
  }
  goToGameReport() {
    this.dashboardService.archiveFlag = true
    this.router.navigate(['/scheduled'])
  }
  openReachOut() {
    const dialogRef = this.dialog.open(ReachOutComponent, {
      panelClass: 'reachout',
      hasBackdrop: true,
    })
  }
  accessGameApiData: any[] = []
  licensesDtls: any = {
    left: null,
    purchased: null,
  }
  validity: any = {
    access_till: null,
    purchased_on: null
  }
  getGameAccessList(game_type: number) {
    this.digitalGamesAllowed = []
    this.pyhsicalGamesAllowed = []
    this.httpSv.getGameAccessList(game_type).subscribe((res: any) => {
      if (res['status']) {
        this.accessGameApiData = res['results']
        // this.digitalGamesAllowed = this.digitalGames.filter((ele: any) => this.accessGameApiData.filter((item:any)=> item.game_id == ele.id))
        if (game_type == 1) {
          this.accessGameApiData.forEach((ele: any) => {
            let temp = this.digitalGames.filter((item: any) => item.id == ele.game_id)
            if (temp.length !== 0) this.digitalGamesAllowed.push(temp[0])
          })

          if (this.digitalGamesAllowed.length != 0) {
            this.licensesDtls.left = this.accessGameApiData[0].no_of_licenses_left
            this.licensesDtls.purchased = this.accessGameApiData[0].no_of_licenses
            this.validity.access_till = this.epochToHumanDate(this.accessGameApiData[0].end_date)
            this.validity.purchased_on = this.epochToHumanDate(this.accessGameApiData[0].start_date)
          }
        }
        else if (game_type == 2) {
          // this.accessGameApiData.forEach((ele: any) => {
          //   let temp = this.pyhsicalGames.filter((item: any) => item.id == ele.game_id)
          //   if (temp.length !== 0) this.pyhsicalGamesAllowed.push(temp[0])
          // })
          this.pyhsicalGamesAllowed = this.accessGameApiData
        }
      }
    })
  }
  allowedGameId: any[] = []
  getAllwedGamesList() {
    this.httpSv.userAllowedGameType().subscribe((res: any) => {
      if (res['status']) {
        this.allowedGameId = res['results']
      } else {
        if (res['message'] == 'No games available') {
          this.toster.warning('Sorry, you currently do not have access to any games.', '', { timeOut: 2000 })
          this._location.back()
        } else
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
  }
  epochToHumanDate(epoch_time: number) {
    let date = new Date(epoch_time * 1000)
    let dateTemp = date.toLocaleDateString("en-GB", { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-')
    return dateTemp.split('-').reverse().join('-')
  }
  goToGameResource(id: any) {
    this.dataShare.exploreGameSelection = this.selectedExplore
    this.dataShare.myGames = this.selectedExplore
    this.dataShare.lastUrlForGameResource = 'myGames'
    this.router.navigate(['game/resources'], { queryParams: { id: id } })
  }
}
