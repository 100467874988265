import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  constructor(
    private dataService: DataSharingServiceService,
    private router: Router
  ) { }
  compRoute: string = 'home'
  ngOnInit(): void {
    this.header = 'Super Admin Dashboard';
  }

  header: string = '';

  changeCompRoute(route: any) {
    if (route == 'user-list') {
      this.router.navigate(['/user-list']);
    } else if (route == 'addUser') {
      this.dataService.addEditUser = 'addUser';
      this.router.navigate(['/add-user']);
    }
    else if (route == 'digital') {
      this.router.navigate(['/admin-games'], { queryParams: { game: 1 } });
    } else if (route == 'physical') {
      this.router.navigate(['/admin-games'], { queryParams: { game: 2 } });
    }
  }
}
