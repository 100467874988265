<div>
  <div>
    <!-- <label class="shedulaarMainDiv" for="session-date">Session Date</label>
    <input type="date" class="form-control shedulaarMainInp" id="datePicker" [(min)]="todayDate"
      [(ngModel)]="sessionDate" (change)="dateChangeHadler()"> -->
    <div [ngClass]=" (noOfPlayer && clientName && sessionName) ?'':'disableDiv'" (click)="showErr(noOfPlayer > 100 || noOfPlayer > avaliableLicience)">
      <label for="client-name">Session Date & Time</label>
      <img placement="bottom" [ngbTooltip]="sessionduration" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
      <div class="date_time_div custom-select" (click)="handleClander()" #calendar_drop >
        <span>{{getTimeDisplay(selectedTimeString,0)}}</span>
        <span *ngIf="selectedTimeString && selectedTimeString != ''">|</span>
        <span [ngClass]="slotFilled?'colorRedCss':''">{{getTimeDisplay(selectedTimeString,1)}}</span>
        <div style="position: absolute;right: 13px;top: 36%;"><img src="../../../assets/arrow-down-339-svgrepo-com.svg" alt="" style="width: 20px;"></div>
      </div>
      <div style="width: 24vw;" *ngIf="slotFilled">
        <span class="err_ele_span" style="margin-top: 10px;">
          <img src="../../../assets/error_icon.svg" alt="">
          <span>Oops! This time slots got booked just now. Please select another time slot.</span>
        </span>
      </div>

    </div>
  </div>
</div>
<div *ngIf="noOfPlayer && clientName !='' && sessionName != '' && showCalendar && sessionName && clientName && noOfPlayer < 101 && noOfPlayer > 1 && noOfPlayer <= avaliableLicience">
  <div style="display: flex;gap: 25px;position: absolute;bottom: 0;align-items: flex-end;" (appOutsideClick)="outSideClickHandle($event)">
    <div class="calendar_main_div" #calendar_div>
      <table>
        <thead>
          <tr>
            <th colspan="7"
              style="border-top-left-radius: 16px;border-top-right-radius: 16px;background: #E4E9ED;padding: 10px 5px 0 5px;">
              <div style="display: flex;
              justify-content: space-between;
              align-items: center;margin-bottom: 10px;">
                <img src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" alt=""
                  style="width: 15px; cursor: pointer;" (click)="previousMonth()">


                <span style="color: #FF4C0D; font-size: 17px; font-weight: 500;">{{ month }} {{ year }}</span>


                <img src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" alt=""
                  style="width: 15px;  cursor: pointer;transform: rotate(180deg);" (click)="nextMonth()">
              </div>
            </th>
          </tr>
          <tr class="week_days_head_main">
            <th class="week_days_head" *ngFor="let day of days">{{ day }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let week of weeks;let i2 = index">
            <td *ngFor="let day of week; let i = index"
              [ngClass]="day.day == preSelectedDate && !day.disabled ? 'selectedDateCss' :'' "
              (click)="shedularClaedarClick(day.day,i,day,day.disabled)">
              <span [ngClass]="!day.allowed?'disabled':'enabled'">{{ day.day }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="min-width: 15vw;" #slot_div>
      <div class="time_slot_main_div">
        <div class="timeSlot_header_main_div">
          <div style="color: #000000; font-weight: 500;text-align: center;">Time Slots</div>
        </div>
        <div class="time_slot_list_main_div" id="scroll_content">
          <!-- <button class="scroll-button scroll-up-button" (click)="handleScrollTop()">▲</button> -->
          <div *ngIf="allSlots.length>0">
            <div *ngFor="let item of allSlots; let index =index" class="time_slot_list_sub_div"
              [ngClass]="item.selected?'slot_selected':''" (click)="selectedSlotHandlear(item,index)">
              <span>{{getStartSlotTime(item.start_time)}}</span>
              <span> - </span>
              <span>{{getEndSlotTime(item.expiry_time)}}</span>

            </div>
          </div>
          <div *ngIf="allSlots.length == 0" style="text-align: center;
          font-size: 18px;
          font-weight: 500;">
            No Slots Available
          </div>
          <!-- <button class="scroll-button scroll-down-button" (click)="handleScrollDown()">▼</button> -->
        </div>
        <div>
          <div class="time_slot_select_btn_div">
            <button class="time_slot_select_btn_disable" [disabled]="selectedSlot.length == 0 "
              [ngClass]="selectedSlot.length>0?'time_slot_select_btn_enable':''" (click)="goToStep2()">Select</button>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 17px;">
      <div *ngIf="slotConsicutiveFlag">
        <span class="err_ele_span" style="margin-top: 10px;">
          <img src="../../../assets/error_icon.svg" alt="">
          <span>Please select consecutive time slots</span>
        </span>
      </div>
      <div *ngIf="maxSlotFlag">
        <span class="err_ele_span" style="margin-top: 10px;">
          <img src="../../../assets/error_icon.svg" alt="">
          <span>You can select a maximum of 3 time slots (session for up to 180 min)</span>
        </span>
      </div>
    </div>
  </div>
</div>



<style>
  label {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.24px;
    color: #00477B;
  }

  .disableDiv {
    opacity: 35%;
  }
  .date_time_div{
    padding-right: 6px;
    position: relative;
  }
  .colorRedCss{
    color: red;
  }
</style>